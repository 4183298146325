import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Add as AddIcon, PlaylistAdd as PlaylistAddIcon } from "@mui/icons-material";
import { Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import FullScreenDialog from "components/FullScreenDialog";
import { StyledFab } from "components/StyledFab";
import { AddExtra } from "./extra/AddExtraDialog";

import { useAppSelector } from "store";
import { selectSelectedJob, selectSelectedJobVisit } from "store/slices/jobs.store";
import { TabProps } from "./TabProps";
import { ExtraCardComponent } from "./extra/ExtraCard";

const PREFIX = "ExtrasTab";

const classes = {
  input: `${PREFIX}-input`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),

  [`& .${classes.input}`]: {
    display: "none",
  },
}));

export const ExtrasTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const intl = useIntl();
  const { extras } = useAppSelector(selectSelectedJobVisit);
  const job = useAppSelector(selectSelectedJob);
  const [openAddExtraDialog, setOpenAddExtraDialog] = useState(false);

  useEffect(() => {
    const fab = (
      <StyledFab
        color="primary"
        aria-label={intl.formatMessage({ id: "general.add" })}
        onClick={() => {
          setOpenAddExtraDialog(true);
        }}
        data-testid="PartTabAddButton"
      >
        <AddIcon />
      </StyledFab>
    );
    if (setFab) {
      setFab(fab);
    }
  }, [intl, setFab]);

  return (
    <StyledContainer data-testid="ExtrasTab">
      {extras.length === 0 && (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <PlaylistAddIcon color="secondary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography color="secondary">
              <FormattedMessage id="visit.noExtrasAdded" />
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        {extras.map((e, index) => (
          <ExtraCardComponent extra={e} setCountEditItems={() => {}} index={index} />
        ))}
        {/* Extra space to scroll down */}
        <div style={{ height: "80px", width: "80px" }}> </div>
      </Grid>
      <FullScreenDialog
        child={<AddExtra setOpenAddExtraDialog={setOpenAddExtraDialog} loading={false} />}
        title={`${job?.externalId} / ${intl.formatMessage({ id: "job.extras" })}`}
        centerTitle
        goingBack
        open={openAddExtraDialog}
        setOpen={(open: boolean) => setOpenAddExtraDialog(open)}
      />
    </StyledContainer>
  );
};
