import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { AsolviDatePicker } from "components/AsolviDatePicker";
import { DateFilterType, FilterDateSelect } from "components/FilterDateSelect";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import StyledTextField from "components/StyledTextField";
import { endOfDay } from "date-fns";
import { addressToPostalLocation } from "helpers";
import { useDebounce } from "hooks/useDebounce";
import { isEqual } from "lodash";
import {
  JobFilter,
  JobFilterDateTypes,
  JobFilterStorageKey,
  defaultJobFilter,
} from "models/JobFilter";
import { FC, Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { getCustomers, resetJobFilter, setJobFilter } from "store/slices/jobs.store";

type JobFilterDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: (dirty: boolean) => void;
};

export const JobFilterDialog: FC<JobFilterDialogProps> = (props) => {
  const { open, setOpen, onClose } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const filter = useAppSelector((s) => s.jobs.jobFilter);
  const { symptoms } = useAppSelector((s) => s.cache);
  const { customers, loadingCustomers } = useAppSelector((s) => s.jobs.filterOptions);

  const [jobId, setJobId] = useState<String>("");
  const [customerSearch, setCustomerSearch] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const nameFilter = useDebounce<string>(customerSearch, 500);

  useEffect(() => {
    if (open) {
      dispatch(getCustomers({ nameFilter }));
    }
  }, [nameFilter, dispatch, open]);

  const dateOptions: DateFilterType[] = [
    {
      label: intl.formatMessage({ id: "filter.next7days" }),
      value: "week",
    },
    {
      label: intl.formatMessage({ id: "filter.nextMonth" }),
      value: "1month",
    },
    {
      label: intl.formatMessage({ id: "filter.next3months" }),
      value: "3month",
    },
    {
      label: intl.formatMessage({ id: "filter.specificDate" }),
      value: "specificDate",
    },
  ];

  const setFilter = (filter: JobFilter) => {
    setIsDirty(true);
    dispatch(setJobFilter({ jobFilter: filter, hideNewJobNotification: true }));
  };

  const setDate = function (type: JobFilterDateTypes) {
    var specificDate = null;
    if (type === "specificDate") specificDate = filter.specificDate;
    setFilter({
      ...filter,
      typeDate: type,
      jobId: null,
      specificDate: specificDate,
    });
  };
  const setSpecificDate = function (value: Date | null) {
    setFilter({
      ...filter,
      specificDate: value ? endOfDay(value) : null,
      jobId: null,
      typeDate: "specificDate",
    });
  };

  useEffect(() => {
    setJobId(filter.jobId || "");
  }, [filter, setJobId]);

  const resetFilter = () => {
    if (!isEqual(filter, defaultJobFilter)) {
      setIsDirty(true);
    }
    dispatch(resetJobFilter());
  };

  const saveFilter = () => {
    localStorage.setItem(JobFilterStorageKey, JSON.stringify(filter));
  };

  const handleClose = () => {
    const dirty = isDirty;
    saveFilter();
    setOpen(false);
    setIsDirty(false);
    onClose(dirty);
  };

  return (
    <FullScreenDialog
      title={intl.formatMessage({ id: "filter.setFilters" })}
      open={open}
      setOpen={setOpen}
      onClose={() => {
        handleClose();
      }}
      child={
        <Container sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <StyledTextField
                label={intl.formatMessage({ id: "filter.job" })}
                data-testid="JobFilterDialog-JobFilter"
                value={jobId}
                onChange={(e) => {
                  setFilter({
                    ...defaultJobFilter,
                    jobId: e.target.value,
                  });
                }}
                InputProps={{
                  endAdornment: filter.jobId ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setFilter({
                          ...filter,
                          jobId: null,
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : undefined,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={symptoms}
                getOptionLabel={(option) => `${option.description}`}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label={intl.formatMessage({ id: "filter.symptoms" })}
                  />
                )}
                value={filter.selectedSymptoms}
                data-testid="JobFilterDialog-SymptomFilter"
                onChange={(_, value) => {
                  setFilter({
                    ...filter,
                    selectedSymptoms: value,
                    jobId: null,
                  });
                }}
                isOptionEqualToValue={(option, value) => option.code === value.code}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                data-testid="JobFilterDialog-CustomerFilter"
                value={filter.customer}
                onChange={(_, value) => {
                  setFilter({
                    ...filter,
                    customer: value,
                    jobId: null,
                  });
                }}
                getOptionLabel={(o) => `${o.name}`}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={`${option.id}-${option.name}`}
                      style={{ display: "block" }}
                      data-testid={`${option.id}-${option.name}`}
                    >
                      <Typography variant="body1">{option.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {addressToPostalLocation(option.address)}
                      </Typography>
                    </li>
                  );
                }}
                inputValue={customerSearch}
                onInputChange={(_, value) => {
                  setCustomerSearch(value);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterOptions={(x) => x}
                options={customers}
                loading={loadingCustomers}
                renderInput={(params) => (
                  <StyledTextField
                    name="customer"
                    {...params}
                    label={intl.formatMessage({
                      id: "general.customer",
                    })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={intl.formatMessage({ id: "filter.city" })}
                data-testid="JobFilterDialog-CityFilter"
                value={filter.city || ""}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    city: e.target.value,
                    jobId: null,
                  });
                }}
                InputProps={{
                  endAdornment: filter.city ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setFilter({
                          ...filter,
                          city: null,
                          jobId: null,
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : undefined,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={intl.formatMessage({ id: "general.postalCode" })}
                data-testid="JobFilterDialog-PostalCodeFilter"
                value={filter.postalCode || ""}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    postalCode: e.target.value,
                    jobId: null,
                  });
                }}
                InputProps={{
                  endAdornment: filter.postalCode ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setFilter({
                          ...filter,
                          postalCode: null,
                          jobId: null,
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : undefined,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterDateSelect value={filter.typeDate} options={dateOptions} onChange={setDate} />
            </Grid>
            {filter.typeDate === "specificDate" && (
              <Grid item xs={12}>
                <AsolviDatePicker
                  data-testid="JobFilterDialog-DatePickerFilter"
                  onChange={(value) => {
                    setSpecificDate(value);
                  }}
                  value={filter.specificDate}
                  label={intl.formatMessage({ id: "filter.specificDate" })}
                  clearable
                />
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SecondaryButton
                onClick={resetFilter}
                fullWidth
                variant="outlined"
                color="primary"
                data-testid="JobFilterDialog-ResetButton"
              >
                <FormattedMessage id="filter.resetAll" />
              </SecondaryButton>
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                data-testid="JobFilterDialog-SubmitButton"
                onClick={() => {
                  handleClose();
                }}
              >
                <FormattedMessage id="general.viewJobs" />
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};
