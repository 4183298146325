import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import engineerSettings from "helpers/engineerSettings";
import { GetUserEndpointsQueryVariables } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./user.store";
export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

// Definition of actual actions
export const asyncQueries = {
  getPublicFeatureFlags: createAppAsyncThunk(
    "user/getPublicFeatureFlags",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getPublicFeatureFlags);
      if (errors) return rejectWithValue(errors);
      if (!data?.publicFeatureFlags) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getFeatureFlags: createAppAsyncThunk(
    "user/getFeatureFlags",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getFeatureFlags);
      if (errors) return rejectWithValue(errors);
      if (!data?.featureFlags) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getUserEndpoints: createAppAsyncThunk(
    "user/getUserEndpoints",
    async (variables: GetUserEndpointsQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getUserEndpoints, { variables });
      if (errors) return rejectWithValue(errors);
      if (!data?.userEndpoints) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getUserSettings: createAppAsyncThunk(
    "user/getUserSettings",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getUserSettings);
      if (errors) return rejectWithValue(errors);
      if (!data?.userSettings) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getEngineerSettings: createAppAsyncThunk(
    "user/getEngineerSettings",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getEngineerSettings);
      if (errors) return rejectWithValue(errors);
      if (!data?.engineerSettings) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getPublicFeatureFlags.fulfilled, (state, { payload: data }) => {
    state.featureFlagVar = [...data.publicFeatureFlags];
    return state;
  });
  builder.addCase(asyncQueries.getFeatureFlags.fulfilled, (state, { payload: data }) => {
    state.featureFlagVar = [...data.featureFlags];
    return state;
  });
  builder.addCase(asyncQueries.getUserEndpoints.fulfilled, (state, { payload: data }) => {
    state.userEndpoints = data.userEndpoints;
    return state;
  });
  builder.addCase(asyncQueries.getUserSettings.fulfilled, (state, { payload: data }) => {
    state.userSettings = data.userSettings;
    return state;
  });
  builder.addCase(asyncQueries.getEngineerSettings.fulfilled, (state, { payload: data }) => {
    state.engineerSettings = data.engineerSettings;
    Object.assign(engineerSettings, data.engineerSettings);
  });
};
