import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

// "index" page to avoid issues with router
import { version } from "env";
import engineerSettings from "helpers/engineerSettings";
import { UserConsentTypeEnum } from "operations/schema/schema";
import { useAppSelector } from "store";
import { selectUser } from "store/slices/user.store";
import { Userpilot } from "userpilot";

export const UserpilotHandler: FC = () => {
  const userVar = useAppSelector(selectUser);
  const location = useLocation();

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  useEffect(() => {
    if (
      userVar &&
      userVar.email &&
      userVar.userConsents.find((x) => x.type === UserConsentTypeEnum.Userpilot && x.accepted)
    ) {
      Userpilot.identify(userVar.email, {
        email: userVar.email,
        company: {
          id: window.location.hostname,
          name: userVar.companyName,
          created_at: "2024-06-28",
        },
        Name: userVar.fullName,
        Email: userVar.email,
        Company: userVar.companyName,
        TechnicianId: userVar.externalId,
        TechnicianGuid: userVar.externalGuid,
        TechnicianNo: userVar.technicianNo,
        EvaticMobileVersion: version(),
        AppName: "Mobile",
        EngineerSettings: {
          AllowChangeInvoiceType: engineerSettings.allowChangeInvoiceType,
          AllowNegativeStock: engineerSettings.allowNegativeStock,
          SyncSalesPrice: engineerSettings.syncSalesPrice,
          SyncCostPrice: engineerSettings.syncCostPrice,
          CanChangeEquipmentOnTicket: engineerSettings.canChangeEquipmentOnTicket,
          CanCreateJob: engineerSettings.canCreateJob,
          CanEditCustomEquipmentProps: engineerSettings.canEditCustomEquipmentProps,
          IsSupervisor: engineerSettings.isSupervisor,
          RequireEngineerSignature: engineerSettings.requireEngineerSignature,
          RequiredSolutionDescription: engineerSettings.requiredSolutionDescription,
          SendMailToCustomer: engineerSettings.sendMailToCustomer,
        },
      });
    } else {
      Userpilot.anonymous();
    }
  }, [userVar]);

  return <></>;
};
