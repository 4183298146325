import { toDateString } from "helpers";
import { WritableDraft } from "immer/dist/internal";
import { Job } from "models/Job";
import { initialVisitFormValues } from "models/jobVisitForm";
import { UsedPart } from "models/usedPart";
import { EngineerSettings, JobCategoryType, MeterReadingInputType } from "operations/schema/schema";
import { State, storeVisitsToLocal } from "store/slices/jobs.store";

export const initializeJobVisit = (
  state: WritableDraft<State>,
  job: Job,
  jobCategories: JobCategoryType[],
  engineerSettings: EngineerSettings
) => {
  const travelTimes = job.travelTimes.filter((tt) => tt.startTime || tt.stopTime);
  state.jobVisits[job.id] = {
    checklists: [
      ...job.checklists.map((cl) => ({
        checklist: cl,
        uploaded: false,
        hasPreStart: cl.questions.some((q) => q.isRequiredPreStart),
      })),
    ],
    files: [],
    extras: [],
    meterReadings: [
      ...job.meters.map(
        (mr) =>
          ({
            currentReading: undefined,
            currentReadingDate: undefined,
            typeId: mr.typeId,
          } as MeterReadingInputType)
      ),
    ],
    usedParts: [
      ...job.usedParts.map(
        (p) => ({ part: p, maxQuantity: p.quantity, salesPriceChanged: false } as UsedPart)
      ),
    ],
    travelTimes: (travelTimes || []).map((tt) => ({
      startDate: toDateString(tt.startTime!),
      startTime: toDateString(tt.startTime!),
      stopDate: toDateString(tt.stopTime) || undefined,
      stopTime: toDateString(tt.stopTime) || undefined,
    })),
    travelTimesInvoiceType: null,
    followUp: (() => {
      let bool = !!job.preOrderedParts.length;
      return {
        followUpChecked: bool,
      };
    })(),
    signatureCustomer: undefined,
    signatureEngineer: undefined,
    autoEndTime: true,
    ...initialVisitFormValues(job, jobCategories),
    errors: {},
    signoffErrors: {},
    submitLoading: false,
    metersNotSaved: engineerSettings.requireMeterReading === true && job.meters.length > 0,
    metersUseCurrent: false,
    hasCategories: !!jobCategories.length,
    reference: "",
    customerSatisfaction: undefined,
  };
  storeVisitsToLocal(state);
};
