import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import StyledCard from "components/StyledCard";
import { StyledCardActions } from "components/StyledCardActions";
import { StyledContainer } from "components/StyledContainer";
import { PartStatus, PartType, StockStore } from "operations/schema/schema";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { addParts } from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { ReturnPartsDialog } from "./ReturnPartsDialog";

interface PreOrderedPart extends PartType {
  checked: boolean;
}

interface AddPreOrderedPartsProps {
  parts: PartType[];
}

export const AddPreOrderedParts: FC<AddPreOrderedPartsProps> = (props) => {
  const { parts: propParts } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { userVar: userData } = useAppSelector((state) => state.user);
  const [parts, setParts] = useState<PreOrderedPart[]>(
    propParts.map((p) => ({ ...p, checked: true }))
  );

  const [openReturnPartsDialog, setOpenReturnPartsDialog] = useState(false);

  const addCheckedParts = () => {
    const usedParts = getCheckedAsJobUsedParts();
    setParts(parts.filter((p) => !p.checked));
    dispatch(addParts({ parts: usedParts }));
    dispatch(addSnackbarMessage({ key: "AddParts-success" }));
  };

  const getCheckedAsJobUsedParts = () => {
    return parts
      .filter((p: PreOrderedPart) => p.checked === true)
      .map((selectedPart: PreOrderedPart) => {
        const part: PartType = {
          id: selectedPart.id || "",
          partNumber: selectedPart.partNumber || "",
          barcode: selectedPart.barcode,
          quantity: selectedPart.quantity,
          stockStore: StockStore.Engineer,
          stockId: userData?.suppliedStoreId?.toString(),
        };

        return {
          maxQuantity: part.quantity || 0,
          salesPriceChanged: false,
          part: {
            ...part,
            description: selectedPart.description || "",
            status: PartStatus.RequestedUsed,
          },
        };
      });
  };

  return (
    <StyledContainer data-testid="AddPreOrderedParts">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">
            {intl.formatMessage({
              id: "part.previouslyRequested",
            })}
          </Typography>
        </Grid>
        {parts.map((part, index) => {
          return (
            <Grid key={part.id} item xs={12} sm={6} lg={4}>
              <StyledCard>
                <CardContent sx={{ width: "100%" }}>
                  <Typography variant="body1" component="h3">
                    {part.description}
                  </Typography>
                  <Typography color="textSecondary">{part.partNumber}</Typography>
                  <Typography variant="body2" component="p">
                    <FormattedMessage id="general.quantity" />: {part.quantity}
                  </Typography>
                </CardContent>
                <StyledCardActions data-testid={`AddPreOrderedParts-Checkbox-${part.id}`}>
                  <Checkbox
                    color="primary"
                    checked={part.checked}
                    onChange={() =>
                      setParts([
                        ...parts.slice(0, index),
                        {
                          ...part,
                          checked: !part.checked,
                        },
                        ...parts.slice(index + 1),
                      ])
                    }
                  />
                </StyledCardActions>
              </StyledCard>
            </Grid>
          );
        })}
        <Grid item>
          <PrimaryButton
            fullWidth
            variant="contained"
            onClick={addCheckedParts}
            disabled={parts.every((p) => !p.checked)}
            data-testid="AddPreOrderedParts-AddPart"
          >
            {intl.formatMessage({
              id: "visit.addPart",
            })}
          </PrimaryButton>
        </Grid>
      </Grid>
      <FullScreenDialog
        open={openReturnPartsDialog}
        setOpen={setOpenReturnPartsDialog}
        child={
          <ReturnPartsDialog
            setOpenReturnPartsDialog={setOpenReturnPartsDialog}
            preOrderedParts={getCheckedAsJobUsedParts()}
          />
        }
      />
    </StyledContainer>
  );
};
