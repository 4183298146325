import {
  MoodBad as MoodBadIcon,
  SentimentNeutral as SentimentNeutralIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJobVisit, setVisitValue } from "store/slices/jobs.store";

const CustomerSatisfactionRatings = {
  GOOD: 7,
  NEUTRAL: 4,
  BAD: 1,
};

export const CustomerSatisfactionSurvey: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { customerSatisfaction } = useAppSelector(selectSelectedJobVisit);
  return (
    <Grid item container justifyContent="center" sx={{ mt: "15px" }}>
      <Grid item>
        <FormattedMessage id="visit.signoff.rateSatisfaction" />
      </Grid>
      <Grid container justifyContent="space-around">
        <IconButton
          edge="start"
          color={
            customerSatisfaction === CustomerSatisfactionRatings.GOOD ? "success" : "secondary"
          }
          onClick={() =>
            dispatch(
              setVisitValue({
                key: "customerSatisfaction",
                value: CustomerSatisfactionRatings.GOOD,
              })
            )
          }
          aria-label={intl.formatMessage({ id: "visit.signoff.verySatisfied" })}
          data-testid="CustomerSatisfaction-VerySatisfied"
        >
          <SentimentVerySatisfiedIcon sx={{ fontSize: "3rem" }} />
        </IconButton>
        <IconButton
          edge="start"
          color={
            customerSatisfaction === CustomerSatisfactionRatings.NEUTRAL ? "success" : "secondary"
          }
          onClick={() =>
            dispatch(
              setVisitValue({
                key: "customerSatisfaction",
                value: CustomerSatisfactionRatings.NEUTRAL,
              })
            )
          }
          aria-label={intl.formatMessage({ id: "visit.signoff.neutral" })}
          data-testid="CustomerSatisfaction-Neutral"
        >
          <SentimentNeutralIcon sx={{ fontSize: "3rem" }} />
        </IconButton>
        <IconButton
          edge="start"
          color={customerSatisfaction === CustomerSatisfactionRatings.BAD ? "success" : "secondary"}
          onClick={() =>
            dispatch(
              setVisitValue({
                key: "customerSatisfaction",
                value: CustomerSatisfactionRatings.BAD,
              })
            )
          }
          aria-label={intl.formatMessage({ id: "visit.signoff.notSatisfied" })}
          data-testid="CustomerSatisfaction-NotSatisfied"
        >
          <MoodBadIcon sx={{ fontSize: "3rem" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
