import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import SearchIcon from "@mui/icons-material/Search";
import { LinearProgress, List } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PullToRefresh from "react-simple-pull-to-refresh";

import { useAppDispatch, useAppSelector } from "store";
import { setAppBar } from "store/slices/menu.store";

import { FilterAppBar } from "components/FilterAppBar";
import FullScreenDialog from "components/FullScreenDialog";
import { StyledListSubheader } from "components/StyledListSubheader";
import StyledTextField from "components/StyledTextField";
import { filterPartList, groupByProperty } from "helpers";
import { useDebounce } from "hooks/useDebounce";
import { Stock } from "operations/schema/schema";
import { getStockEnquiry } from "store/slices/stock.store";
import { StockFilterDialog } from "./StockFilterDialog";

export const StockPage: FC = () => {
  const dispatch = useAppDispatch();
  const { userVar: userData } = useAppSelector((state) => state.user);
  const { stockEnquiry, loadingStockEnquiry } = useAppSelector((state) => state.stock);
  const intl = useIntl();
  const [filter, setFilter] = useState<string>("");
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const debouncedPartSearch = useDebounce<string>(filter, 500);
  const [showOtherStockLocations, setShowOtherStockLocations] = useState(false);
  const [hideOutOfStock, setHideOutOfStock] = useState(true);
  const [selectedStock, setSelectedStock] = useState<Stock>({
    id: "",
    name: "",
  });
  const filterActive = !hideOutOfStock || showOtherStockLocations;

  const getStockEnquiryCb = useCallback(() => {
    dispatch(
      getStockEnquiry({
        locationId: showOtherStockLocations ? selectedStock?.id : userData?.stockId,
        searchString: debouncedPartSearch,
        hideOutOfStock: hideOutOfStock,
      })
    );
  }, [
    debouncedPartSearch,
    dispatch,
    hideOutOfStock,
    selectedStock?.id,
    showOtherStockLocations,
    userData?.stockId,
  ]);

  useEffect(() => {
    if (!filterDialogOpen) {
      getStockEnquiryCb();
    }
  }, [
    debouncedPartSearch,
    dispatch,
    filterDialogOpen,
    getStockEnquiryCb,
    hideOutOfStock,
    selectedStock.id,
    showOtherStockLocations,
    userData?.stockId,
  ]);

  const grouppedParts = groupByProperty(stockEnquiry, (p) => p?.stockName!, "");

  useEffect(() => {
    dispatch(
      setAppBar({
        title: intl.formatMessage({ id: "menu.stockEnquiry" }),
      })
    );
  }, [
    dispatch,
    intl,
    showOtherStockLocations,
    userData?.stockId,
    selectedStock.id,
    hideOutOfStock,
  ]);

  return (
    <>
      {loadingStockEnquiry && !stockEnquiry && <LinearProgress sx={{ zIndex: 9999 }} />}

      <Container>
        <FilterAppBar
          onClick={setFilterDialogOpen}
          filterCount={filterActive ? 1 : 0}
          foundCount={stockEnquiry.length}
          type="parts"
        />

        <PullToRefresh onRefresh={async () => await getStockEnquiryCb()}>
          <Container sx={{ mt: 2 }}>
            <Grid>
              <StyledTextField
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
                variant="outlined"
                placeholder={intl.formatMessage({ id: "stock.search" })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                data-testid="StockPage-Filter"
              />
            </Grid>
            <div>
              {!!stockEnquiry?.length ? (
                <List sx={{ width: "100%" }}>
                  {Object.entries(grouppedParts).map((entry) => {
                    const [stockName, parts] = entry;
                    const filteredPartsList = filterPartList(parts, filter);

                    const mappedParts = filteredPartsList.map(
                      (part, index: number, { length }: { length: number }) => (
                        <List dense key={`p-${part.partNumber}-${index}`}>
                          <Grid
                            key={`${part.partNumber}-${index}`}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            wrap="nowrap"
                          >
                            <Grid item xs={10}>
                              {part.description}
                              <Typography variant="body2" color="textSecondary">
                                {part.partNumber}
                              </Typography>
                              {part.location && (
                                <Typography variant="body2" color="textSecondary">
                                  {part.location}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              container
                              justifyContent="flex-end"
                              wrap="nowrap"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  color={(part.quantity || 0) > 0 ? "success.main" : "error.main"}
                                >
                                  {part.quantity ?? 0}
                                </Typography>
                              </Grid>
                              <Grid item>
                                {(part.quantity || 0) > 0 ? (
                                  <CheckIcon color="success" />
                                ) : (
                                  <ClearIcon color="error" />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {index + 1 !== length && (
                            <Divider flexItem sx={{ width: "100%", mt: 2 }} />
                          )}
                        </List>
                      )
                    );
                    return (
                      <li
                        key={stockName}
                        style={{
                          backgroundColor: "inherit",
                        }}
                      >
                        <ul
                          style={{
                            backgroundColor: "inherit",
                            padding: 0,
                          }}
                        >
                          {(filteredPartsList?.length ?? 0) > 0 && (
                            <StyledListSubheader sx={{ p: 0 }}>
                              {stockName.toUpperCase()}
                            </StyledListSubheader>
                          )}
                          {mappedParts}
                        </ul>
                      </li>
                    );
                  })}
                </List>
              ) : (
                <Grid item container direction="column" alignItems="center">
                  <Grid item>
                    <PlaylistAddIcon color="secondary" fontSize="large" />
                  </Grid>
                  <Grid item>
                    <Typography color="secondary">
                      <FormattedMessage id="stock.noStockFound" />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          </Container>
        </PullToRefresh>

        <FullScreenDialog
          title={intl.formatMessage({ id: "filter.setFilters" })}
          open={filterDialogOpen}
          setOpen={setFilterDialogOpen}
          child={
            <StockFilterDialog
              setSelectedStock={setSelectedStock}
              selectedStock={selectedStock}
              showOtherStockLocations={showOtherStockLocations}
              setShowOtherStockLocations={setShowOtherStockLocations}
              hideOutOfStock={hideOutOfStock}
              setHideOutOfStock={setHideOutOfStock}
              onSubmit={() => {
                setFilterDialogOpen(false);
              }}
            />
          }
        />
      </Container>
    </>
  );
};
