import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { RefObject, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactSignatureCanvas from "react-signature-canvas";

import StyledButtonSecondaryGreyMini from "components/StyledButtonSecondaryGreyMini";

export interface AsolviSignoffProps {
  canvasReference: RefObject<ReactSignatureCanvas>;
  handleSignature: (added: boolean) => void;
  labelText: JSX.Element;
  signatureImageData?: string | null;
}

export const AsolviSignoff = (props: AsolviSignoffProps) => {
  const { canvasReference, handleSignature, labelText, signatureImageData } = props;

  /* resize canvas element to  fit*/
  const [canvasWidth, setCanvasWidth] = useState(
    canvasReference.current?.getCanvas().parentElement?.offsetWidth
  );

  const width = useRef(window.innerWidth);

  useEffect(() => {
    const resizeCanvas = () => {
      const newWidth = window.innerWidth;
      if (width.current !== newWidth) {
        width.current = newWidth;
        const trimmedCanvas = canvasReference.current?.getTrimmedCanvas();
        const imageHeight = trimmedCanvas?.height;
        const imageWidth = trimmedCanvas?.width;
        const imageData = trimmedCanvas?.toDataURL();
        const newCanvasWidth = canvasReference.current?.getCanvas().parentElement?.offsetWidth;
        const newCanvasHeight = canvasReference.current?.getCanvas().parentElement?.offsetHeight;
        setCanvasWidth(newCanvasWidth);
        if (imageData && imageWidth && imageHeight && newCanvasHeight && newCanvasWidth) {
          const loadImage = (url: string) => {
            return new Promise<HTMLImageElement>((res, rej) => {
              const img = new Image();
              img.onload = () => {
                res(img);
              };
              img.onerror = rej;
              img.src = url;
            });
          };
          loadImage(imageData).then((img) => {
            let x = (newCanvasWidth - imageWidth) * 0.5,
              y = (newCanvasHeight - imageHeight) * 0.5;
            const ctx = canvasReference.current?.getCanvas().getContext("2d");
            ctx?.drawImage(img, x, y);
          });
        }
      }
    };
    window.addEventListener("resize", resizeCanvas);
    setCanvasWidth(canvasReference.current?.getCanvas().parentElement?.offsetWidth);
  }, [canvasReference, handleSignature, signatureImageData]);

  return (
    <Grid container direction="column">
      <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
        <Grid item>
          <InputLabel sx={{ marginTop: "16px" }}>{labelText}</InputLabel>
        </Grid>
        <Grid item>
          <StyledButtonSecondaryGreyMini
            onClick={() => {
              canvasReference.current?.clear();
              handleSignature(false);
            }}
            data-testid="AsolviSignoff-ButtonClear"
          >
            <FormattedMessage id="general.clear" />
          </StyledButtonSecondaryGreyMini>
        </Grid>
      </Grid>

      <Grid item sx={{ width: "100%" }}>
        <ReactSignatureCanvas
          penColor="black"
          data-testid="canvas"
          canvasProps={{
            width: canvasWidth,
            height: 220,
          }}
          onEnd={() => {
            handleSignature(true);
          }}
          ref={canvasReference}
        />
      </Grid>
    </Grid>
  );
};
