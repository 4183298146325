import CheckIcon from "@mui/icons-material/Check";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useIntl } from "react-intl";

import { ListItemButton } from "@mui/material";
import engineerSettings from "helpers/engineerSettings";
import { MenuItem } from "models/MenuItem";
import { useAppDispatch, useAppSelector } from "store";
import { setPlannerDisplay } from "store/slices/planner.store";
import { isFlagEnabled } from "store/slices/user.store";

export const PlannerDisplayAsList = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { plannerDisplay } = useAppSelector((s) => s.planner);
  const mapViewEnabled =
    useAppSelector((s) => isFlagEnabled(s, "MapView")) && engineerSettings.mapboxToken !== "";

  const viewList: MenuItem[] = [
    {
      text: intl.formatMessage({ id: "menu.displayAs" }),
      childItems: [
        {
          text: intl.formatMessage({ id: "menu.display.list" }),
          selected: plannerDisplay === "list",
          onClick: () => {
            dispatch(setPlannerDisplay({ display: "list" }));
          },
          testId: "display-list",
        },
        {
          text: intl.formatMessage({ id: "menu.display.calendar" }),
          selected: plannerDisplay === "calendar",
          onClick: () => {
            dispatch(setPlannerDisplay({ display: "calendar" }));
          },
          testId: "display-calendar",
        },
        {
          text: intl.formatMessage({ id: "menu.display.map" }),
          selected: plannerDisplay === "map",
          onClick: () => {
            dispatch(setPlannerDisplay({ display: "map" }));
          },
          testId: "display-map",
          hide: !mapViewEnabled,
        },
      ],
    },
  ];

  return (
    <div data-testid="PlannerDisplayAsList">
      {viewList.map(({ text, childItems }) => (
        <div key={text + "listItem"}>
          <ListItem dense>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ style: { fontWeight: "bold" } }}
            />
          </ListItem>
          <List disablePadding>
            {childItems?.map(
              ({ text: subListText, testId, selected, onClick, className, hide }: MenuItem) => {
                if (hide) return null;
                return (
                  <ListItemButton
                    key={subListText + "item"}
                    dense
                    onClick={onClick}
                    className={className}
                    data-testid={`PlannerDisplayAsList-Button-${testId}`}
                  >
                    {selected && <CheckIcon color="primary" />}
                    <ListItemText
                      primary={subListText}
                      primaryTypographyProps={{
                        style: { marginLeft: selected ? "16px" : "40px" },
                      }}
                    />
                  </ListItemButton>
                );
              }
            )}
          </List>
        </div>
      ))}
    </div>
  );
};
