import { Grid } from "@mui/material";
import { formatTimeDiff } from "helpers";
import { FC } from "react";
import { useIntl } from "react-intl";

import { Times } from "models/travelTimes";
import { useAppSelector } from "store";
import { selectSelectedJob } from "store/slices/history.store";

import TimeBlockComponent from "./TimeBlockComponent";
import { TotalTimeComponent } from "./TotalTimeComponent";

export type TravelType = {
  travelTimes: Times[];
};

export const TravelTab: FC = () => {
  const intl = useIntl();
  const { travelTimes } = useAppSelector(selectSelectedJob);

  return (
    <>
      <Grid container direction="column" spacing={1} width="100%" pl={1} pr={1}>
        {travelTimes.map((times, index) => {
          return (
            <div key={`${times.startTime}`}>
              {times.startTime !== null && (
                <Grid item>
                  <TimeBlockComponent
                    times={times}
                    id={index}
                    timesLabel="travelTimes"
                    timesText={intl.formatMessage({
                      id: "times.travelTime",
                    })}
                    totalTime={formatTimeDiff(
                      intl,
                      Math.floor(
                        (new Date(times.stopTime!).getTime() -
                          new Date(times.startTime!).getTime()) /
                          1000 /
                          60
                      )
                    )}
                  />
                </Grid>
              )}
            </div>
          );
        })}
        <Grid item xs={12} ml={1}>
          <TotalTimeComponent timesMerged={travelTimes} />
        </Grid>
      </Grid>
    </>
  );
};
