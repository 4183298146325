import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AsolviDatePicker } from "components/AsolviDatePicker";
import { AsolviTimePicker } from "components/AsolviTimePicker";
import { toDate } from "helpers";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "store";
import { selectSelectedJobVisit } from "store/slices/jobs.store";

const PREFIX = "TimeBlock";
const classes = {
  field: `${PREFIX}-field`,
};
const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.field}`]: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

interface TimeBlockComponentProps {
  id: number;
  isDeleteDisabled?: boolean;
  setIndex: (index: number) => void;
  setOpenDeleteDialog: (open: boolean) => void;
  showLastOne?: boolean;
  timeBlockOnChange: (
    id: number,
    fieldname: "startDate" | "startTime" | "stopDate" | "stopTime",
    value: Date
  ) => void;
  timesLabel: "workTimes" | "travelTimes";
  timesText: string;
  totalTime: string;
}

const TimeBlockComponent: FC<TimeBlockComponentProps> = (props) => {
  const {
    id,
    isDeleteDisabled,
    setIndex,
    setOpenDeleteDialog,
    showLastOne,
    timeBlockOnChange,
    timesLabel,
    timesText,
    totalTime,
  } = props;
  const intl = useIntl();
  const visit = useAppSelector(selectSelectedJobVisit);

  return (
    <StyledGrid container spacing={1}>
      <Grid item container direction="row" spacing={1} wrap="nowrap" justifyContent="space-between">
        <Grid item ml={1}>
          <Typography variant="body1">
            {timesText}: {totalTime}
          </Typography>
        </Grid>
        <Grid item mr={1}>
          {isDeleteDisabled ? (
            <DeleteIcon color="disabled" onClick={() => {}} />
          ) : (
            <DeleteIcon
              color="error"
              onClick={() => {
                setIndex(id);
                setOpenDeleteDialog(true);
              }}
              data-testid="TimeBlockComponent-DeleteButton"
            />
          )}
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={1} wrap="nowrap">
        <Grid item xs={6}>
          <AsolviDatePicker
            name={`${timesLabel}[${id}].startDate`}
            label={intl.formatMessage({ id: "times.startDate" })}
            required
            value={toDate(visit[timesLabel][id].startDate)}
            onChange={(value: Date) => timeBlockOnChange?.(id, "startDate", value)}
            error={visit.errors[`${timesLabel}[${id}].startDate`]}
          />
        </Grid>
        <Grid item xs={6}>
          <AsolviTimePicker
            name={`${timesLabel}[${id}].startTime`}
            label={intl.formatMessage({ id: "times.startTime" })}
            required
            value={toDate(visit[timesLabel][id].startTime)}
            onChange={(value: Date) => timeBlockOnChange?.(id, "startTime", value)}
            error={visit.errors[`${timesLabel}[${id}].startTime`]}
          />
        </Grid>
      </Grid>
      {showLastOne && (
        <Grid item container direction="row" spacing={1} wrap="nowrap">
          <Grid item xs={6}>
            <AsolviDatePicker
              name={`${timesLabel}[${id}].stopDate`}
              label={intl.formatMessage({ id: "visit.endDate" })}
              required
              value={toDate(visit[timesLabel][id].stopDate)}
              onChange={(value: Date) => timeBlockOnChange?.(id, "stopDate", value)}
              error={visit.errors[`${timesLabel}[${id}].stopDate`]}
            />
          </Grid>
          <Grid item xs={6}>
            <AsolviTimePicker
              name={`${timesLabel}[${id}].stopTime`}
              label={intl.formatMessage({ id: "visit.endTime" })}
              required
              value={toDate(visit[timesLabel][id].stopTime)}
              onChange={(value: Date) => timeBlockOnChange?.(id, "stopTime", value)}
              error={visit.errors[`${timesLabel}[${id}].stopTime`]}
            />
          </Grid>
        </Grid>
      )}
    </StyledGrid>
  );
};

export default TimeBlockComponent;
