import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  AppBar,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";

import { AsolviDatePicker } from "components/AsolviDatePicker";
import { AsolviTimePicker } from "components/AsolviTimePicker";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import { StyledContainer } from "components/StyledContainer";
import { isAbortError, toDate, toDateString } from "helpers";
import { PlannedDateValidationSchema } from "models/PlannedDateValidationSchema";
import { TimesType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import {
  handleCloseUpdatePlannedDate,
  selectSelectedJob,
  setPlannedDate,
  updatePlannedDate,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { ValidationError } from "yup";

export const PlannedDateEditDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { open, loading } = useAppSelector((s) => s.jobs.updatePlannedDate);

  const [errors, setErrors] = useState<any>({});
  const [plannedTimes, setPlannedTimes] = useState<TimesType>(job.plannedDate || {});

  const handleClose = () => {
    dispatch(handleCloseUpdatePlannedDate());
  };

  const updatePlannedDateCb = useCallback(() => {
    dispatch(updatePlannedDate({ jobId: job.id, times: plannedTimes }))
      .then(unwrapResult)
      .then(({ queued }) => {
        if (queued) {
          dispatch(addSnackbarMessage({ key: "UpdatePlannedDate-stored" }));
        } else {
          dispatch(addSnackbarMessage({ key: "UpdatePlannedDate-success" }));
        }
        dispatch(setPlannedDate({ jobId: job.id, times: plannedTimes }));
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "UpdatePlannedDate-fail" }));
      });
  }, [dispatch, job.id, plannedTimes]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} data-testid="ChangePlannedDateDialog">
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="ChangePlannedDateDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            {`${job?.externalId} `} / <FormattedMessage id="visit.changePlannedTimes" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        <Grid
          item
          container
          alignItems="start"
          justifyContent="space-between"
          spacing={2}
          sx={{ margin: 0, paddingRight: 3 }}
        >
          <Grid item container direction="row" spacing={1} wrap="nowrap">
            <Grid item xs={6}>
              <AsolviDatePicker
                name={`PlannedDate-startDate`}
                label={intl.formatMessage({ id: "times.startDate" })}
                required
                value={toDate(plannedTimes.startTime)}
                onChange={(value: Date) =>
                  setPlannedTimes({
                    startTime: toDateString(value),
                    stopTime: plannedTimes.stopTime,
                  })
                }
                error={errors?.startDate}
              />
            </Grid>
            <Grid item xs={6}>
              <AsolviTimePicker
                name={`PlannedDate-startTime`}
                label={intl.formatMessage({ id: "times.startTime" })}
                required
                value={toDate(plannedTimes.startTime)}
                onChange={(value: Date) =>
                  setPlannedTimes({
                    startTime: toDateString(value),
                    stopTime: plannedTimes.stopTime,
                  })
                }
                error={errors?.startTime}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={1} wrap="nowrap">
            <Grid item xs={6}>
              <AsolviDatePicker
                name={`PlannedDate-stopDate`}
                label={intl.formatMessage({ id: "visit.endDate" })}
                required
                value={toDate(plannedTimes.stopTime)}
                onChange={(value: Date) =>
                  setPlannedTimes({
                    startTime: plannedTimes.startTime,
                    stopTime: toDateString(value),
                  })
                }
                error={errors?.stopDate}
              />
            </Grid>
            <Grid item xs={6}>
              <AsolviTimePicker
                name={`PlannedDate-stopTime`}
                label={intl.formatMessage({ id: "visit.endTime" })}
                required
                value={toDate(plannedTimes.stopTime)}
                onChange={(value: Date) =>
                  setPlannedTimes({
                    startTime: plannedTimes.startTime,
                    stopTime: toDateString(value),
                  })
                }
                error={errors?.stopTime}
              />
            </Grid>
          </Grid>
          <Grid item width="100%">
            <PrimaryButton
              key={`change-planned-date-submit-button`}
              fullWidth
              variant="contained"
              disabled={loading}
              onClick={() => {
                const validationSchema = PlannedDateValidationSchema();
                const isValid = validationSchema.isValidSync(plannedTimes);
                if (isValid) {
                  updatePlannedDateCb();
                  handleClose();
                  setErrors({});
                } else {
                  try {
                    validationSchema.validateSync(plannedTimes, {
                      strict: true,
                      abortEarly: false,
                    });
                  } catch (e: any) {
                    let { inner } = e as ValidationError;
                    let errors: any = {};
                    for (let error of inner) {
                      if (!error.path) continue;
                      errors[error.path] = error.message;
                    }
                    setErrors({ ...errors });
                  }
                }
              }}
              data-testid="ChangePlannedDate-SaveButton"
            >
              <FormattedMessage id="general.save" />
            </PrimaryButton>
          </Grid>
        </Grid>
      </StyledContainer>
    </Dialog>
  );
};
