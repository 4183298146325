import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";
import StyledButtonSecondaryMini from "components/StyledButtonSecondaryMini";

type PromptDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onOk?: () => void;
  showOk?: boolean;
  okText?: string;
  okDisabled?: boolean;
  onCancel?: () => void;
  showCancel?: boolean;
  cancelText?: string;
  title?: JSX.Element | string;
  promptContent: JSX.Element;
  leftActionText?: string;
  "data-testid"?: string;
};

export const PromptDialog: FC<PromptDialogProps> = (props) => {
  const {
    open,
    setOpen,
    onOk,
    showOk = true,
    okText,
    okDisabled,
    onCancel,
    showCancel = true,
    cancelText,
    title,
    promptContent,
    leftActionText,
    "data-testid": dataTestId,
  } = props;

  return (
    <Dialog
      open={open}
      data-testid={dataTestId ? dataTestId : "PromptDialog"}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>{promptContent}</DialogContent>
      <DialogActions
        sx={{ height: 60, pl: 2, pr: 2, pt: 0 }}
        style={{ justifyContent: "space-evenly" }}
      >
        {leftActionText && (
          <Typography mr="auto" pl={1}>
            {leftActionText}
          </Typography>
        )}
        {showOk && (
          <StyledButtonPrimaryMini
            disabled={okDisabled}
            data-testid="PromptDialog-OkButton"
            onClick={() => {
              if (onOk) onOk();
              setOpen(false);
            }}
          >
            {okText || <FormattedMessage id={"general.yes"} />}
          </StyledButtonPrimaryMini>
        )}
        {showCancel && (
          <StyledButtonSecondaryMini
            data-testid="PromptDialog-CancelButton"
            onClick={() => {
              if (onCancel) onCancel();
              setOpen(false);
            }}
            autoFocus
          >
            {cancelText || <FormattedMessage id={"general.cancel"} />}
          </StyledButtonSecondaryMini>
        )}
      </DialogActions>
    </Dialog>
  );
};
