import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const PREFIX = "AsolviSwitch";

const classes = {
  root: `${PREFIX}-root`,
  switchBase: `${PREFIX}-switchBase`,
  thumb: `${PREFIX}-thumb`,
  track: `${PREFIX}-track`,
  checked: `${PREFIX}-checked`,
};

const StyledSwitch = styled(Switch)(({ theme }) => ({
  [`& .${classes.track}`]: {
    opacity: 1,
    backgroundColor: theme.palette.error.main,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 20,
      height: 20,
      color: theme.palette.common.white,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="white" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>')`,
      left: 10,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="white" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>')`,
      right: 10,
    },
  },

  [`& .${classes.thumb}`]: {
    width: 26,
    height: 26,
    boxShadow: "none",
    color: theme.palette.common.white,
  },

  [`&.${classes.root}`]: {
    width: 70,
    height: 30,
    padding: 0,
    borderRadius: 50,
  },

  [`& .${classes.switchBase}`]: {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(40px) !important",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main,
      },
    },
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
    },
  },
}));

export const AsolviToggle = (props: SwitchProps) => {
  return (
    <StyledSwitch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};
