import AddIcon from "@mui/icons-material/Add";
import BuildIcon from "@mui/icons-material/Build";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";

import { unwrapResult } from "@reduxjs/toolkit";
import { EngineerAssign } from "components/EngineerAssign";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import { PromptDialog } from "components/PromptDialog";
import { GraphQLError } from "graphql";
import { isAbortError } from "helpers";
import { AssignTaskMutationVariables } from "operations/schema/schema";
import { getEngineers } from "store/slices/cache.store";
import { assignTask } from "store/slices/planner.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

const StyledContainer = styled("div")(() => ({
  zIndex: "9001",
}));

export const PlannerActions: FC = () => {
  const job = useAppSelector(selectPageSelectedJob);
  const { userVar: userData } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [assignToMePrompt, setAssignToMePrompt] = useState(false);
  const [assignDialog, setAssignDialog] = useState(false);
  const scheduleStartDate = job.plannedDate?.startTime;

  useEffect(() => {
    dispatch(getEngineers());
  }, [dispatch]);

  const assignTaskCb = useCallback(
    ({ jobId, engineerId, scheduleStartDate }: AssignTaskMutationVariables) => {
      dispatch(
        assignTask({
          jobId,
          engineerId,
          scheduleStartDate,
        })
      )
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "AssignTask-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "AssignTask-success" }));
          }
          navigate("/planner", { state: { openFilter: false } });
        })
        .catch((error: GraphQLError[] | Error) => {
          if (isAbortError(error)) return;
          if (Array.isArray(error) && error.some((e) => e.message.includes("403 Forbidden"))) {
            dispatch(addSnackbarMessage({ key: "AssignTask-forbidden" }));
          }
          dispatch(addSnackbarMessage({ key: "AssignTask-fail" }));
        });
    },
    [dispatch, navigate]
  );

  return (
    <StyledContainer data-testid="PlannerActionsContainer">
      <div style={{ margin: "16px" }} data-testid="PlannerActionButtons">
        <PrimaryButton
          startIcon={<AddIcon />}
          onClick={() => setAssignToMePrompt(true)}
          data-testid="PlannerActionsAssignToMe"
          style={{ textTransform: "none" }}
        >
          <FormattedMessage id="planner.assignToMe" />
        </PrimaryButton>
        <PrimaryButton
          startIcon={<BuildIcon />}
          onClick={() => setAssignDialog(true)}
          data-testid="PlannerActionsReAssignJob"
          style={{ textTransform: "none" }}
        >
          <FormattedMessage id="planner.reAssignJob" />
        </PrimaryButton>
      </div>
      <PromptDialog
        open={assignToMePrompt}
        setOpen={setAssignToMePrompt}
        onOk={() => {
          assignTaskCb({
            jobId: job.id,
            engineerId: userData?.externalId!,
            scheduleStartDate: scheduleStartDate,
          });
        }}
        promptContent={
          <>
            <Typography mb={2} align="center" variant="subtitle1">
              <FormattedMessage id="planner.assignToMePrompt" />
            </Typography>
          </>
        }
      />
      <FullScreenDialog open={assignDialog} setOpen={setAssignDialog} child={<EngineerAssign />} />
    </StyledContainer>
  );
};
