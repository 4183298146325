import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { clearQueue } from "context/graphql/queue";
import { getDefaultUserLanguage, languages } from "models/Setting";
import {
  Auth0LoginMutationVariables,
  PreLoginMutationVariables,
  UserConsentType,
  UserSettingsInputType,
} from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./user.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

// Definition of actual actions
export const asyncMutations = {
  preLogin: createAppAsyncThunk(
    "user/preLogin",
    async (variables: PreLoginMutationVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.preLogin, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.preLogin) return rejectWithValue("something went wrong");
      return data.preLogin;
    }
  ),
  loginAuth0: createAppAsyncThunk(
    "user/loginAuth0",
    async (variables: Auth0LoginMutationVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.auth0Login, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.auth0Login) return rejectWithValue("something went wrong");
      return data.auth0Login;
    }
  ),
  logout: createAppAsyncThunk("user/logout", async (_, { rejectWithValue, extra: { sdk } }) => {
    const { data, errors, queued } = await graphqlRequest(sdk.logout, {
      thunkName: "user/logout",
    });
    if (errors) return rejectWithValue(errors);
    if (data && !data?.logout) return rejectWithValue("something went wrong");
    return { data, queued };
  }),
  updateUserConsent: createAppAsyncThunk(
    "user/updateUserConsent",
    async (props: { consent: UserConsentType }, { rejectWithValue, extra: { sdk } }) => {
      const { consent } = props;
      const { data, errors } = await graphqlRequest(sdk.addUserConsent, {
        variables: {
          //TODO; Necessary to handle multiple if all we do is update one at a time?
          userConsents: [consent],
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.addUserConsent) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  updateUserSettings: createAppAsyncThunk(
    "user/updateUserSettings",
    async (props: UserSettingsInputType, { rejectWithValue, extra: { sdk } }) => {
      let { lastEndpoint: le, userEndpoints: ue, useMultipleEndpoints } = props;
      const settings: UserSettingsInputType = {
        lastEndpoint: stripTypename(le!),
        userEndpoints: ue!.map((e) => stripTypename(e!)),
        useMultipleEndpoints: useMultipleEndpoints,
      };
      const { data, errors } = await graphqlRequest(sdk.updateUserSettings, {
        thunkName: "user/updateUserSettings",
        thunkProps: props,
        variables: { userSettings: settings },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.userSettings) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  updateEngineerMetadata: createAppAsyncThunk(
    "user/updateEngineerMetadata",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.updateEngineerMetadata);
      if (errors) return rejectWithValue(errors);
      if (!data?.updateEngineerMetadata) return rejectWithValue("something went wrong");
      return data.updateEngineerMetadata;
    }
  ),
};
const keysToSkip = new Set(["__typename"]);
function stripTypename<T>(item: T): Omit<T, "__typename"> {
  let result: any = {};
  for (let key in item) {
    if (keysToSkip.has(key)) continue;
    result[key] = item[key];
  }
  return result as Omit<T, "__typename">;
}

export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.preLogin.pending, (state, { meta }) => {
    if (meta.queued) return state;
    // TODO ??
  });
  builder.addCase(asyncMutations.preLogin.rejected, (state, { payload: errors, meta }) => {
    if (meta.aborted) return state;
    // TODO ??
  });
  builder.addCase(asyncMutations.preLogin.fulfilled, (state, { payload: data }) => {
    // TODO ??
  });
  builder.addCase(asyncMutations.loginAuth0.pending, (state, { meta }) => {
    if (meta.queued) return state;
    // TODO ??
  });
  builder.addCase(asyncMutations.loginAuth0.rejected, (state, { payload: errors, meta }) => {
    if (meta.aborted) return state;
    localStorage.removeItem("auth0Props");
    state.authVar = {
      ...state.authVar,
      message: "Auth0 failed", // TODO Find better way of handling this
    };
    return state;
  });
  builder.addCase(asyncMutations.loginAuth0.fulfilled, (state, { payload: data }) => {
    const auth = {
      ...data,
      expiry: data.expiry,
      isLoggedIn: true,
    };
    state.authVar = auth;
    state.userVar = auth;
    const languageCode =
      languages.find((lang) => lang === auth?.languageCode) || getDefaultUserLanguage();
    state.languageVar = languageCode;
    localStorage.setItem("currentUser", JSON.stringify(auth));
    localStorage.setItem("authState", JSON.stringify(auth));
    localStorage.setItem("language", languageCode);
    return state;
  });
  builder.addCase(asyncMutations.logout.pending, (state, { meta }) => {
    if (meta.queued) return state;
    // TODO ??
  });
  builder.addCase(asyncMutations.logout.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    // TODO ??
  });
  builder.addCase(asyncMutations.logout.fulfilled, (state) => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("authState");
    localStorage.removeItem("auth0Props");
    state.userVar = null;
    state.authVar = { isLoggedIn: false };
    clearQueue(); // Make sure the queue is cleared
    return state;
  });
  builder.addCase(asyncMutations.updateUserConsent.fulfilled, (state, { payload: data }) => {
    if (!state.userVar) return state;
    state.userVar.userConsents = [...data.addUserConsent];
    localStorage.setItem("currentUser", JSON.stringify({ ...state.userVar }));
    return state;
  });
  builder.addCase(asyncMutations.updateUserSettings.fulfilled, (state, { payload: data }) => {
    state.userSettings = data.userSettings;
    return state;
  });
  builder.addCase(
    asyncMutations.updateEngineerMetadata.rejected,
    (state, { payload: errors, meta }) => {
      if (meta.aborted) return state;
      return state;
    }
  );
  builder.addCase(asyncMutations.updateEngineerMetadata.fulfilled, (state, data) => {
    return state;
  });
};
