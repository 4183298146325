import { EngineerSettings } from "operations/schema/schema";

const engineerSettings: EngineerSettings = {
  allowChangeInvoiceType: false,
  allowNegativeStock: false,
  syncSalesPrice: false,
  syncCostPrice: false,
  canChangeEquipmentOnTicket: false,
  canCreateJob: false,
  canEditCustomEquipmentProps: false,
  customerSatisfactionEnabled: false,
  isSupervisor: false,
  mapboxToken: "",
  requireActionCode1: false,
  requireActionCode2: false,
  requireActionCode3: false,
  requireActionText: false,
  requireActionTextReturn: false,
  requireCheckListId: false,
  requireCustomerSignature: false,
  requireCustomerSignatureName: false,
  requireEngineerSignature: false,
  requireMeterReading: false,
  requireReference: false,
  requireSymptomCode1: false,
  requireSymptomCode2: false,
  requireSymptomCode3: false,
  requireSymtomText: false,
  requireTravelTime: false,
  requireTravelTimeReturn: false,
  requireWorkTime: false,
  requireWorkTimeReturn: false,
  requiredSolutionDescription: false,
  sendMailToCustomer: false,
};

export default engineerSettings;
