import { Alert, AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { isMobileSafari, versionCleanup } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { getPublicFeatureFlags } from "store/slices/user.store";

import { Version } from "components/Version";

import { ReactComponent as Logo } from "assets/a2h.svg";
import { setForcedOffline } from "store/root.store";
import { PreLoginForm } from "./PreLogin";

const PREFIX = "Login";

const classes = {
  paper: `${PREFIX}-paper`,
  wrapper: `${PREFIX}-wrapper`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  versionFont: `${PREFIX}-versionFont`,
  select: `${PREFIX}-select`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.buttonProgress}`]: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  [`& .${classes.versionFont}`]: {
    lineHeight: "1.75",
  },
  [`& .${classes.select}`]: {
    "& label.Mui-focused": {
      color: theme.palette.info.main,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: theme.palette.info.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.info.main,
      },
    },
  },
}));

const shouldShowInstallPrompt = () => {
  window.addEventListener("DOMContentLoaded", () => {
    let newNavigator: any = navigator;
    if (newNavigator.standalone) {
      return false;
    }
    if (window.matchMedia("(display-mode: standalone)").matches) {
      return false;
    }
  });

  if (localStorage.getItem("showPrompt") === "false") {
    return false;
  }

  return isMobileSafari();
};

export const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const { authVar } = useAppSelector((state) => state.user);

  const [searchParams] = useSearchParams();

  const [showPrompt, setShowPrompt] = useState(shouldShowInstallPrompt());

  const [email, setEmail] = useState(authVar.email);
  const [preLoginLoading, setPreLoginLoading] = useState(false);

  var emsUrl = searchParams.get("emsUrl");
  if (emsUrl?.slice(emsUrl.length - 1) === "/") {
    emsUrl = emsUrl.slice(0, emsUrl.length - 1);
  }

  useEffect(() => {
    versionCleanup();
  }, []);

  useEffect(() => {
    dispatch(getPublicFeatureFlags());
    dispatch(setForcedOffline({ status: false }));
  }, [dispatch]);

  const handleClosePrompt = () => {
    localStorage.setItem("showPrompt", "false");
    setShowPrompt(false);
  };

  return (
    <StyledContainer maxWidth="xs">
      <CssBaseline />
      {showPrompt && (
        <Alert onClose={handleClosePrompt} severity="info" sx={{ mt: 2 }} data-testid="Login-Alert">
          <AlertTitle>Install to Home Screen</AlertTitle>
          <Typography component="div">
            Just tap <Logo style={{ width: 20, height: 20 }} title="icon" /> and then{" "}
            <Box fontWeight="fontWeightMedium" display="inline">
              Add to Home Screen
            </Box>
          </Typography>
        </Alert>
      )}
      <div className={classes.paper}>
        <Typography component="h2" variant="h5">
          <FormattedMessage id="general.welcomeTo" />
        </Typography>
        <Box
          component="img"
          src="/evatic_logo.svg"
          alt="Evatic by Asolvi logo"
          sx={{ width: "80%", mt: 6, mb: 6 }}
        />

        <PreLoginForm
          classes={classes}
          email={email}
          preLoginLoading={preLoginLoading}
          setPreLoginLoading={setPreLoginLoading}
          setEmail={setEmail}
          url={emsUrl ?? ""}
        />
        <Version />
      </div>
    </StyledContainer>
  );
};
