import { Autocomplete } from "@mui/material";
import StyledTextField from "components/StyledTextField";
import { FC } from "react";
import { useIntl } from "react-intl";

export interface InvoiceAutocompleteProps {
  onChange: (value: any) => void;
  value: any;
}

export const InvoiceAutocomplete: FC<InvoiceAutocompleteProps> = (props) => {
  const { onChange, value } = props;
  const intl = useIntl();

  const invoiceOptions = [
    { value: null, label: intl.formatMessage({ id: "visit.contract" }) }, // Default option
    { value: "1", label: intl.formatMessage({ id: "visit.invoiceable" }) },
    { value: "0", label: intl.formatMessage({ id: "visit.notInvoiceable" }) },
  ];

  const findMatch = (value: string) => {
    const match = invoiceOptions.find((o) => o.value === value);
    return match ?? null;
  };

  return (
    <Autocomplete
      fullWidth
      options={invoiceOptions}
      getOptionLabel={(o) => `${o.label}`}
      value={findMatch(value)}
      renderInput={(params) => (
        <StyledTextField
          name="invoiceType"
          {...params}
          label={intl.formatMessage({ id: "visit.invoice" })}
        />
      )}
      onChange={(_, value) => {
        onChange(value?.value);
      }}
    />
  );
};
