import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { subPeriod, toDateString } from "helpers";
import { Jobs } from "models/Job";
import { ServiceJob } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./history.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getHistoryJob: createAppAsyncThunk(
    "history/getHistoryJob",
    async (
      onCompleted: ((job: ServiceJob) => void) | undefined,
      { getState, rejectWithValue, extra: { sdk } }
    ) => {
      let { root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getJob, {
        variables: {
          id: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.job) return rejectWithValue("something went wrong");
      return { data, selectedJobId, onCompleted };
    }
  ),
  getEngineerHistory: createAppAsyncThunk(
    "history/getEngineerHistory",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      const filter = getState().history.historyFilter;
      const { data, errors } = await graphqlRequest(sdk.getEngineerHistory, {
        variables: {
          from: toDateString(subPeriod(filter.typeDate)),
          specificDate: toDateString(filter.specificDate),
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.engineerHistory) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getServiceHistory: createAppAsyncThunk(
    "history/getServiceHistory",
    async (
      { type, typeId }: { type?: string; typeId?: string },
      { getState, rejectWithValue, extra: { sdk } }
    ) => {
      const filter = getState().history.serviceHistoryFilter;
      const { data, errors } = await graphqlRequest(sdk.getServiceHistory, {
        variables: {
          equipmentId: type === "equipment" ? typeId : null,
          fromDate: toDateString(subPeriod(filter.typeDate)),
          specificDate: toDateString(filter.specificDate),
          customerId: type === "equipment" ? null : typeId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.serviceHistory) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getFiles: createAppAsyncThunk(
    "history/getFiles",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getFiles, {
        variables: {
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.files) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getNotes: createAppAsyncThunk(
    "history/getNotes",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { history, root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const selectedJob = history.jobs[selectedJobId];
      const { data, errors } = await graphqlRequest(sdk.getWorkNotes, {
        variables: {
          workNoteArgs: {
            jobId: selectedJobId,
            contractId: selectedJob.contractId,
            customerId: selectedJob.customer?.id,
            equipmentId: selectedJob.equipment?.id,
          },
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.workNotes) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getRelatedJobs: createAppAsyncThunk(
    "history/getRelatedJobs",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { jobs } = getState();
      const selectedJobId = jobs.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const selectedJob = jobs.jobs[selectedJobId];
      const { data, errors } = await graphqlRequest(sdk.getRelatedJobs, {
        variables: {
          max: 100,
          customerId: selectedJob.customer?.id,
          equipmentId: selectedJob.equipment?.id,
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.relatedJobs) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getVisits: createAppAsyncThunk(
    "history/getVisits",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      const selectedJobId = getState().root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getJobVisitsHistory, {
        variables: {
          max: 20,
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.jobVisitsHistory) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getHistoryJob.pending, (state) => {
    state.loadingJob = true;
    return state;
  });
  builder.addCase(asyncQueries.getHistoryJob.rejected, (state) => {
    state.loadingJob = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getHistoryJob.fulfilled,
    (state, { payload: { data, selectedJobId, onCompleted } }) => {
      let { job } = data;
      if (!selectedJobId || !job) return state;
      /**
       * While this "if" shouldn't be necessary, selected job can be undefined
       * if you start the app or reload while viewing a job
       */
      if (!state.jobs[selectedJobId]) {
        state.jobs[selectedJobId] = {
          ...job,
          files: [],
          workNotes: [],
          visits: [],
          relatedJobs: [],
        };
      } else {
        state.jobs[selectedJobId] = {
          ...state.jobs[selectedJobId],
          ...job,
          files: state.jobs[selectedJobId].files || [],
          workNotes: state.jobs[selectedJobId].workNotes || [],
          visits: state.jobs[selectedJobId].visits || [],
          relatedJobs: state.jobs[selectedJobId].relatedJobs || [],
        };
      }
      state.loadingJob = false;
      if (onCompleted) onCompleted(job);
      return state;
    }
  );
  builder.addCase(asyncQueries.getEngineerHistory.pending, (state) => {
    state.loadingJobs = true;
    return state;
  });
  builder.addCase(asyncQueries.getEngineerHistory.rejected, (state) => {
    state.loadingJobs = false;
    return state;
  });
  builder.addCase(asyncQueries.getEngineerHistory.fulfilled, (state, { payload: data }) => {
    let serviceJobs = data.engineerHistory;
    let newJobs = serviceJobs.reduce((obj, next) => {
      obj[next.id] = {
        ...next,
        files: [],
        workNotes: [],
        visits: [],
        relatedJobs: [],
      };
      return obj;
    }, {} as Jobs);

    state.jobs = newJobs;
    state.loadingJobs = false;
    return state;
  });
  builder.addCase(asyncQueries.getServiceHistory.pending, (state) => {
    state.loadingServiceHistory = true;
    return state;
  });
  builder.addCase(asyncQueries.getServiceHistory.rejected, (state) => {
    state.loadingServiceHistory = false;
    return state;
  });
  builder.addCase(asyncQueries.getServiceHistory.fulfilled, (state, { payload: data }) => {
    state.serviceHistory = [...data.serviceHistory];
    state.loadingServiceHistory = false;
    return state;
  });

  builder.addCase(
    asyncQueries.getFiles.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId || !state.jobs[selectedJobId]) return state;
      state.jobs[selectedJobId].files = [...data.files];
      return state;
    }
  );
  builder.addCase(
    asyncQueries.getNotes.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId || !state.jobs[selectedJobId]) return state;
      state.jobs[selectedJobId].workNotes = [...data.workNotes];
      return state;
    }
  );
  builder.addCase(asyncQueries.getRelatedJobs.pending, (state) => {
    state.loadingRelatedJobs = true;
    return state;
  });
  builder.addCase(asyncQueries.getRelatedJobs.rejected, (state) => {
    state.loadingRelatedJobs = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getRelatedJobs.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId || !state.jobs[selectedJobId]) return state;
      state.jobs[selectedJobId].relatedJobs = [...data.relatedJobs];
      state.loadingRelatedJobs = false;
      return state;
    }
  );
  builder.addCase(
    asyncQueries.getVisits.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId || !state.jobs[selectedJobId]) return state;
      state.jobs[selectedJobId].visits = [...data.jobVisitsHistory];
      return state;
    }
  );
};
