import { SortGroupType, SupplierType } from "operations/schema/schema";

export const JobFilterStorageKey = "jobFilter";

export type AddPartFilter = {
  selectedSuppliers: SupplierType[];
  sortGroup1: SortGroupType[];
  sortGroup2: SortGroupType[];
  sortGroup3: SortGroupType[];
  sortGroup4: SortGroupType[];
  sortGroup5: SortGroupType[];
};

export const defaultPartFilter: AddPartFilter = {
  selectedSuppliers: [],
  sortGroup1: [],
  sortGroup2: [],
  sortGroup3: [],
  sortGroup4: [],
  sortGroup5: [],
};

type FilterKeys = (keyof typeof defaultPartFilter)[];
export const PartFilterKeys: FilterKeys = Object.keys(defaultPartFilter) as FilterKeys;
