import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { LinearProgressProps } from "@mui/material/LinearProgress";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";

import { getChecklistStatus } from "helpers";
import { ChecklistType } from "operations/schema/schema";
import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";

import { ChecklistDialog } from "./ChecklistDialog";

const LinearProgressWithLabel = (props: LinearProgressProps & { label: string }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 2 }}>
        <LinearProgress {...props} />
      </Box>
      <Box sx={{ minWidth: 50 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          {props.label}
        </Typography>
      </Box>
    </Box>
  );
};

export const ChecklistTab: FC = () => {
  const job = useAppSelector(selectPageSelectedJob);
  const [openChecklistDialog, setOpenChecklistDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const checklists = job.checklists || [];

  const getChecklistElement = (checklist: ChecklistType) => {
    const { isComplete, questionsAnswered, questionsCount } = getChecklistStatus(checklist);

    let labelElement: JSX.Element;

    if (isComplete) {
      labelElement = (
        <Typography color="success.main" display="inline">
          <FormattedMessage id="checklist.complete" />
        </Typography>
      );
    } else if (checklist.isTemplate) {
      labelElement = (
        <Typography display="inline">
          <FormattedMessage id="checklist.begin" />
        </Typography>
      );
    } else {
      labelElement = (
        <Typography color="info.main" display="inline">
          <FormattedMessage id="checklist.inProgress" />
        </Typography>
      );
    }

    const percentage = isComplete ? 100 : (questionsAnswered / questionsCount) * 100;

    const countLabel = `${questionsAnswered}/${questionsCount}`;

    return {
      labelElement,
      percentage,
      countLabel,
    };
  };

  return (
    <Container>
      <List>
        {checklists.map((checklist: ChecklistType, index, { length }) => {
          const { labelElement, percentage, countLabel } = getChecklistElement(checklist);

          return (
            <div key={checklist.checklistCode}>
              <ListItem
                key={checklist.title}
                disablePadding
                onClick={() => {
                  setSelectedIndex(index);
                  setOpenChecklistDialog(true);
                }}
                disableGutters
                secondaryAction={
                  <IconButton sx={{ padding: 0 }}>
                    {labelElement}
                    <KeyboardArrowRightIcon />
                  </IconButton>
                }
                data-testid={`ChecklistTab-ListItem-${checklist.checklistCode}`}
              >
                <ListItemButton disableGutters>
                  <ListItemText primary={checklist.title} sx={{ maxWidth: "80%" }} />
                </ListItemButton>
              </ListItem>
              <LinearProgressWithLabel
                color="success"
                variant="determinate"
                value={percentage}
                label={countLabel}
                sx={{ height: 10, backgroundColor: "secondary.light" }}
              />
              {index + 1 !== length && <Divider sx={{ mt: 1 }} />}
            </div>
          );
        })}
      </List>
      <ChecklistDialog
        open={openChecklistDialog}
        handleClose={() => {
          setOpenChecklistDialog(false);
          setSelectedIndex(null);
        }}
        index={selectedIndex!}
        checklist={checklists[selectedIndex!]}
        readonly={true}
      />
    </Container>
  );
};
