import { Edit as EditIcon, History as HistoryIcon } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { DetailAccordion } from "components/DetailAccordion";
import { EquipmentPropsDialog } from "components/job/equipment/EquipmentPropsDialog";
import { intlFormatDate, intlFormatDateTimeWithWeekday, isAbortError } from "helpers";
import engineerSettings from "helpers/engineerSettings";
import { ChangeJobEquipmentDialog } from "pages/jobs/dialogs/ChangeJobEquipmentDialog";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus, selectPageSelectedJob } from "store/root.store";
import {
  getJob,
  setChangeJobEquipmentOpen,
  setMachinePropertiesOpen,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { isFlagEnabled } from "store/slices/user.store";

const PREFIX = "JobDetailTab";

const classes = {
  bold: `${PREFIX}-bold`,
  historyIconAdjust: `${PREFIX}-eq-history-icon-adjust`,
  iconAutoMargin: `${PREFIX}-icon-auto-margin`,
  noPadding: `${PREFIX}-no-padding`,
  leftIndent: `${PREFIX}-left-indent`,
  disabled: `${PREFIX}-disabled`,
};

interface EquipmentDisplayProps {
  readonly?: boolean;
  planner?: boolean;
}

export const EquipmentDisplay: FC<EquipmentDisplayProps> = (props: EquipmentDisplayProps) => {
  const { readonly, planner } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);
  const isOnline = useAppSelector(selectConnectionStatus);
  const machineSortGroupsEnabled = useAppSelector((s) => isFlagEnabled(s, "MachineSortGroups"));
  const changeJobEquipmentEnabled = useAppSelector((s) => isFlagEnabled(s, "ChangeJobEquipment"));
  const canChangeEquipment =
    engineerSettings.canChangeEquipmentOnTicket &&
    isOnline &&
    changeJobEquipmentEnabled &&
    !readonly;

  const backTo = pathname.split("/").filter((x) => x)[0];
  const machineInstallDate = job.equipment?.installDate;

  const refetch = () => {
    dispatch(getJob())
      .then(unwrapResult)
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "GetJob-fail" }));
      });
  };

  const openServiceHistory = (id: string) => {
    if (!readonly) {
      navigate("/history/equipment/" + id, {
        state: {
          navTitle: job.equipment?.name,
          backTo: backTo,
        },
      });
    }
  };

  const historyIcon = (
    <HistoryIcon className={`${classes.historyIconAdjust} ${readonly ? classes.disabled : ""}`} />
  );

  const formatCustomProperty = function (type: string | undefined, value: any) {
    switch (type) {
      case "Boolean":
        return value
          ? intl.formatMessage({ id: "general.yes" })
          : intl.formatMessage({ id: "general.no" });
      case "DateTime":
        return intlFormatDateTimeWithWeekday(intl, value);
      case "Date":
        return intlFormatDate(intl, value);
      case "TimeOfDay":
      case "Decimal":
      case "DropDown":
      case "Int32":
      case "String":
      default:
        return value;
    }
  };

  return (
    <>
      <ListItem
        component="div"
        disableGutters
        className={classes.noPadding}
        data-testid="EquipmentDisplay"
      >
        {job.equipment?.id && !planner && (
          <ListItemIcon
            onClick={() => openServiceHistory(job.equipment!.id!)}
            className={`min-width-38 ${classes.iconAutoMargin}`}
            sx={{ alignSelf: "flex-start", mt: 1 }}
            data-testid="EquipmentDisplay-OpenEquipmentHistory"
          >
            {historyIcon}
          </ListItemIcon>
        )}
        <ListItemText
          className={classes.bold}
          primary={
            <>
              {job.equipment?.name}
              {canChangeEquipment && (
                <IconButton
                  aria-label={intl.formatMessage({ id: "visit.changeMachine" })}
                  onClick={() => dispatch(setChangeJobEquipmentOpen({ open: true }))}
                  data-testid="EquipmentDisplay-ChangeJobEquipment"
                  sx={{
                    fontSize: "1.2rem",
                    paddingY: 0,
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              )}
            </>
          }
        />
      </ListItem>

      <div className={classes.leftIndent}>
        <Typography>{job.equipment?.serialNumber}</Typography>
        {job.equipment?.assetNumber && <Typography>{job.equipment.assetNumber}</Typography>}
        {job.equipment?.location &&
          (job.equipment.location.startsWith("///") ? (
            <Link
              href={`https://what3words.com/${job.equipment.location.replace("///", "")}`}
              underline="none"
              color="blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              {job.equipment.location}
            </Link>
          ) : (
            <Typography>{job.equipment.location}</Typography>
          ))}
        {machineInstallDate && (
          <Typography>
            {`${intl.formatMessage({
              id: "general.installed",
            })} ${intlFormatDate(intl, machineInstallDate)}`}
          </Typography>
        )}
        {machineSortGroupsEnabled && !!job.equipment?.details && (
          <DetailAccordion
            data-testid="EquipmentDisplay-DetailAccordion"
            content={
              <>
                {!!job.equipment.details.information && (
                  <Typography>
                    {`${intl.formatMessage({
                      id: "job.equipment.details.information",
                    })}: ${job.equipment.details.information}`}
                  </Typography>
                )}
                {!!job.equipment.details.customProperties?.length && (
                  <Grid container>
                    <Grid item>
                      {job.equipment.details.customProperties.map((cp, index) => (
                        <Typography key={`${cp?.code}-${index}`}>
                          {`${cp?.description}: ${formatCustomProperty(cp?.type, cp?.value)}`}
                        </Typography>
                      ))}
                    </Grid>
                    <Grid item xs={2}>
                      {!readonly && engineerSettings?.canEditCustomEquipmentProps && (
                        <EditIcon
                          sx={{ ml: 3 }}
                          onClick={() => dispatch(setMachinePropertiesOpen({ open: true }))}
                          data-testid="EquipmentDisplay-OpenEditEquipment"
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {!!job.equipment.details.sortGroups?.length &&
                  job.equipment.details.sortGroups.map((sg, index) => (
                    <Typography key={`sortgroup-${index}`}>
                      {`${sg?.description}: ${sg?.value}`}
                    </Typography>
                  ))}
              </>
            }
          />
        )}
      </div>
      {canChangeEquipment && <ChangeJobEquipmentDialog handleFinished={refetch} />}
      {!readonly && job.equipment && engineerSettings?.canEditCustomEquipmentProps && (
        <EquipmentPropsDialog />
      )}
    </>
  );
};
