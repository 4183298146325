import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

import { ArrowBack as ArrowBackIcon, CropFree as CropFreeIcon } from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import { unwrapResult } from "@reduxjs/toolkit";

import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import { PromptDialog } from "components/PromptDialog";
import StyledTextField from "components/StyledTextField";
import { BarcodeScannerDialog } from "components/barcodeScan";
import { scanditLicense } from "env";
import { addressToString, isAbortError } from "helpers";
import { filterEquipmentList } from "helpers/filterEquipmentList";
import { EquipmentType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import {
  changeJobEquipment,
  getEquipment,
  selectIsJobInProgress,
  selectSelectedJob,
  setChangeJobEquipmentOpen,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

const StyledDetails = styled("div")(() => ({
  marginTop: 5,
  marginLeft: 14,
}));
interface ChangeJobEquipmentDialogProps {
  handleFinished: () => void;
}

export const ChangeJobEquipmentDialog: FC<ChangeJobEquipmentDialogProps> = (props) => {
  const { handleFinished } = props;

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { equipments, loading, loadingEquipments, open } = useAppSelector(
    (state) => state.jobs.changeJobEquipment
  );

  const [equipmentLoading, setEquipmentLoading] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [equipmentACOpen, setEquipmentACOpen] = useState(false);
  const [openVisitInProgressDialog, setOpenVisitInProgressDialog] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentType | null>(null);
  const [equipmentSearch, setEquipmentSearch] = useState<string>("");
  const [scannerValue, setScannerValue] = useState<string>("");
  const hasBarcodeScanner = !!scanditLicense();
  const currentEquipment = job?.equipment;
  const machineInstallDate = currentEquipment?.installDate;
  const jobInProgress = useAppSelector(selectIsJobInProgress);

  const close = useCallback(() => {
    setSelectedEquipment(null);
    setEquipmentSearch("");
    dispatch(setChangeJobEquipmentOpen({ open: false }));
  }, [dispatch]);

  const checkVisitInProgress = () => {
    if (jobInProgress) {
      setOpenVisitInProgressDialog(true);
    } else {
      onSave();
    }
  };

  const changeJobEquipmentCb = useCallback(
    (equipmentId: string) => {
      dispatch(changeJobEquipment({ jobId: job.id, equipmentId }))
        .then(unwrapResult)
        .then(() => {
          setEquipmentLoading(false);
          handleFinished();
          close();
          dispatch(addSnackbarMessage({ key: "ChangeJobEquipment-success" }));
        })
        .catch((error: any) => {
          if (isAbortError(error)) return;
          if (Array.isArray(error) && error.some((e) => e.message.includes("403 Forbidden"))) {
            dispatch(addSnackbarMessage({ key: "ChangeJobEquipment-forbidden" }));
          } else {
            dispatch(addSnackbarMessage({ key: "ChangeJobEquipment-fail" }));
          }
          setEquipmentLoading(false);
        });
    },
    [close, dispatch, handleFinished, job.id]
  );

  const onSave = () => {
    setEquipmentLoading(true);
    changeJobEquipmentCb(selectedEquipment?.id || "");
  };

  useEffect(() => {
    if (open) {
      if (job?.customer) {
        dispatch(
          getEquipment({
            serialNumber: "",
            customerId: job.customer.id,
          })
        );
      }
    }
  }, [open, job.customer, dispatch]);

  useEffect(() => {
    if (scannerValue) {
      const scanMatches = equipments.filter((equipment) => equipment.serialNumber === scannerValue);

      if (scanMatches.length === 1) {
        setSelectedEquipment(scanMatches[0]);
      }
      setScannerValue("");
    }
  }, [scannerValue, equipments]);

  return (
    <Dialog fullScreen open={open} onClose={close} data-testid="ChangeJobEquipment-Dialog">
      <BackdropPrimaryMain open={equipmentLoading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="ChangeJobEquipmentDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            {`${job?.externalId} `} / <FormattedMessage id="visit.changeMachine" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Card>
        {currentEquipment && (
          <CardHeader
            data-testid="ChangeJobEquipment-currentEquipment"
            sx={{
              fontSize: "1.2rem",
            }}
            subheader={
              <>
                <Typography fontWeight={700}>{currentEquipment.name}</Typography>
                <Typography>{currentEquipment.serialNumber}</Typography>
                {currentEquipment.assetNumber && (
                  <Typography>{currentEquipment.assetNumber}</Typography>
                )}
                {currentEquipment.location && <Typography>{currentEquipment.location}</Typography>}
                {machineInstallDate && (
                  <Typography>
                    {`${intl.formatMessage({ id: "general.installed" })} `}
                    <FormattedDate
                      value={machineInstallDate}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  </Typography>
                )}
              </>
            }
          />
        )}
        {!currentEquipment && (
          <CardHeader
            data-testid="ChangeJobEquipment-currentEquipment"
            sx={{
              fontSize: "1.2rem",
            }}
            subheader={
              <>
                <Typography fontWeight={700}>
                  <FormattedMessage id="changeJobEquipment.noMachine" />
                </Typography>
                <Typography></Typography>
              </>
            }
          />
        )}
        <CardContent sx={{ paddingTop: 0 }}>
          {!equipmentLoading && (
            <>
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  container
                  alignItems="start"
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <Grid item width="100%">
                    <Autocomplete
                      data-testid="ChangeJobEquipment-autocomplete"
                      options={equipments}
                      loading={loadingEquipments}
                      open={equipmentACOpen}
                      onOpen={() => setEquipmentACOpen(true)}
                      onClose={() => setEquipmentACOpen(false)}
                      value={selectedEquipment}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setSelectedEquipment(null);
                          setEquipmentSearch("");
                        } else {
                          if (value) {
                            setSelectedEquipment(value);
                          }
                        }
                      }}
                      getOptionLabel={(o: EquipmentType) => `${o.serialNumber}`}
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            key={`${option.id}-${option.name}`}
                            style={{ display: "block" }}
                            data-testid={`${option.id}-${option.serialNumber}`}
                          >
                            <Typography variant="body1">{option.serialNumber}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {option.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {option.assetNumber}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {option.location}
                            </Typography>
                          </li>
                        );
                      }}
                      inputValue={equipmentSearch}
                      onInputChange={(_, value) => {
                        if (scannerValue && !value) {
                          setEquipmentSearch(scannerValue);
                        } else {
                          setScannerValue("");
                          setEquipmentSearch(value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionDisabled={(option) => option.id === currentEquipment?.id}
                      filterOptions={(
                        options: EquipmentType[],
                        state: FilterOptionsState<EquipmentType>
                      ) => {
                        return filterEquipmentList(options, state.inputValue);
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          name="equipment"
                          {...params}
                          label={intl.formatMessage({
                            id: "job.equipment.serialNumber",
                          })}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadingEquipments ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                          required
                        />
                      )}
                    />
                    {!!selectedEquipment?.id && (
                      <StyledDetails data-testid="ChangeJobEquipment-SelectedEquipment">
                        {!!selectedEquipment.location && (
                          <Typography variant="body2" color="textSecondary">
                            {selectedEquipment.location}
                          </Typography>
                        )}
                        {!!selectedEquipment.name && (
                          <Typography variant="body2" color="textSecondary">
                            {selectedEquipment.name}
                          </Typography>
                        )}
                        {!!selectedEquipment.assetNumber && (
                          <Typography variant="body2" color="textSecondary">
                            {selectedEquipment.assetNumber}
                          </Typography>
                        )}
                        {!!selectedEquipment.address && (
                          <Typography variant="body2" color="textSecondary">
                            {addressToString(selectedEquipment.address)}
                          </Typography>
                        )}
                      </StyledDetails>
                    )}
                  </Grid>
                  {hasBarcodeScanner && (
                    <Grid item>
                      <IconButton
                        data-testid="ChangeJobEquipment-BarcodeButton"
                        onClick={() => setOpenScanner(true)}
                        size="large"
                        sx={{ pr: 0, ml: 1 }}
                      >
                        <CropFreeIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>

                <Grid item>
                  <PrimaryButton
                    key={`change-equipment-submit-button`}
                    fullWidth
                    variant="contained"
                    disabled={!selectedEquipment || loading || loadingEquipments}
                    onClick={checkVisitInProgress}
                    data-testid="ChangeJobEquipment-SaveButton"
                  >
                    <FormattedMessage id="general.save" />
                  </PrimaryButton>
                </Grid>
              </Grid>
              {hasBarcodeScanner && (
                <BarcodeScannerDialog
                  open={openScanner}
                  setOpen={(open: boolean) => setOpenScanner(open)}
                  submitValue={(value: string) => {
                    setScannerValue(value);
                    setEquipmentSearch(value);
                    setOpenScanner(false);
                    setEquipmentACOpen(true);
                  }}
                />
              )}
            </>
          )}
        </CardContent>
      </Card>
      <PromptDialog
        open={openVisitInProgressDialog}
        setOpen={setOpenVisitInProgressDialog}
        data-testid="ChangeJobEquipment-VisitInProgressPrompt"
        onOk={() => {
          onSave();
        }}
        promptContent={<FormattedMessage id="changeJobEquipment.inProgressVisitReset" />}
      />
    </Dialog>
  );
};
