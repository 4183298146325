import { Close as CloseIcon } from "@mui/icons-material";
import { AppBar, Dialog, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactMarkdown from "react-markdown";

import { ConsentObject, getUserpilotConsent, isAbortError } from "helpers";

import { useAppDispatch, useAppSelector } from "store";
import { setOpen as setDialogOpen } from "store/slices/dialog.store";
import { updateUserConsent as dUpdateUserConsent } from "store/slices/user.store";

import { UserConsentType, UserConsentTypeEnum } from "operations/schema/schema";

import { unwrapResult } from "@reduxjs/toolkit";
import { AsolviSwitch } from "components/AsolviSwitch";
import { StyledContainer } from "components/StyledContainer";
import Transition from "components/Transition";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { Userpilot } from "userpilot";

const PREFIX = "UserTrackingSettingsDialog";

const classes = {
  menuButton: `${PREFIX}-menuButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
  },
}));

interface UserTrackingSettingsProps {}

export const UserTrackingSettingsDialog: FC<UserTrackingSettingsProps> = () => {
  const dispatch = useAppDispatch();
  const { userVar: userData, languageVar } = useAppSelector((state) => state.user);
  const { userTrackingSettings: open } = useAppSelector((state) => state.dialog);
  const setOpen = (open: boolean) => {
    dispatch(setDialogOpen({ dialogName: "userTrackingSettings", open }));
  };
  const intl = useIntl();
  const [userpilotConsentAccepted, setUserpilotConsentAccepted] = useState(
    userData?.userConsents?.filter((c) => c?.type === UserConsentTypeEnum.Userpilot)?.[0]
      ?.accepted ?? false
  );
  const [consentObject, setConsentObject] = useState<ConsentObject>();

  useEffect(() => {
    getUserpilotConsent(languageVar).then((c) => setConsentObject(c));
  }, [languageVar]);

  const updateUserConsent = useCallback(async () => {
    let consent: UserConsentType = {
      accepted: !userpilotConsentAccepted,
      version: consentObject!.version,
      type: UserConsentTypeEnum.Userpilot,
    };
    dispatch(dUpdateUserConsent({ consent }))
      .then(unwrapResult)
      .then(() => {
        setUserpilotConsentAccepted(consent.accepted);
        dispatch(addSnackbarMessage({ key: "AddUserConsent-userpilot-success" }));
        Userpilot.reload();
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "AddUserConsent-fail" }));
      });
  }, [consentObject, dispatch, userpilotConsentAccepted]);

  if (!consentObject) {
    return null;
  }
  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            aria-label={intl.formatMessage({ id: "general.close" })}
            className={classes.menuButton}
            onClick={() => setOpen(false)}
            data-testid="UserTrackingSettingsDialog-CloseButton"
            size="large"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Typography flexGrow={1}>
            <FormattedMessage id="menu.userTracking" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <StyledContainer>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Grid item>
            <Typography variant="body1">
              <FormattedMessage id="userpilotConsent.toggleText" />
            </Typography>
          </Grid>
          <Grid item>
            <AsolviSwitch
              key="trackingConsent"
              checked={userpilotConsentAccepted}
              onClick={() => {
                updateUserConsent();
              }}
              data-testid="UserTrackingSettingsDialog-Toggle"
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <Typography component="div" whiteSpace="pre-line">
            <ReactMarkdown className="markdown-text" children={consentObject.text} />
          </Typography>
        </Grid>
      </StyledContainer>
    </StyledDialog>
  );
};
