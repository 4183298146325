import { Add as AddIcon } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { GraphQLError } from "graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { isAbortError } from "helpers";
import {
  Endpoint,
  GetUserEndpointsQueryVariables,
  PreLoginMutationVariables,
  UserEndpointResult,
} from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { getUserEndpoints, preLogin, setAuth0 } from "store/slices/user.store";

import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import { appEnvironment } from "env";

type LoginFormProps = {
  classes: any;
  email: string | undefined;
  preLoginLoading: boolean;
  setPreLoginLoading: (state: boolean) => void;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  url: string;
};

export const PreLoginForm: FC<LoginFormProps> = ({
  classes,
  email,
  preLoginLoading,
  setPreLoginLoading,
  setEmail,
  url,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { authVar } = useAppSelector((s) => s.user);
  const navigate = useNavigate();
  const appEnv = appEnvironment();
  const [loginInitiated, setLoginInitiated] = useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [userEndpoints, setUserEndpoints] = useState<Endpoint[]>([]);
  const [engineerMultipleEndpoints, setEngineerMultipleEndpoints] = useState(false);
  const [useMultipleEndpoints, setUseMultipleEndpoints] = useState(false);
  const [endpoint, setEndpoint] = useState<Endpoint>();
  const [newEndpointUrl, setNewEndpointUrl] = useState("");
  const [addEndpointOpen, setAddEndpointOpen] = useState(false);
  const [userEndpointsLoading, setUserEndpointsLoading] = useState(false);
  const [userEndpointsResponse, setUserEndpointsResponse] = useState<UserEndpointResult>();
  const [userEndpointsError, setUserEndpointsError] = useState<string | GraphQLError[]>();

  const addNewEndpoint = () => {
    if (!newEndpointUrl) return;
    let newEndpoint: Endpoint = {
      apiUrl: parseEndpointUrl(newEndpointUrl),
      apiVersion: "",
      id: "",
    };
    if (!userEndpoints.map((e) => e.apiUrl).includes(newEndpoint.apiUrl)) {
      setUserEndpoints([...userEndpoints, newEndpoint]);
    }
    setEndpoint(newEndpoint);
    setAddEndpointOpen(false);
  };
  const parseEndpointUrl = (url: string): string => {
    if (!url) return url;
    url = url.includes("https://") ? url : `https://${url}`;
    url = url.includes("/api") ? url : `${url}/api`;
    return url;
  };

  const preLoginCallback = useCallback(
    async (variables: PreLoginMutationVariables) => {
      await dispatch(preLogin(variables))
        .then(unwrapResult)
        .then((preLogin) => {
          if (preLogin && preLogin.domain && preLogin.clientId) {
            localStorage.setItem("auth0Props", JSON.stringify(preLogin));
            dispatch(setAuth0(preLogin));
            navigate("/login/auth0login");
          }
          setPreLoginLoading(false);
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          setPreLoginLoading(false);
        });
    },
    [dispatch, navigate, setPreLoginLoading]
  );

  const getUserEndpointsCallback = useCallback(
    async (variables: GetUserEndpointsQueryVariables) => {
      setUserEndpointsLoading(true);
      await dispatch(getUserEndpoints(variables))
        .then(unwrapResult)
        .then(({ userEndpoints }) => {
          setUserEndpointsResponse(userEndpoints);
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          setUserEndpointsError(e);
        });
      setUserEndpointsLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    if (userEndpointsLoading) return;
    if (!!userEndpointsResponse && !userEndpointsError) {
      let { email, useMultipleEndpoints, lastEndpoint, userEndpoints } = userEndpointsResponse;
      setEngineerMultipleEndpoints(!!useMultipleEndpoints);
      // If useMultiple, set endpoints
      if (useMultipleEndpoints || !lastEndpoint) {
        setUserEndpoints(userEndpoints || []);
        setLoginInitiated(true);
        if (lastEndpoint) {
          setEndpoint(lastEndpoint);
        } else {
          setFirstTimeLogin(true);
          setAddEndpointOpen(true);
        }
      } else {
        // If not use multiple, login with last used
        let { apiUrl } = lastEndpoint;
        setPreLoginLoading(true);
        preLoginCallback({
          email: email || "",
          url: apiUrl,
        });
      }
    }
  }, [
    preLoginCallback,
    setPreLoginLoading,
    userEndpointsError,
    userEndpointsLoading,
    userEndpointsResponse,
  ]);

  useEffect(() => {
    setUseMultipleEndpoints(engineerMultipleEndpoints || appEnv !== "prod");
  }, [appEnv, engineerMultipleEndpoints]);

  const loginErrorMessage = (message: string) => {
    switch (message) {
      case "Auth0 failed":
        return intl.formatMessage({ id: "login.auth0.fail" });
      case "Your session has expired, please log in again":
        return intl.formatMessage({ id: "login.sessionExpired" });
      default:
        return message;
    }
  };

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        if (loginInitiated) {
          if (!endpoint && newEndpointUrl) addNewEndpoint();
          let { apiUrl } =
            endpoint || {
              apiUrl: parseEndpointUrl(newEndpointUrl),
            } ||
            {};
          let preLoginInput = {
            email: email || "",
            url: apiUrl,
          };
          setPreLoginLoading(true);
          preLoginCallback(preLoginInput);
        } else {
          if (!email) return;
          getUserEndpointsCallback({ email });
        }
      }}
      noValidate
    >
      <StyledTextField
        required
        id="email"
        label={intl.formatMessage({ id: "general.email" })}
        name="email"
        autoComplete="email"
        autoFocus
        value={email || ""} // ` || ""` added after email to make it a "controlled" value
        onChange={(event) => setEmail(event.target.value)}
        inputProps={{
          "data-testid": "emailInput",
        }}
        style={{ margin: "16px 0px" }}
      />
      {loginInitiated && !firstTimeLogin && (
        <FormControl fullWidth className={classes.select}>
          <InputLabel id="endpointSelectLabel">
            <FormattedMessage id="login.endpoint" />
          </InputLabel>
          <Select
            labelId="endpointSelectLabel"
            value={endpoint?.apiUrl || ""}
            label={intl.formatMessage({ id: "login.endpoint" })}
            onChange={(e) => {
              let val = e.target.value;
              if (val === "null") setAddEndpointOpen(true);
              else setAddEndpointOpen(false);
              setEndpoint(userEndpoints.find((e) => e.apiUrl === val));
            }}
          >
            {userEndpoints.map((e, i) => {
              return (
                <MenuItem key={e.id || i} value={e.apiUrl} sx={{ cursor: "pointer" }}>
                  <ListItemText
                    primary={useMultipleEndpoints ? e.apiUrl : ""}
                    secondary={`${e.apiVersion ? `${e.apiVersion}` : ""}`}
                  />
                </MenuItem>
              );
            })}
            <MenuItem key="addNew" value="null" sx={{ cursor: "pointer" }}>
              <ListItemIcon>
                <AddIcon color="secondary" sx={{ fontSize: "1rem" }} />
              </ListItemIcon>
              <ListItemText primary={intl.formatMessage({ id: "menu.endpoints.addNew" })} />
            </MenuItem>
          </Select>
        </FormControl>
      )}
      {addEndpointOpen && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <StyledTextField
            sx={{ mt: 1 }}
            label={intl.formatMessage({ id: "login.mobileEndpointLink" })}
            value={newEndpointUrl}
            onChange={({ target: { value } }) => setNewEndpointUrl(value)}
          />
        </Box>
      )}
      {authVar.message && (
        <Alert severity="error" data-testid="loginResult">
          <AlertTitle>
            <FormattedMessage id="login.error" />
          </AlertTitle>
          {loginErrorMessage(authVar.message)}
        </Alert>
      )}
      {Array.isArray(userEndpointsError) &&
        userEndpointsError.length &&
        userEndpointsError[0].message === "Failed to fetch" && ( // TODO; JeezusFuckingChrist
          <Alert severity="error" data-testid="loginResult">
            <AlertTitle>
              <FormattedMessage id="login.error" />
            </AlertTitle>
            <FormattedMessage id="login.failedToFetchEndpoints" />
            <br />
            <FormattedMessage id="login.tryAgainLater" />
          </Alert>
        )}
      <div>
        <PrimaryButton
          key={`${preLoginLoading}`}
          type="submit"
          disabled={preLoginLoading}
          className={classes.submit}
          data-testid="loginButton"
        >
          <FormattedMessage id="login.signIn" />
        </PrimaryButton>
        {preLoginLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </form>
  );
};
