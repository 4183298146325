import { Add as AddIcon, PlaylistAdd as PlaylistAddIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useDebounce } from "hooks/useDebounce";
import { StockStore } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";
import {
  getAvailableParts,
  getRequestableParts,
  getSuggestedParts,
} from "store/slices/cache.store";
import { selectSelectedJob } from "store/slices/jobs.store";

import FullScreenDialog from "components/FullScreenDialog";
import { StyledContainer } from "components/StyledContainer";
import { StyledFab } from "components/StyledFab";
import { intlFormatPrice } from "helpers";
import engineerSettings from "helpers/engineerSettings";
import { StockLocationType } from "models/StockLocationType";
import { TabProps } from "../TabProps";
import { AddPart } from "./AddPart";
import { AddPartEnum } from "./AddPartEnum";
import { UsedPartComponent } from "./UsedPart";

interface PartTabProps extends TabProps {
  parts: any[];
  dialogType: AddPartEnum;
  hasPreOrderedParts?: boolean;
  setOpenPreOrderedPartsDialog?: (open: boolean) => void;
  hideAddButton?: boolean;
  setTabIndex: Dispatch<SetStateAction<string>>;
}

export const PartTab: FC<PartTabProps> = (props) => {
  const {
    parts,
    dialogType,
    hideAddButton,
    hasPreOrderedParts,
    setOpenPreOrderedPartsDialog,
    setTabIndex,
    setFab,
  } = props;
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(selectConnectionStatus);
  const { userVar: userData } = useAppSelector((state) => state.user);
  const job = useAppSelector(selectSelectedJob);
  const { availableParts, loading: cacheLoading } = useAppSelector((state) => state.cache);
  const intl = useIntl();
  const [openAddPartDialog, setOpenAddPartDialog] = useState(false);
  const [showSuggestedParts, setShowSuggestedParts] = useState(false);
  const [partSearch, setPartSearch] = useState<string>("");
  const debouncedPartSearch = useDebounce<string>(partSearch, 500);
  const allowSalesPrice = engineerSettings.syncCostPrice || engineerSettings.syncCostPrice;

  const equipmentId = job?.equipment?.id ?? "";

  const stockCheckboxes = [
    {
      locationId: userData?.stockId!,
      type: StockStore.Engineer,
      label: intl.formatMessage({ id: "general.engineer" }),
      disabled: false,
    },
  ];

  stockCheckboxes.push({
    locationId: "",
    type: StockStore.Locational,
    label: intl.formatMessage({ id: "part.other" }),
    disabled: !isOnline && availableParts.locationalParts.length === 0,
  });

  const [stockLocation, setStockLocation] = useState<StockLocationType>(stockCheckboxes[0]);

  useEffect(() => {
    dispatch(
      getAvailableParts({
        location: {
          locationId: stockLocation.locationId,
          stockStore: stockLocation.type,
        },
        searchString: stockLocation.type === StockStore.Locational ? debouncedPartSearch : "",
      })
    );
  }, [debouncedPartSearch, dispatch, stockLocation.locationId, stockLocation.type]);

  const dispatchRequestableParts = useCallback(() => {
    dispatch(
      getRequestableParts({
        location: { locationId: stockLocation.locationId, stockStore: stockLocation.type },
        force: true,
      })
    );
  }, [dispatch, stockLocation.locationId, stockLocation.type]);

  useEffect(() => {
    if (!isOnline) return;
    dispatchRequestableParts();
  }, [dispatchRequestableParts, isOnline]);

  useEffect(() => {
    if (!isOnline) return;
    dispatch(getSuggestedParts({ equipmentId }));
  }, [dispatch, equipmentId, isOnline]);

  useEffect(() => {
    if (hasPreOrderedParts && setOpenPreOrderedPartsDialog) {
      setOpenPreOrderedPartsDialog(true);
    }
  }, [hasPreOrderedParts, setOpenPreOrderedPartsDialog]);

  const [countEditItems, setCount] = useState<number>(0);

  const setCountEditItems = (amount: number) => {
    setCount(countEditItems + amount);
  };

  useEffect(() => {
    const fab = !hideAddButton ? (
      <StyledFab
        disabled={countEditItems > 0}
        color="primary"
        aria-label={intl.formatMessage({ id: "general.add" })}
        onClick={() => {
          if (hasPreOrderedParts && setOpenPreOrderedPartsDialog) {
            setOpenPreOrderedPartsDialog(true);
            setOpenAddPartDialog(true);
          }

          setOpenAddPartDialog(true);
        }}
        data-testid="PartTabAddButton"
      >
        <AddIcon />
      </StyledFab>
    ) : undefined;
    if (setFab) {
      setFab(fab);
    }
  }, [
    countEditItems,
    hasPreOrderedParts,
    hideAddButton,
    intl,
    setFab,
    setOpenPreOrderedPartsDialog,
  ]);

  const partListItems = parts.map((part) => {
    return (
      part.part && (
        <UsedPartComponent
          maxQuantity={part.maxQuantity}
          part={part.part}
          usedPart={part}
          key={`${part.part.id}-${part.part.stockId}-${part.part.stockStore}`}
          setCountEditItems={setCountEditItems}
          dialogType={dialogType}
          setTabIndex={setTabIndex}
        />
      )
    );
  });
  const totalSalesPrice = parts.reduce(
    (sum, part) => (part.part ? sum + (part.part.salesPrice ?? 0) * part.part.quantity : sum),
    0
  );

  const noPartsLabel =
    dialogType === AddPartEnum.AddPart ? (
      <FormattedMessage id="part.noPartsUsed" />
    ) : (
      <FormattedMessage id="part.noPartsRequested" />
    );

  return (
    <StyledContainer>
      {!partListItems ||
        (partListItems?.length === 0 && (
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <PlaylistAddIcon color="secondary" fontSize="large" />
            </Grid>
            <Grid item>
              <Typography color="secondary">{noPartsLabel}</Typography>
            </Grid>
          </Grid>
        ))}
      {allowSalesPrice && partListItems && partListItems?.length > 0 && (
        <Grid container mb={2}>
          <strong>
            <FormattedMessage id="visit.totalPrice" />
          </strong>
          : {intlFormatPrice(intl, totalSalesPrice)}
        </Grid>
      )}
      <Grid container spacing={1}>
        {partListItems && partListItems?.length > 0 ? partListItems : null}
      </Grid>
      <FullScreenDialog
        child={
          <AddPart
            setOpenAddPartDialog={setOpenAddPartDialog}
            partSearch={partSearch}
            setPartSearch={setPartSearch}
            loading={cacheLoading.availableParts || cacheLoading.requestableParts}
            stockCheckboxes={stockCheckboxes}
            stockLocation={stockLocation}
            setStockLocation={setStockLocation}
            dialogType={dialogType}
            showSuggestedParts={showSuggestedParts}
            setShowSuggestedParts={setShowSuggestedParts}
          />
        }
        title={`${job?.externalId} / ${intl.formatMessage({ id: "job.parts" })}`}
        centerTitle
        goingBack
        open={openAddPartDialog}
        setOpen={(open: boolean) => setOpenAddPartDialog(open)}
      />
    </StyledContainer>
  );
};
