import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import {
  Container,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { unwrapResult } from "@reduxjs/toolkit";
import { PromptDialog } from "components/PromptDialog";
import { GraphQLError } from "graphql";
import { isAbortError } from "helpers";
import { AssignTaskMutationVariables, EngineerType, Maybe } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { assignTask } from "store/slices/planner.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

export const EngineerAssign = () => {
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);
  const { engineers } = useAppSelector((s) => s.cache);
  const navigate = useNavigate();
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [engineer, setEngineer] = useState<EngineerType>();
  const isCurrentlyAssigned = (id: Maybe<string> | undefined) => id === job.engineerId;

  const assignTaskCb = useCallback(
    ({ jobId, engineerId }: AssignTaskMutationVariables) => {
      dispatch(
        assignTask({
          jobId,
          engineerId,
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(addSnackbarMessage({ key: "AssignTask-success" }));
          navigate("/planner", { state: { openFilter: false } });
        })
        .catch((error: GraphQLError[] | Error) => {
          if (isAbortError(error)) return;
          if (Array.isArray(error) && error.some((e) => e.message.includes("403 Forbidden"))) {
            dispatch(addSnackbarMessage({ key: "AssignTask-forbidden" }));
          } else {
            dispatch(addSnackbarMessage({ key: "AssignTask-fail" }));
          }
        });
    },
    [dispatch, navigate]
  );

  return (
    <Container data-testid="EngineerAssignDialog">
      {engineers
        .slice()
        .sort((x, y) => (isCurrentlyAssigned(x.id) ? -1 : isCurrentlyAssigned(y.id) ? 1 : 0))
        .map((x, index, { length }) => (
          <div key={x.id}>
            <ListItem
              disablePadding
              onClick={() => {
                setOpenAssignDialog(true);
                setEngineer(x);
              }}
              disableGutters
              data-testid={`EngineerAssign-ButtonAssign-${x.id}`}
              secondaryAction={
                <IconButton sx={{ padding: 0 }}>
                  <Typography
                    color={isCurrentlyAssigned(x.id) ? "" : "info.main"}
                    display="inline-flex"
                  >
                    {isCurrentlyAssigned(x.id) ? (
                      <FormattedMessage id={"general.assigned"} />
                    ) : (
                      <FormattedMessage id={"general.assign"} />
                    )}
                    {!isCurrentlyAssigned(x.id) && <AddIcon sx={{ ml: 1 }} />}
                  </Typography>
                </IconButton>
              }
            >
              <ListItemButton disableGutters>
                <ListItemText primary={x.name} sx={{ maxWidth: "80%" }} />
              </ListItemButton>
            </ListItem>
            {index + 1 !== length && <Divider sx={{ mt: 0.5 }} />}
          </div>
        ))}
      <PromptDialog
        open={openAssignDialog}
        setOpen={setOpenAssignDialog}
        onOk={() => {
          assignTaskCb({
            jobId: job.id,
            engineerId: engineer?.id!,
          });
        }}
        promptContent={
          <>
            <Typography mb={2} align="center" variant="subtitle1">
              <FormattedMessage
                id="planner.assignToEngineerPrompt"
                values={{ engineerName: engineer?.name }}
              />
            </Typography>
          </>
        }
      />
    </Container>
  );
};
