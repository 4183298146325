import { gql } from "graphql-request";
import { mergeDateTimeString } from "helpers";
import engineerSettings from "helpers/engineerSettings";
import { Job } from "models/Job";
import { JobVisitType } from "models/JobVisitType";
import {
  MeterReadingInputType,
  TaskStatus,
  UpdateVisitWithPartsMutationVariables,
} from "operations/schema/schema";
import { FileInputType, PartType, StockStore } from "../schema/schema";

export const UPDATE_VISIT_WITH_PARTS = gql`
  mutation updateVisitWithParts($metadata: MetadataInputType, $updateVisit: UpdateVisitInputType!) {
    updateVisitWithParts(metadata: $metadata, updateVisit: $updateVisit) {
      id
      externalId
    }
  }
`;

export const UPDATE_VISITS_WITH_PARTS = gql`
  mutation updateVisitsWithParts(
    $metadata: MetadataInputType
    $updateVisits: [UpdateVisitInputType!]!
  ) {
    updateVisitsWithParts(metadata: $metadata, updateVisits: $updateVisits) {
      id
      externalId
    }
  }
`;

export const createUpdateVisitVars = (
  jobId: any,
  storeId: any,
  {
    workTimes,
    workTimesInvoiceType,
    actionId1,
    actionId2,
    actionId3,
    solutionDescription,
    skipSignature,
    customerSignerName,
    jobCategory,
    serviceReportEmail,
    followUp,
    usedParts,
    extras,
    files,
    signatureCustomer,
    signatureEngineer,
    checklists,
    reference,
    customerSatisfaction,
  }: JobVisitType,
  taskStatus: TaskStatus
): UpdateVisitWithPartsMutationVariables => {
  let mappedFiles: Array<FileInputType> = [];

  if (files !== undefined && files?.length > 0) {
    mappedFiles = files.map((file) => {
      return {
        data: file.data ?? "",
        description: file.description ?? "",
        name: file.name ?? "",
      };
    });
  }
  const [partsAdded, partsRequested] = usedParts
    .map((p) => p.part)
    .reduce(
      ([added, requested], p) => {
        return p.stockStore !== StockStore.Other
          ? [[...added, p], requested]
          : [added, [...requested, p]];
      },
      [[], []] as Array<PartType[]>
    );

  return {
    updateVisit: {
      jobId: jobId,
      jobVisit: {
        workTimes: workTimes.map((time) => ({
          startTime: mergeDateTimeString(time.startDate, time.startTime),
          stopTime: mergeDateTimeString(time.stopDate!, time.stopTime!),
          invoiceType: workTimesInvoiceType,
        })),
        actionId1: actionId1,
        actionId2: actionId2,
        actionId3: actionId3,
        solutionDescription: solutionDescription,
        customerSignerName: skipSignature ? null : customerSignerName,
        customerSignatureImageData: signatureCustomer,
        engineerSignatureImageData: signatureEngineer,
        categoryId: jobCategory?.id,
        typeId: jobCategory?.typeId,
        checklists: checklists.map((cl) => cl.checklist),
        serviceReportEmail: serviceReportEmail,
        reference: reference,
        customerSatisfaction: customerSatisfaction,
        followUp: {
          followUpChecked: followUp.followUpChecked,
        },
        parts: [
          ...partsAdded.map((p) => {
            return {
              id: p.id || "",
              partNumber: p.partNumber || "",
              quantity: p.quantity,
              stockId: `${p.stockId}`,
              stockStore: p.stockStore || StockStore.Other,
              isNonStock: p.isNonStock || undefined,
              status: p.status || undefined,
              returnStockId: p.returnStockId || undefined,
              salesPrice: p.salesPrice || undefined,
              salesPriceChangedOnClient: p.salesPriceChangedOnClient,
              description: p.description,
              invoiceType: p.invoiceType,
            };
          }),
          ...partsRequested.map((p) => {
            return {
              id: p.id || "",
              partNumber: p.partNumber || "",
              quantity: p.quantity,
              stockId: `${p.stockId}`,
              stockStore: p.stockStore || StockStore.Other,
              isNonStock: p.isNonStock || undefined,
              status: p.status,
              requestStockId: storeId, //  || undefined,
              description: p.description,
              invoiceType: p.invoiceType,
            };
          }),
        ],
        extras: extras,
        files: mappedFiles,
      },
      taskStatus,
    },
  };
};

export const meterReadingsNotRequiredOrFilled = (
  cachedReadings: MeterReadingInputType[],
  job: Job
) => {
  return (
    !engineerSettings.requireMeterReading ||
    !job.meters.length ||
    (engineerSettings.requireMeterReading && cachedReadings.length > 0)
  );
};
