import { Container, LinearProgress } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { setAppBar } from "store/slices/menu.store";

import { FilterAppBar } from "components/FilterAppBar";
import { JobList } from "components/job/JobList";
import { JobListSkeleton } from "components/job/JobListSkeleton";
import { useEffectOnce } from "helpers/useEffectOnce";
import { HistoryFilter, HistoryFilterStorageKey, defaultHistoryFilter } from "models/HistoryFilter";
import { HistoryFilterDialog } from "pages/history/historyDetails/HistoryFilterDialog";
import { shallowEqual } from "react-redux";
import { setPage, setSelectedJob } from "store/root.store";
import {
  getEngineerHistory,
  selectHistoryFilter,
  selectHistoryFilterCount,
  selectJobs,
  selectLoadingJobs,
  setHistoryFilter,
  setHistorySelectedJob,
} from "store/slices/history.store";
import { setSelectedJob as setVisitSelectedJob } from "store/slices/jobs.store";
import { clearPlannerState, setPlannerSelectedJob } from "store/slices/planner.store";

const sortingType = "history";

export const HistoryPage: FC = () => {
  const dispatch = useAppDispatch();
  const engineerHistory = useAppSelector(selectJobs, shallowEqual);
  const loadingJobs = useAppSelector(selectLoadingJobs);
  const filter = useAppSelector(selectHistoryFilter);
  const filterCount = useAppSelector(selectHistoryFilterCount);
  const intl = useIntl();
  const { state }: any = useLocation();
  const openFilter: boolean = state?.openFilter ?? false;
  const [filterDialogOpen, setFilterDialogOpen] = useState(openFilter);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffectOnce(() => {
    const historyFilterString = localStorage.getItem(HistoryFilterStorageKey);
    let storedHistoryFilter: HistoryFilter | null = null;
    if (historyFilterString) {
      storedHistoryFilter = JSON.parse(historyFilterString) as HistoryFilter;
      if (storedHistoryFilter.specificDate)
        storedHistoryFilter.specificDate = new Date(storedHistoryFilter.specificDate);
    }
    if (storedHistoryFilter != null) {
      dispatch(setHistoryFilter({ historyFilter: storedHistoryFilter }));
    } else {
      dispatch(setHistoryFilter({ historyFilter: defaultHistoryFilter }));
    }
  });

  useEffect(() => {
    dispatch(
      setAppBar({
        title: intl.formatMessage({ id: "history.jobHistory" }),
        sortingType,
        refetchEnabled: true,
      })
    );

    dispatch(setPage({ page: "history" }));
    dispatch(setSelectedJob({ jobId: undefined }));
    dispatch(setVisitSelectedJob({ jobId: undefined }));
    dispatch(setPlannerSelectedJob({ jobId: undefined }));
    dispatch(setHistorySelectedJob({ jobId: undefined }));
    dispatch(clearPlannerState());
  }, [dispatch, intl]);

  const dispatchGetEngineerHistory = useCallback(() => {
    dispatch(getEngineerHistory());
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem(HistoryFilterStorageKey, JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    if (initialLoad && !openFilter) {
      dispatchGetEngineerHistory();
      setInitialLoad(false);
    }
  }, [dispatchGetEngineerHistory, initialLoad, openFilter]);

  return loadingJobs && engineerHistory === undefined ? (
    <>
      {loadingJobs && <LinearProgress />}
      <JobListSkeleton />
    </>
  ) : (
    <Container data-testid="EngineerHistoryPage-Container">
      <FilterAppBar
        onClick={setFilterDialogOpen}
        filterCount={filterCount}
        foundCount={engineerHistory.length}
        type="jobs"
      />
      <JobList
        jobs={engineerHistory || []}
        loading={loadingJobs}
        refetchJobs={dispatchGetEngineerHistory}
        toLink="/history"
        sortingType={sortingType}
      />
      <HistoryFilterDialog
        open={filterDialogOpen}
        setOpen={setFilterDialogOpen}
        onClose={(dirty) => {
          if (dirty || initialLoad) {
            setInitialLoad(false);
            dispatchGetEngineerHistory();
          }
        }}
      />
    </Container>
  );
};
