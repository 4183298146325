import { AssignmentTurnedIn as AssignmentTurnedInIcon } from "@mui/icons-material";
import { PromptDialog } from "components/PromptDialog";
import StyledFab from "components/StyledFab";
import { StockStore } from "operations/schema/schema";
import { FC, memo, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectSelectedJob,
  selectSelectedJobVisit,
  selectVisitCanComplete,
  setJobTab,
} from "store/slices/jobs.store";

interface CompleteVisitButtonProps {
  onCompleteVisit: () => null | undefined;
}

type Checks = "requestedPartChecked";

export const CompleteVisitButton: FC<CompleteVisitButtonProps> = memo((props) => {
  const { onCompleteVisit } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { usedParts } = useAppSelector(selectSelectedJobVisit);
  const visitCanComplete = useAppSelector(selectVisitCanComplete);
  const requestedPartChecked = "requestedPartChecked";
  const checkedPrompts = useRef<Checks[]>([]);
  const [openUnusedReqPartsDialog, setOpenUnusedReqPartsDialog] = useState(false);
  const [hasUnusedRequestedParts, setHasUnusedRequestedParts] = useState(false);

  useEffect(() => {
    let parts = usedParts.filter((p) => p && p.part?.stockStore !== StockStore.Other);
    var allUsed = job.preOrderedParts.every((pop) =>
      parts.some((up) => up?.part?.id === pop?.id && up?.part?.partNumber === pop?.partNumber)
    );
    setHasUnusedRequestedParts(!allUsed);
  }, [job?.preOrderedParts, usedParts]);

  const check = () => {
    if (hasUnusedRequestedParts && !checkedPrompts.current.includes(requestedPartChecked)) {
      setOpenUnusedReqPartsDialog(true);
    } else {
      setOpenUnusedReqPartsDialog(false);
      onCompleteVisit();
    }
  };

  return (
    <>
      <StyledFab data-testid="CompleteVisitButton" disabled={!visitCanComplete} onClick={check}>
        <AssignmentTurnedInIcon />
      </StyledFab>
      <PromptDialog
        open={openUnusedReqPartsDialog}
        setOpen={setOpenUnusedReqPartsDialog}
        cancelText={intl.formatMessage({ id: "general.continue" })}
        okText={intl.formatMessage({ id: "part.viewParts" })}
        onCancel={() => {
          checkedPrompts.current.push(requestedPartChecked);
          check();
        }}
        onOk={() => {
          dispatch(setJobTab({ tab: "parts" }));
        }}
        promptContent={<FormattedMessage id="part.requestedPartsExist" />}
      />
    </>
  );
});
