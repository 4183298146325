import { isValid } from "date-fns";
import * as yup from "yup";

import { getIntl } from "context/SettingsProvider";
import { DefinedDate, mergeDateTime } from "helpers";
import engineerSettings from "helpers/engineerSettings";

export const VisitValidationSchema = (autoEndTime: boolean, followUpChecked: boolean) => {
  const intl = getIntl();
  return yup.object({
    travelTimes: yup
      .array()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "times.startDateRequired" }),
        test: function (value) {
          return engineerSettings.requireTravelTime ||
            (followUpChecked && engineerSettings.requireTravelTimeReturn)
            ? !!value && value.length > 0
            : true;
        },
      })
      .of(
        yup.object().shape({
          startDate: yup
            .string()
            .test({
              name: "required",
              message: intl.formatMessage({ id: "times.startDateRequired" }),
              test: function (value) {
                return engineerSettings.requireTravelTime ||
                  (followUpChecked && engineerSettings.requireTravelTimeReturn)
                  ? !!value && value.length > 0
                  : true;
              },
            })
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.date" }),
              test: function (value) {
                if (!value) {
                  return false;
                }
                return isValid(new Date(value));
              },
            }),
          startTime: yup
            .string()
            .test({
              name: "required",
              message: intl.formatMessage({ id: "times.startTimeRequired" }),
              test: function (value) {
                return engineerSettings.requireTravelTime ||
                  (followUpChecked && engineerSettings.requireTravelTimeReturn)
                  ? !!value && value.length > 0
                  : true;
              },
            })
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.time" }),
              test: function (value) {
                return !!value ? isValid(new Date(value)) : true;
              },
            })
            .test({
              name: "max",
              message: intl.formatMessage({
                id: "times.startTimeCannotBeFuture",
              }),
              test: function (value) {
                if (!value) {
                  return true;
                }
                let currentDateTime = new Date();
                const dateTimeStart = mergeDateTime(this.parent.startDate, value);
                return currentDateTime > dateTimeStart;
              },
            }),
          stopDate: yup
            .string()
            .nullable()
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.date" }),
              test: function (value) {
                return !!value ? isValid(new Date(value)) : true;
              },
            })
            .when("startDate", {
              is: (startDate: string) => {
                return isValid(new Date(startDate));
              },
              then: yup
                .string()
                .nullable()
                .test({
                  name: "min",
                  message: intl.formatMessage({
                    id: "visit.endDateAfterStartDate",
                  }),
                  test: function (value) {
                    if (!value || !isValid(new Date(value))) {
                      return true;
                    }
                    // Make copies when mutating the dates or we might accidentally set the times to midnight.
                    const stopDateCopy = new Date(value!);
                    stopDateCopy.setHours(0, 0, 0, 0);
                    const startDateCopy = new Date(this.parent.startDate);
                    startDateCopy.setHours(0, 0, 0, 0);
                    return stopDateCopy >= startDateCopy;
                  },
                }),
            }),
          stopTime: yup
            .string()
            .nullable()
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.time" }),
              test: function (value) {
                return !!value ? isValid(new Date(value)) : true;
              },
            })
            .when(["startTime", "startDate", "stopDate"], {
              is: (startTime: DefinedDate, startDate: DefinedDate, stopDate: DefinedDate) => {
                return (
                  isValid(new Date(startTime)) &&
                  isValid(new Date(startDate)) &&
                  isValid(new Date(stopDate))
                );
              },
              then: yup
                .string()
                .test({
                  name: "required",
                  message: intl.formatMessage({ id: "visit.endTimeRequired" }),
                  test: function (value) {
                    return !!value;
                  },
                })
                .test({
                  name: "type",
                  message: intl.formatMessage({ id: "validation.time" }),
                  test: function (value) {
                    return !!value ? isValid(new Date(value)) : true;
                  },
                })
                .test({
                  name: "min",
                  message: intl.formatMessage({
                    id: "visit.endTimeAfterStartTime",
                  }),
                  test: function (value) {
                    if (!value) {
                      return true;
                    }
                    const dateTimeStart = mergeDateTime(
                      this.parent.startDate,
                      this.parent.startTime
                    );
                    const dateTimeEnd = mergeDateTime(this.parent.stopDate, value);
                    return dateTimeEnd > dateTimeStart;
                  },
                }),
            }),
        })
      ),
    workTimes: yup
      .array()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "times.startDateRequired" }),
        test: function (value) {
          return engineerSettings.requireWorkTime ||
            (followUpChecked && engineerSettings.requireWorkTimeReturn)
            ? !!value && value.length > 0
            : true;
        },
      })
      .of(
        yup.object().shape({
          startDate: yup
            .string()
            .test({
              name: "required",
              message: intl.formatMessage({ id: "times.startDateRequired" }),
              test: function (value) {
                return engineerSettings.requireWorkTime ||
                  (followUpChecked && engineerSettings.requireWorkTimeReturn)
                  ? !!value && value.length > 0
                  : true;
              },
            })
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.date" }),
              test: function (value) {
                return !!value ? isValid(new Date(value)) : true;
              },
            }),
          startTime: yup
            .string()
            .test({
              name: "required",
              message: intl.formatMessage({ id: "times.startTimeRequired" }),
              test: function (value) {
                return engineerSettings.requireWorkTime ||
                  (followUpChecked && engineerSettings.requireWorkTimeReturn)
                  ? !!value && value.length > 0
                  : true;
              },
            })
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.time" }),
              test: function (value) {
                return !!value ? isValid(new Date(value)) : true;
              },
            })
            .test({
              name: "max",
              message: intl.formatMessage({
                id: "times.startTimeCannotBeFuture",
              }),
              test: function (value) {
                if (!value) {
                  return true;
                }
                let currentDateTime = new Date();
                const dateTimeStart = mergeDateTime(this.parent.startDate, value);
                return currentDateTime > dateTimeStart;
              },
            }),
          stopDate: yup.string().when("startDate", {
            is: (startDate: string) => {
              return isValid(new Date(startDate));
            },
            then: yup
              .string()
              .test({
                name: "required",
                message: intl.formatMessage({ id: "visit.endDateRequired" }),
                test: function (value) {
                  return autoEndTime || !!value;
                },
              })
              .test({
                name: "type",
                message: intl.formatMessage({ id: "validation.date" }),
                test: function (value) {
                  if (!value || autoEndTime) return true;
                  return isValid(new Date(value));
                },
              })
              .test({
                name: "min",
                message: intl.formatMessage({
                  id: "visit.endDateAfterStartDate",
                }),
                test: function (value) {
                  if (autoEndTime || !value || !isValid(new Date(value))) {
                    return true;
                  }
                  // Make copies when mutating the dates or we might accidentally set the times to midnight.
                  const stopDateCopy = new Date(value!);
                  stopDateCopy.setHours(0, 0, 0, 0);
                  const startDateCopy = new Date(this.parent.startDate);
                  startDateCopy.setHours(0, 0, 0, 0);
                  return stopDateCopy >= startDateCopy;
                },
              }),
          }),
          stopTime: yup.string().when(["startTime", "startDate", "stopDate"], {
            is: (startTime: DefinedDate, startDate: DefinedDate, stopDate: DefinedDate) => {
              return (
                isValid(new Date(startTime)) &&
                isValid(new Date(startDate)) &&
                isValid(new Date(stopDate))
              );
            },
            then: yup
              .string()
              .test({
                name: "required",
                message: intl.formatMessage({ id: "visit.endTimeRequired" }),
                test: function (value) {
                  return autoEndTime || !!value;
                },
              })
              .test({
                name: "type",
                message: intl.formatMessage({ id: "validation.time" }),
                test: function (value) {
                  if (!value || autoEndTime) return true;
                  return isValid(new Date(value));
                },
              })
              .test({
                name: "min",
                message: intl.formatMessage({
                  id: "visit.endTimeAfterStartTime",
                }),
                test: function (value) {
                  if (autoEndTime || !value) {
                    return true;
                  }

                  const dateTimeStart = mergeDateTime(this.parent.startDate, this.parent.startTime);

                  const dateTimeEnd = mergeDateTime(this.parent.stopDate, value);

                  return dateTimeEnd > dateTimeStart;
                },
              }),
          }),
        })
      ),
    actionId1: yup.string().test({
      name: "required",
      message: intl.formatMessage({ id: "visit.actionRequired" }),
      test: function (value) {
        return engineerSettings.requireActionCode1 ? !!value : true;
      },
    }),
    actionId2: yup
      .string()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.actionRequired" }),
        test: function (value) {
          return engineerSettings.requireActionCode2 ? !!value : true;
        },
      }),
    actionId3: yup
      .string()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.actionRequired" }),
        test: function (value) {
          return engineerSettings.requireActionCode2 ? !!value : true;
        },
      }),
    solutionDescription: yup.string().test({
      name: "required",
      message: intl.formatMessage({ id: "visit.solutionDescriptionRequired" }),
      test: function (value) {
        return engineerSettings.requireActionText ||
          (followUpChecked && engineerSettings.requireActionTextReturn)
          ? !!value
          : true;
      },
    }),
  });
};
