import { ContactMail, ContactPhone, LocationOn, PermContactCalendar } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { EditContactDialog } from "components/job/details/EditContactDialog";
import { PromptDialog } from "components/PromptDialog";
import { chooseContact, isAbortError } from "helpers";
import { addressToGoogleString, addressToString } from "helpers/addressToString";
import { AddressType, CustomerContactType, JobStatus, Maybe } from "operations/schema/schema";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import {
  acceptJob,
  addTravel,
  refreshJob,
  selectSelectedJob,
  selectSelectedJobVisit,
  setEditContactOpen,
  setJobTab,
  startVisitTravel,
  updateVisitTravel,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

interface ContactsProps {
  customerAddress: AddressType;
  contacts: Maybe<CustomerContactType>[];
  preferredContact?: Maybe<CustomerContactType>;
}

export const Contacts: FC<ContactsProps> = (props) => {
  const { contacts, preferredContact, customerAddress } = props;
  const firstContact = chooseContact(preferredContact, contacts);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { travelTimes } = useAppSelector(selectSelectedJobVisit);

  const addressString = addressToString(customerAddress);
  const googleString = addressToGoogleString(customerAddress);
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(googleString)}`;

  const [openPrompt, setOpenPrompt] = useState(false);

  const openMap = () => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  const onStartTravel = useCallback(async () => {
    await dispatch(addTravel());
    if (travelTimes.length === 0) {
      if (job.status !== JobStatus.Accepted) {
        dispatch(
          acceptJob({
            jobId: job.id,
          })
        )
          .then(unwrapResult)
          .then(() => {
            dispatch(refreshJob());
          });
      }
      await dispatch(startVisitTravel({ jobId: job.id }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdateTravel-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "StartTravel-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "StartTravel-fail" }));
        });
    } else {
      await dispatch(updateVisitTravel({ jobId: job.id }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdateTravel-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "UpdateTravel-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "UpdateTravel-fail" }));
        });
    }
  }, [dispatch, job.id, job.status, travelTimes.length]);

  const address = (
    <ListItem
      component="a"
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(googleString)}`}
      onClick={(e) => {
        e.preventDefault();
        if (
          travelTimes.length === 0 ||
          (travelTimes[travelTimes.length - 1].stopDate &&
            travelTimes[travelTimes.length - 1].stopTime)
        ) {
          setOpenPrompt(true);
        } else {
          openMap();
        }
      }}
      disableGutters
      target="_blank"
      rel="noopener noreferrer"
    >
      <ListItemIcon className="min-width-38">
        <LocationOn />
      </ListItemIcon>
      <ListItemText primary={addressString} />
    </ListItem>
  );

  const firstContactDetails = (address: JSX.Element, firstContact: CustomerContactType) => {
    return (
      <>
        {firstContact?.name && (
          <ListItem disableGutters>
            <ListItemIcon className="min-width-38">
              <PermContactCalendar />
            </ListItemIcon>
            <ListItemText primary={firstContact.name} />
          </ListItem>
        )}
        {address}
        {firstContact?.phoneNumber && (
          <ListItem component="a" href={`tel:${firstContact.phoneNumber}`} disableGutters>
            <ListItemIcon className="min-width-38">
              <ContactPhone />
            </ListItemIcon>
            <ListItemText primary={firstContact.phoneNumber} />
          </ListItem>
        )}
        {firstContact?.mobileNumber && (
          <ListItem component="a" href={`tel:${firstContact.mobileNumber}`} disableGutters>
            <ListItemIcon className="min-width-38">
              <ContactPhone />
            </ListItemIcon>
            <ListItemText primary={firstContact.mobileNumber} />
          </ListItem>
        )}
        {firstContact?.email && (
          <ListItem component="a" href={`mailto:${firstContact.email}`} disableGutters>
            <ListItemIcon className="min-width-38">
              <ContactMail />
            </ListItemIcon>
            <ListItemText primary={firstContact.email} />
          </ListItem>
        )}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.2rem",
          }}
        >
          <FormattedMessage id="contact" />
        </Typography>

        <IconButton
          aria-label={intl.formatMessage({ id: "visit.addEditContact" })}
          onClick={() =>
            dispatch(
              setEditContactOpen({
                open: true,
              })
            )
          }
          data-testid="Contacts-EditButton"
          sx={{
            fontSize: "1.2rem",
            paddingY: 0,
          }}
        >
          <EditIcon color="primary" />
        </IconButton>
      </div>
      <List dense>
        {firstContact ? (
          firstContactDetails(address, firstContact)
        ) : (
          <>
            {address}
            <br />
            <Typography>{intl.formatMessage({ id: "contact.noCustomerContacts" })}</Typography>
          </>
        )}
      </List>
      <EditContactDialog />
      <PromptDialog
        open={openPrompt}
        setOpen={setOpenPrompt}
        okText={intl.formatMessage({ id: "contacts.prompt.addTravelTime" })}
        onOk={() => {
          onStartTravel();
          dispatch(setJobTab({ tab: "times" }));
          openMap();
        }}
        cancelText={intl.formatMessage({ id: "contacts.prompt.notNow" })}
        onCancel={openMap}
        promptContent={
          <>
            <Typography mb={2} align="center" variant="subtitle1">
              <FormattedMessage id="contacts.prompt.startTravel" />
            </Typography>
          </>
        }
      />
    </>
  );
};
