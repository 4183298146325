import * as yup from "yup";

import { getIntl } from "context/SettingsProvider";
import engineerSettings from "helpers/engineerSettings";

export const EngineerSignoffValidationSchema = (
  hasCategories: boolean,
  followUpChecked: boolean
) => {
  const intl = getIntl();
  return yup.object({
    solutionDescription: yup.string(),
    skipSignature: yup.boolean(),
    customerSignerName: yup.string().test({
      name: "required",
      message: intl.formatMessage({ id: "visit.customerSignerNameRequired" }),
      test: function (value) {
        return engineerSettings.requireCustomerSignatureName ? !!value : true;
      },
    }),
    // customerSignature does not get saved until form submit
    // so it is not validated here on purpose
    signatureEngineer: yup.string().test({
      name: "required",
      message: intl.formatMessage({ id: "visit.engineerSignatureRequired" }),
      test: function (value) {
        return engineerSettings.requireEngineerSignature ? !!value : true;
      },
    }),
    changeServiceTerms: yup.boolean(),
    serviceLevel: yup
      .object()
      .nullable()
      .when("changeServiceTerms", {
        is: true,
        then: yup
          .object()
          .typeError(intl.formatMessage({ id: "visit.signoff.serviceTermRequired" }))
          .required(intl.formatMessage({ id: "visit.signoff.serviceTermRequired" })),
      }),
    jobCategory: yup
      .object()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.categoryRequired" }),
        test: function (value) {
          return hasCategories && followUpChecked ? !!value?.id : true;
        },
      }),
    reference: yup
      .string()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.reference" }),
        test: function (value) {
          return engineerSettings.requireReference ? !!value : true;
        },
      }),
  });
};
