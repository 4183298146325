import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";

import { ReactComponent as TaskMarker } from "./TaskMarker.svg";
import { ReactComponent as TechnicianMarker } from "./TechnicianMarker.svg";

export function TaskMarkerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <TaskMarker />
    </SvgIcon>
  );
}
export function TechnicianMarkerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <TechnicianMarker />
    </SvgIcon>
  );
}
