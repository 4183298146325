import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete, FormHelperText, Grid } from "@mui/material";
import StyledTextField from "components/StyledTextField";
import engineerSettings from "helpers/engineerSettings";
import { JobCategoryType, JobSymptomType } from "operations/schema/schema";

export interface SymptomAutocompleteProps {
  symptoms: JobSymptomType[];
  category?: JobCategoryType | null;
  errors?: {
    symptomCode1?: string;
    symptomCode2?: string;
    symptomCode3?: string;
  };
  symptomCode1?: string;
  onChange: (
    field: "symptomCode1" | "symptomCode2" | "symptomCode3",
    value: any,
    shouldValidate?: boolean
  ) => void;
}

export const SymptomAutocomplete: FC<SymptomAutocompleteProps> = (props) => {
  const { symptoms, category, errors, onChange, symptomCode1 = null } = props;
  const intl = useIntl();
  const [symptom1, setSymptom1] = useState<JobSymptomType | null>(
    symptoms.find((x) => x.code === symptomCode1) ?? null
  );
  const [symptom2, setSymptom2] = useState<JobSymptomType | null>(null);
  const [symptom3, setSymptom3] = useState<JobSymptomType | null>(null);
  const [categorySymptoms, setCategorySymptoms] = useState<JobSymptomType[]>(
    (category?.id
      ? symptoms.filter((s) => s.typeId == null || s.typeId === category?.typeId)
      : symptoms) ?? []
  );
  const [symptoms1, setSymptoms1] = useState<JobSymptomType[]>(
    categorySymptoms.filter((s) => s.sortId === "1")
  );
  const [symptoms2, setSymptoms2] = useState<JobSymptomType[]>([]);
  const [symptoms3, setSymptoms3] = useState<JobSymptomType[]>([]);

  useEffect(() => {
    if (category?.id) {
      setCategorySymptoms(
        symptoms.filter((s) => s.typeId == null || s.typeId === category?.typeId)
      );
      setSymptoms1(
        symptoms.filter(
          (s) => s.sortId === "1" && (s.typeId == null || s.typeId === category?.typeId)
        )
      );
    } else {
      setCategorySymptoms(symptoms);
      setSymptoms1(symptoms.filter((s) => s.sortId === "1"));
    }
  }, [category, symptoms, setSymptoms1]);

  useEffect(() => {
    setSymptom1(symptoms1.find((x) => x.code === symptomCode1) ?? null);
  }, [symptomCode1, symptoms1, setSymptom1]);

  return (
    <>
      <Grid item>
        <Autocomplete
          id="symptomCode1"
          options={symptoms1}
          data-testid="symptoms1"
          getOptionLabel={(s: JobSymptomType) => `${s.description}`}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              name="symptomCode1"
              className="symptomCode1"
              error={!!errors?.symptomCode1}
              label={intl.formatMessage({ id: "job.symptomDescription" })}
              required={engineerSettings.requireSymptomCode1}
            />
          )}
          onChange={(_, value: JobSymptomType | null) => {
            setSymptom1(value);
            setSymptom2(null);
            setSymptom3(null);
            onChange("symptomCode1", value?.code, true);

            const filtered = categorySymptoms.filter((a) =>
              value?.subSymptomCodes?.includes(a.code as string)
            );

            setSymptoms2(
              !!filtered?.length ? filtered : categorySymptoms.filter((a) => a.sortId === "2")
            );
          }}
          value={symptom1}
          isOptionEqualToValue={(option, value) => option.code === value?.code}
          fullWidth
        />
        <FormHelperText error>{errors?.symptomCode1}</FormHelperText>
      </Grid>
      {symptom1 && !!symptoms2.length && (
        <Grid item>
          <Autocomplete
            options={symptoms2}
            data-testid="symptoms2"
            getOptionLabel={(s: JobSymptomType) => `${s.description}`}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                name="symptomCode2"
                className="symptomCode2"
                error={!!errors?.symptomCode2}
                required={engineerSettings.requireSymptomCode2}
                label={`${intl.formatMessage({ id: "job.symptomDescription" })} 2`}
              />
            )}
            onChange={(_, value: JobSymptomType | null) => {
              setSymptom2(value);
              setSymptom3(null);
              onChange("symptomCode2", value?.code, false);

              const filtered = categorySymptoms.filter((a) =>
                value?.subSymptomCodes?.includes(a.code as string)
              );

              setSymptoms3(
                !!filtered?.length ? filtered : categorySymptoms.filter((a) => a.sortId === "3")
              );
            }}
            fullWidth
            value={symptom2}
          />
          <FormHelperText error>{errors?.symptomCode2}</FormHelperText>
        </Grid>
      )}
      {symptom2 && !!symptoms3.length && (
        <Grid item>
          <Autocomplete
            options={symptoms3}
            data-testid="symptoms3"
            getOptionLabel={(s: JobSymptomType) => `${s.description}`}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                name="symptomCode3"
                className="symptomCode3"
                error={!!errors?.symptomCode3}
                required={engineerSettings.requireSymptomCode3}
                label={`${intl.formatMessage({ id: "job.symptomDescription" })} 3`}
              />
            )}
            onChange={(_, value: JobSymptomType | null) => {
              setSymptom3(value);
              onChange("symptomCode3", value?.code, false);
            }}
            fullWidth
            value={symptom3}
          />
          <FormHelperText error>{errors?.symptomCode3}</FormHelperText>
        </Grid>
      )}
    </>
  );
};
