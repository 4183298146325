import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";

import { unwrapResult } from "@reduxjs/toolkit";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import { StyledContainer } from "components/StyledContainer";
import StyledTextField from "components/StyledTextField";
import Transition from "components/Transition";
import { isAbortError } from "helpers";
import { selectPageSelectedJob } from "store/root.store";
import {
  addWorkNote,
  handleClose,
  selectNoteDialog,
  setText,
} from "store/slices/dialogs/note.store";
import { getNotes } from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

export const NoteDialog: FC = () => {
  const dispatch = useAppDispatch();
  const { open, loading, isAlert, text } = useAppSelector(selectNoteDialog);
  const job = useAppSelector(selectPageSelectedJob);

  const intl = useIntl();

  const addWorkNoteCb = useCallback(() => {
    if (!text) return;
    dispatch(addWorkNote({ jobId: job.id, text: text, isAlert }))
      .then(unwrapResult)
      .then(({ queued }) => {
        if (queued) {
          dispatch(addSnackbarMessage({ key: "AddWorkNote-stored" }));
        } else {
          dispatch(addSnackbarMessage({ key: "AddWorkNote-success" }));
          dispatch(getNotes());
        }
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "AddWorkNote-fail" }));
      });
  }, [dispatch, isAlert, job.id, text]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => dispatch(handleClose())}
      TransitionComponent={Transition}
      data-testid="NoteDialog"
    >
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => dispatch(handleClose())}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="NoteDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            {`${job?.externalId} `} / <FormattedMessage id="job.notes" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <StyledTextField
              value={text}
              label={intl.formatMessage({ id: "note" })}
              onChange={(e) => dispatch(setText({ text: e.target.value }))}
              multiline
              rows={15}
              data-testid="NoteDialog-Text"
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              key={`${!text || loading}`}
              disabled={!text || loading}
              fullWidth
              onClick={() => {
                addWorkNoteCb();
              }}
              data-testid="NoteDialog-SaveButton"
            >
              <FormattedMessage id="general.save" />
            </PrimaryButton>
          </Grid>
        </Grid>
      </StyledContainer>
    </Dialog>
  );
};
