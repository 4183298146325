import { Done as DoneIcon } from "@mui/icons-material";
import { CircularProgress, Fade, FormHelperText, Grid, Typography } from "@mui/material";
import { FC, MutableRefObject } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSignatureCanvas from "react-signature-canvas";

import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJobVisit, setVisitValue } from "store/slices/jobs.store";

import { AsolviSwitch } from "components/AsolviSwitch";
import BackdropWhite from "components/BackdropWhite";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import engineerSettings from "helpers/engineerSettings";
import { EngineerSignoff } from "./EngineerSignoff";
import { JobVisitSummary } from "./JobVisitSummary";

interface SignoffDialogProps {
  handleSignature: (added: boolean) => void;
  disableNextButton: boolean;
  canvasRef: MutableRefObject<ReactSignatureCanvas | null>;
  loading: boolean;
  openCustomerReview: () => void;
}

export const EngineerSignoffComponent: FC<SignoffDialogProps> = (props: SignoffDialogProps) => {
  const { handleSignature, canvasRef, disableNextButton, loading, openCustomerReview } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { solutionDescription, followUp } = useAppSelector(selectSelectedJobVisit);
  const requireEngineerSignature = engineerSettings.requireEngineerSignature ?? false;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <JobVisitSummary isEngineerSummaryPage={true} />
      </Grid>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">
            <FormattedMessage id="visit.followUp.required" />
          </Typography>
        </Grid>
        <Grid item>
          <AsolviSwitch
            key={`FollowUpSwitch`}
            checked={followUp.followUpChecked}
            onChange={() =>
              dispatch(
                setVisitValue({
                  key: "followUp",
                  value: { ...followUp, followUpChecked: !followUp.followUpChecked },
                })
              )
            }
            data-testid="EngineerSignoffDialog-FollowUpButton"
          />
        </Grid>
      </Grid>
      {followUp.followUpChecked && (
        <Fade in={followUp.followUpChecked}>
          <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item width="100%" marginTop="16px">
              <StyledTextField
                id="reason"
                name="reason"
                label={intl.formatMessage({ id: "visit.followUp.reason" })}
                value={solutionDescription}
                onChange={({ target: { value } }) => {
                  dispatch(setVisitValue({ key: "solutionDescription", value }));
                }}
                multiline
                rows={10}
              />
              {solutionDescription.length === 0 &&
                engineerSettings?.requiredSolutionDescription && (
                  <FormHelperText error>
                    {intl.formatMessage({
                      id: "visit.followUp.reasonRequired",
                    })}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
        </Fade>
      )}

      {requireEngineerSignature && (
        <Grid item sx={{ width: "100%" }}>
          <EngineerSignoff canvasReference={canvasRef} handleSignature={handleSignature} />
        </Grid>
      )}
      <Grid item pb={1.5}>
        <BackdropWhite open={loading}>
          <CircularProgress color="inherit" />
        </BackdropWhite>

        <PrimaryButton
          key={`to-customer-summary-button`}
          startIcon={<DoneIcon />}
          variant="contained"
          onClick={openCustomerReview}
          fullWidth
          disabled={disableNextButton}
        >
          <FormattedMessage id="general.next" />
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};
