import { getIntl } from "context/SettingsProvider";
import engineerSettings from "helpers/engineerSettings";
import * as yup from "yup";

export const CreateJobValidationSchema = (hasCategories: boolean) => {
  const intl = getIntl();

  return yup.object({
    customer: yup
      .object()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "createJob.customerRequired" }),
        test: (value) => {
          return !!value?.id;
        },
      }),
    symptomCode1: yup
      .string()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.symptomRequired" }),
        test: (value) => {
          return engineerSettings.requireSymptomCode1 ? !!value : true;
        },
      }),
    symptomCode2: yup
      .string()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.symptomRequired" }),
        test: (value) => {
          return engineerSettings.requireSymptomCode2 ? !!value : true;
        },
      }),
    symptomCode3: yup
      .string()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.symptomRequired" }),
        test: (value) => {
          return engineerSettings.requireSymptomCode3 ? !!value : true;
        },
      }),
    category: yup
      .object()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.categoryRequired" }),
        test: function (value) {
          return hasCategories ? !!value?.id : true;
        },
      }),
    description: yup.string().test({
      name: "required",
      message: intl.formatMessage({ id: "createJob.descriptionRequired" }),
      test: (value) => {
        return engineerSettings.requireSymtomText ? !!value : true;
      },
    }),
    equipment: yup.object().nullable(),
  });
};
