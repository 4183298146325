import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { unwrapResult } from "@reduxjs/toolkit";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import Transition from "components/Transition";
import { isAbortError } from "helpers";
import { PropertyValueInputType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { setMachinePropertiesOpen, updateMachineCustomProps } from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

export const EquipmentPropsDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);
  const { open, loading } = useAppSelector((s) => s.jobs.machineProperties);
  const [properties, setProperties] = useState(job.equipment?.details?.customProperties ?? []);

  const handleClose = useCallback(() => {
    dispatch(setMachinePropertiesOpen({ open: false }));
  }, [dispatch]);

  const updateMachineProps = useCallback(
    async (val: PropertyValueInputType[]) => {
      dispatch(updateMachineCustomProps({ jobId: job.id, properties: val }))
        .then(unwrapResult)
        .then(({ queued }) => {
          handleClose();
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdateEquipmentCustomProps-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "UpdateEquipmentCustomProps-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "UpdateEquipmentCustomProps-fail" }));
        });
    },
    [dispatch, handleClose, job.id]
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-testid="EquipmentPropsDialog"
    >
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="EquipmentPropsDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            <FormattedMessage id="visit.editMachineProperties" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={1}>
            {properties.map((cp, index) => (
              <Grid
                key={`${cp?.code}-${index}`}
                item
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>{cp?.description}</Grid>
                <Grid item xs={5}>
                  <StyledTextField
                    value={cp?.value}
                    size="small"
                    onChange={(e) =>
                      setProperties(
                        properties.map((p, i) =>
                          i === index ? { ...p, value: e.target.value } : p
                        )
                      )
                    }
                    data-testid={`EquipmentPropsDialog-Input-${cp.code}`}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <PrimaryButton
                key={`${loading}`}
                disabled={loading}
                fullWidth
                onClick={() => {
                  updateMachineProps(
                    properties.filter((p) => p.propertyGuid || p.value) as PropertyValueInputType[]
                  );
                }}
                data-testid="EquipmentPropsDialog-SaveButton"
              >
                <FormattedMessage id="general.save" />
              </PrimaryButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};
