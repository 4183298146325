import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FC, useCallback, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

import { unwrapResult } from "@reduxjs/toolkit";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import Transition from "components/Transition";
import { isAbortError } from "helpers";
import { EquipmentInputType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { handleCloseUpdateEquipment, updateEquipment } from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

export const EquipmentEditDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { id, externalId, equipment } = useAppSelector(selectPageSelectedJob);
  const { open, loading } = useAppSelector((s) => s.jobs.updateEquipment);

  const [newLocation, setNewLocation] = useState("");
  const machineInstallDate = equipment?.installDate;

  const updateEquipmentCb = useCallback(
    async (equipment: EquipmentInputType) => {
      dispatch(updateEquipment({ jobId: id, equipment }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdateEquipment-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "UpdateEquipment-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "UpdateEquipment-fail" }));
        });
    },
    [dispatch, id]
  );

  const isValidW3W = () => {
    if (newLocation.startsWith("///")) {
      const tempNewLocation = newLocation.replace("///", "");
      const tempNewLocationArray = tempNewLocation.split(".");
      const words = tempNewLocationArray[0] && tempNewLocationArray[1] && tempNewLocationArray[2];

      if (!/\s/.test(tempNewLocation) && tempNewLocationArray.length === 3 && words) {
        return true;
      }

      return false;
    }

    return true;
  };

  const handleClose = () => {
    dispatch(handleCloseUpdateEquipment());
  };
  if (!equipment) {
    handleClose();
    return null;
  }
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-testid="EquipmentEditDialog"
    >
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="EquipmentEditDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            {`${externalId} `} / <FormattedMessage id="visit.editMachineLocation" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Card>
        <CardHeader
          sx={{
            fontSize: "1.2rem",
          }}
          subheader={
            <>
              <Typography fontWeight={700}>{equipment.name}</Typography>
              <Typography>{equipment.serialNumber}</Typography>
              {equipment.assetNumber && <Typography>{equipment.assetNumber}</Typography>}
              {equipment.location && <Typography>{equipment.location}</Typography>}
              {machineInstallDate && (
                <Typography>
                  {`${intl.formatMessage({ id: "general.installed" })} `}
                  <FormattedDate
                    value={machineInstallDate}
                    day="numeric"
                    month="long"
                    year="numeric"
                  />
                </Typography>
              )}
            </>
          }
        />
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <StyledTextField
                value={newLocation}
                label={intl.formatMessage({ id: "equipmentEdit.newLocation" })}
                onChange={(e) => {
                  setNewLocation(e.target.value);
                }}
                error={!isValidW3W()}
                helperText={
                  isValidW3W()
                    ? ""
                    : intl.formatMessage({
                        id: "equipmentEdit.invalidWhat3Words",
                      })
                }
                data-testid="EditEquipment-TextField"
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                key={`${!newLocation || loading}`}
                disabled={!newLocation || loading}
                fullWidth
                data-testid="EditEquipment-SaveButton"
                onClick={() => {
                  updateEquipmentCb({
                    id: equipment.id || "",
                    serialNumber: equipment.serialNumber,
                    modelGuid: equipment.modelGuid,
                    addressRowNo: equipment.addressRowNo,
                    location: newLocation,
                  });
                }}
              >
                <FormattedMessage id="general.save" />
              </PrimaryButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};
