import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { StyledChip } from "components/StyledChip";
import { Maybe } from "operations/schema/schema";

interface StyledChipsProps {
  isOnStop?: boolean;
  priority?: Maybe<string>;
  category?: Maybe<string>;
  partsStatusName?: Maybe<string>;
  symptoms?: Maybe<Maybe<string>[]>;
}

export const StyledChips: FC<StyledChipsProps> = (props) => {
  const { isOnStop, priority, category, partsStatusName, symptoms } = props;

  return (
    <>
      {isOnStop && (
        <div style={{ display: "flex", marginBottom: 15, alignItems: "center" }}>
          <ErrorRoundedIcon color="error" sx={{ mr: 0.5 }} />
          <FormattedMessage id="general.onStop" />
        </div>
      )}
      {priority && <StyledChip key="priority-chip" label={priority} />}
      {category && <StyledChip key="category-chip" label={category} />}
      {partsStatusName && <StyledChip key="parts-status-name-chip" label={partsStatusName} />}
      {!!symptoms?.length &&
        symptoms.map((x) => <StyledChip key={`symptoms-chip-${x}`} label={x} />)}
    </>
  );
};
