import { NoMatchPage } from "components/NoMatchPage";
import HistoryRouter from "pages/history";
import JobsRouter from "pages/jobs";
import { JobsPage } from "pages/jobs/JobsPage"; // "index" page to avoid issues with router
import LoginRouter from "pages/login";
import PlannerRouter from "pages/planner";
import { PrivateRoute } from "pages/PrivateRoute";
import StockRouter from "pages/stock";
import { FC } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { UserpilotHandler } from "./UserpilotHandler";

const AppRouter: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="login/*" element={<LoginRouter />} />
        <Route path="history/*" element={<PrivateRoute component={HistoryRouter} />} />
        <Route path="jobs/*" element={<PrivateRoute component={JobsRouter} />} />
        <Route path="planner/*" element={<PrivateRoute component={PlannerRouter} />} />
        <Route path="stock/*" element={<PrivateRoute component={StockRouter} />} />
        <Route path="" element={<PrivateRoute component={JobsPage} />} />
        <Route path="*" element={<NoMatchPage />} />
      </Routes>
      <UserpilotHandler />
    </Router>
  );
};

export default AppRouter;
