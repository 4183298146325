import {
  Add as AddIcon,
  AssignmentInd as AssignmentIndIcon,
  Close as CloseIcon,
  LockClock,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { intlFormatDate, intlFormatDateTimeWithWeekday } from "helpers";
import { ServiceJob } from "operations/schema/schema";
import { PlannerMapActions } from "pages/planner/plannerDetails/PlannerMapActions";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { ContactsMinimal } from "./details/ContactsMinimal";

const PREFIX = "JobMapView";

const classes = {
  bold: `${PREFIX}-bold`,
  noPadding: `${PREFIX}-no-padding`,
  leftIndent: `${PREFIX}-left-indent`,
  disabled: `${PREFIX}-disabled`,
};
const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },
  [`& .${classes.noPadding}`]: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  [`& .${classes.leftIndent}`]: {
    marginLeft: "15px",
  },
  [`& .${classes.disabled}`]: {
    color: theme.palette.secondary.main,
  },
}));

const StyledPaper = styled(Paper)({
  position: "fixed",
  bottom: 0,
  width: "100vw",
  height: "auto",
  maxHeight: "50vh",
  borderRadius: 0,
});

interface JobMapPopupProps {
  job: ServiceJob;
  plannerMode?: boolean;
  onClose: () => void;
}

export const JobMapPopup: FC<JobMapPopupProps> = (props: JobMapPopupProps) => {
  const { job, plannerMode, onClose } = props;
  const intl = useIntl();
  const date = job.plannedDate?.startTime || job.responseDate;
  const fontSize = "0.8rem";
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <StyledPaper>
      <StyledCard elevation={1} data-testid="JobDetailTab">
        <CardHeader
          title={
            <>
              {date && (
                <Typography className={classes.bold}>
                  {!job.plannedDate?.startTime && (
                    <LockClock className="left-icon-adjust" sx={{ mr: 1.75, top: "0.2em" }} />
                  )}
                  {intlFormatDateTimeWithWeekday(intl, date)}
                </Typography>
              )}
              <Typography
                className={classes.leftIndent}
                variant="body2"
                sx={{ mb: 1, mt: 1, fontSize: fontSize }}
              >
                {job.customer?.name}
              </Typography>
            </>
          }
          action={
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                onClose();
              }}
              aria-label={intl.formatMessage({ id: "general.close" })}
              data-testid="IconButtonClose"
            >
              <CloseIcon />
            </IconButton>
          }
          subheader={
            <>
              <div className={classes.leftIndent}>
                <Typography variant="body2" sx={{ fontSize: fontSize }}>
                  {job.equipment?.serialNumber}
                </Typography>
                {job.equipment?.assetNumber && (
                  <Typography variant="body2" sx={{ fontSize: fontSize }}>
                    {job.equipment.assetNumber}
                  </Typography>
                )}
                {job.equipment?.location &&
                  (job.equipment.location.startsWith("///") ? (
                    <Link
                      href={`https://what3words.com/${job.equipment.location.replace("///", "")}`}
                      underline="none"
                      color="blue"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontSize: fontSize }}
                    >
                      {job.equipment.location}
                    </Link>
                  ) : (
                    <Typography variant="body2" sx={{ fontSize: fontSize }}>
                      {job.equipment.location}
                    </Typography>
                  ))}
                {job.equipment?.installDate && (
                  <Typography variant="body2" sx={{ fontSize: fontSize }}>
                    {`${intl.formatMessage({
                      id: "general.installed",
                    })} ${intlFormatDate(intl, job.equipment?.installDate)}`}
                  </Typography>
                )}
              </div>

              {plannerMode && (
                <ListItem
                  component="div"
                  disableGutters
                  className={classes.noPadding}
                  sx={{ mt: "5px" }}
                >
                  <ListItemIcon className="min-width-28" sx={{ fontSize: "1.2rem" }}>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    data-testid="JobDetailsTab-AssignedTo"
                    disableTypography
                    primary={
                      <>
                        <Typography variant="body2" sx={{ fontSize: fontSize }}>
                          {!!job.engineerName
                            ? `${intl.formatMessage({ id: "visit.assignedTo" })} ${
                                job.engineerName
                              }`
                            : intl.formatMessage({ id: "general.unassigned" })}

                          <IconButton
                            aria-label={intl.formatMessage({ id: "planner.reAssignJob" })}
                            onClick={() => {
                              setOpenDrawer(true);
                            }}
                            data-testid="JobMapPopup-AssignEngineerButton"
                            sx={{
                              fontSize: "1.2rem",
                              paddingY: 0,
                            }}
                          >
                            <AddIcon color="primary" />
                          </IconButton>
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              )}
            </>
          }
        />
        <CardContent style={{ paddingTop: 0 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ pt: "4px !important" }}>
              <ContactsMinimal
                customerAddress={job.customer?.address!}
                contacts={job.customer?.contacts || []}
                preferredContact={job.preferredContact}
              />
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
      {plannerMode && <PlannerMapActions openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />}
    </StyledPaper>
  );
};
