import { ContactMail, ContactPhone, LocationOn, PermContactCalendar } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { chooseContact } from "helpers";
import { addressToGoogleString, addressToString } from "helpers/addressToString";
import { AddressType, CustomerContactType, Maybe } from "operations/schema/schema";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface ContactsProps {
  customerAddress: AddressType;
  contacts: Maybe<CustomerContactType>[];
  preferredContact?: Maybe<CustomerContactType>;
}

export const ContactsReadOnly: FC<ContactsProps> = (props) => {
  const { contacts, preferredContact, customerAddress } = props;
  const firstContact = chooseContact(preferredContact, contacts);
  const intl = useIntl();

  const addressString = addressToString(customerAddress);
  const googleString = addressToGoogleString(customerAddress);
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(googleString)}`;

  const openMap = () => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  const address = (
    <ListItem
      component="a"
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(googleString)}`}
      onClick={(e) => {
        e.preventDefault();
        openMap();
      }}
      disableGutters
      target="_blank"
      rel="noopener noreferrer"
    >
      <ListItemIcon className="min-width-38">
        <LocationOn />
      </ListItemIcon>
      <ListItemText primary={addressString} />
    </ListItem>
  );

  const firstContactDetails = (address: JSX.Element, firstContact: CustomerContactType) => {
    return (
      <>
        {firstContact?.name && (
          <ListItem disableGutters>
            <ListItemIcon className="min-width-38">
              <PermContactCalendar />
            </ListItemIcon>
            <ListItemText primary={firstContact.name} />
          </ListItem>
        )}
        {address}
        {firstContact?.phoneNumber && (
          <ListItem component="a" href={`tel:${firstContact.phoneNumber}`} disableGutters>
            <ListItemIcon className="min-width-38">
              <ContactPhone />
            </ListItemIcon>
            <ListItemText primary={firstContact.phoneNumber} />
          </ListItem>
        )}
        {firstContact?.mobileNumber && (
          <ListItem component="a" href={`tel:${firstContact.mobileNumber}`} disableGutters>
            <ListItemIcon className="min-width-38">
              <ContactPhone />
            </ListItemIcon>
            <ListItemText primary={firstContact.mobileNumber} />
          </ListItem>
        )}
        {firstContact?.email && (
          <ListItem component="a" href={`mailto:${firstContact.email}`} disableGutters>
            <ListItemIcon className="min-width-38">
              <ContactMail />
            </ListItemIcon>
            <ListItemText primary={firstContact.email} />
          </ListItem>
        )}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.2rem",
          }}
        >
          <FormattedMessage id="contact" />
        </Typography>
      </div>
      <List dense>
        {firstContact ? (
          firstContactDetails(address, firstContact)
        ) : (
          <>
            {address}
            <br />
            <Typography>{intl.formatMessage({ id: "contact.noCustomerContacts" })}</Typography>
          </>
        )}
      </List>
    </>
  );
};
