import { Build as BuildIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { formatTimeDiff, isAbortError, isEmpty, mergeDateTime, toDateString } from "helpers";
import { Times } from "models/travelTimes";
import { useAppDispatch, useAppSelector } from "store";
import {
  acceptJob,
  refreshJob,
  selectSelectedJob,
  selectSelectedJobVisit,
  setAutoEndTime,
  setVisitValue,
  startVisitWork,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

import { AsolviSwitch } from "components/AsolviSwitch";
import { InvoiceAutocomplete } from "components/job/InvoiceAutocomplete";
import PrimaryButton from "components/PrimaryButton";
import { PromptDialog } from "components/PromptDialog";
import SecondaryGreyButton from "components/SecondaryGreyButton";
import engineerSettings from "helpers/engineerSettings";
import { JobStatus } from "operations/schema/schema";
import { isFlagEnabled } from "store/slices/user.store";
import TimeBlockComponent from "./TimeBlockComponent";
import { TotalTimeComponent } from "./TotalTimeComponent";

export const WorkTab: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { autoEndTime, workTimes, workTimesInvoiceType } = useAppSelector(selectSelectedJobVisit);
  const job = useAppSelector(selectSelectedJob);
  const [openDialog, setOpenDialog] = useState(false);
  const [index, setIndex] = useState(0);

  const hasUpdatedServiceJobEndpoint = useAppSelector((s) =>
    isFlagEnabled(s, "UpdatedServiceJobEndpoint")
  );
  const allowChangeInvoiceType =
    engineerSettings.allowChangeInvoiceType && hasUpdatedServiceJobEndpoint;

  const toggleAutoEndTime = () => {
    if (autoEndTime && !workTimes[workTimes.length - 1].stopTime) {
      stopWork();
    }
    dispatch(setAutoEndTime({ autoEndTime: !autoEndTime }));
  };

  const isLastOneEnded =
    !autoEndTime &&
    workTimes[workTimes.length - 1].stopDate !== null &&
    workTimes[workTimes.length - 1].stopTime !== null;

  const startWork = () => {
    if (isEmpty(workTimes) || !workTimes[0].startTime) {
      if (job.status !== JobStatus.Accepted) {
        dispatch(
          acceptJob({
            jobId: job.id,
          })
        )
          .then(unwrapResult)
          .then(() => {
            dispatch(refreshJob());
          });
      }
      dispatch(startVisitWork({ jobId: job.id }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "StartVisitWork-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "StartVisitWork-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "StartVisitWork-fail" }));
        });
    }
    const newWorkTime: Times = {
      startDate: toDateString(Date.now()),
      startTime: toDateString(Date.now()),
      invoiceType: workTimesInvoiceType ?? undefined,
    };
    dispatch(setVisitValue({ key: "workTimes", value: [...workTimes, newWorkTime] }));
  };

  const stopWork = () => {
    let times = cloneDeep(workTimes);

    let last = times.pop();
    if (last) {
      last.stopDate = toDateString(Date.now());
      last.stopTime = toDateString(Date.now());
      last.invoiceType = workTimesInvoiceType ?? undefined;
      dispatch(setVisitValue({ key: "workTimes", value: [...times, last] }));
    }
  };

  const deleteWorkTime = (index: number) => {
    const newArray = workTimes.filter((time) => time !== workTimes[index]);
    dispatch(setVisitValue({ key: "workTimes", value: [...newArray] }));
  };

  const timeBlockOnChange = (
    id: number,
    fieldname: "startDate" | "startTime" | "stopDate" | "stopTime",
    value: Date
  ) => {
    let newTimes = cloneDeep(workTimes);
    newTimes[id][fieldname] = toDateString(value);
    dispatch(setVisitValue({ key: "workTimes", value: [...newTimes] }));
  };

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, [autoEndTime]);

  return (
    <>
      <Grid container direction="column" spacing={1} width="100%" pl={1} pr={1}>
        {workTimes.map((times: Times, index: number) => {
          return (
            <Grid key={index} item>
              <TimeBlockComponent
                setOpenDeleteDialog={setOpenDialog}
                setIndex={setIndex}
                timeBlockOnChange={timeBlockOnChange}
                id={index}
                isDeleteDisabled={workTimes.length === 1}
                showLastOne={!(!isLastOneEnded && workTimes.length - 1 === index)}
                timesLabel="workTimes"
                timesText={intl.formatMessage({ id: "times.workTime" })}
                totalTime={
                  times.stopTime && times.stopDate
                    ? formatTimeDiff(
                        intl,
                        Math.floor(
                          (mergeDateTime(times.stopDate, times.stopTime).getTime() -
                            mergeDateTime(times.startDate, times.startTime).getTime()) /
                            1000 /
                            60
                        )
                      )
                    : ""
                }
              />
            </Grid>
          );
        })}
        {workTimes.length > 0 && (
          <>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              spacing={1}
              wrap="nowrap"
              sx={{
                mt: 1,
                ml: 0,
                pl: "0 !important",
              }}
            >
              <Grid item ml={1}>
                <Typography variant="body1" onClick={toggleAutoEndTime}>
                  <FormattedMessage id="times.setEndToCurrent" />
                </Typography>
              </Grid>
              <Grid item mr={1}>
                <AsolviSwitch checked={autoEndTime} onChange={toggleAutoEndTime} />
              </Grid>
            </Grid>
            <Grid item xs={12} ml={1}>
              <TotalTimeComponent times={workTimes} />
            </Grid>
          </>
        )}
        {isLastOneEnded || !workTimes[0]?.startTime ? (
          <PrimaryButton
            fullWidth
            startIcon={<BuildIcon />}
            onClick={() => {
              startWork();
              dispatch(setAutoEndTime({ autoEndTime: true }));
            }}
          >
            {intl.formatMessage({ id: "visit.startWork" })}
          </PrimaryButton>
        ) : (
          <>
            <SecondaryGreyButton
              fullWidth
              onClick={() => {
                deleteWorkTime(workTimes.length - 1);
                dispatch(setAutoEndTime({ autoEndTime: false }));
              }}
              disabled={workTimes.length <= 1}
            >
              {intl.formatMessage({ id: "general.cancel" })}
            </SecondaryGreyButton>
            <PrimaryButton
              startIcon={<BuildIcon />}
              onClick={() => {
                stopWork();
                dispatch(setAutoEndTime({ autoEndTime: false }));
              }}
            >
              {intl.formatMessage({ id: "visit.stopWork" })}
            </PrimaryButton>
          </>
        )}
        {allowChangeInvoiceType && (
          <Grid item container xs={12}>
            <InvoiceAutocomplete
              onChange={(value: string) => {
                dispatch(setVisitValue({ key: "workTimesInvoiceType", value: value }));
              }}
              value={workTimesInvoiceType ?? null}
            />
          </Grid>
        )}
      </Grid>
      <PromptDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onOk={() => {
          deleteWorkTime(index);
        }}
        promptContent={<FormattedMessage id="times.deletePrompt" />}
        title={intl.formatMessage({ id: "visit.deleteTimesPrompt" })}
        okText={intl.formatMessage({ id: "general.continue" })}
      />
    </>
  );
};
