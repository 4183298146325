import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { AppBar, Dialog, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { cloneDeep } from "lodash";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import PrimaryButton from "components/PrimaryButton";
import { StyledContainer } from "components/StyledContainer";
import StyledTextField from "components/StyledTextField";
import Transition from "components/Transition";
import { ChecklistQuestionType, ChecklistType, QuestionDataType } from "operations/schema/schema";

import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { updateChecklist } from "store/slices/jobs.store";

import {
  BooleanType,
  ConditionalGroupType,
  DateTimeType,
  DateType,
  DropDownType,
  ExpressionType,
  GroupType,
  ImageType,
  NumberType,
  SignatureType,
  StringType,
  TimeType,
} from "./types";

type ChecklistDialogProps = {
  open: boolean;
  handleClose: () => void;
  index: number;
  checklist: ChecklistType | null;
  readonly?: boolean;
};

const DataTypeComponents = {
  [QuestionDataType.Boolean]: BooleanType,
  [QuestionDataType.ConditionalGroup]: GroupType,
  [QuestionDataType.Date]: DateType,
  [QuestionDataType.DateTime]: DateTimeType,
  [QuestionDataType.Decimal]: NumberType,
  [QuestionDataType.DropDown]: DropDownType,
  [QuestionDataType.Expression]: ExpressionType,
  [QuestionDataType.Group]: ConditionalGroupType,
  [QuestionDataType.Image]: ImageType,
  [QuestionDataType.Int32]: NumberType,
  [QuestionDataType.Signature]: SignatureType,
  [QuestionDataType.String]: StringType,
  [QuestionDataType.TimeOfDay]: TimeType,
};

export const ChecklistDialog: FC<ChecklistDialogProps> = (props) => {
  const { open, handleClose, index, checklist, readonly } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { externalId } = useAppSelector(selectPageSelectedJob);
  const [checklistState, setChecklistState] = useState<ChecklistType | null>(null);

  useEffect(() => {
    if (checklist) {
      setChecklistState(cloneDeep(checklist));
    }
  }, [checklist]);

  const getQuestionAnswerType = (question: ChecklistQuestionType) => {
    const Component = DataTypeComponents[question.dataType];

    if (question.dataType === QuestionDataType.Expression) {
      if (!question.expression) return;
      return (
        <Component
          question={question}
          onChange={(q) => {
            handleExpressionChange(q);
          }}
          isInt={false}
          readonly={readonly}
          data-testid={`ChecklistDialog-Question-${question.nodeKey}`}
        />
      );
    }

    return (
      <Component
        question={question}
        onChange={(value) => {
          handleOnChange(question.nodeKey, value, "answer");
        }}
        isInt={question.dataType === QuestionDataType.Int32}
        readonly={readonly}
        data-testid={`ChecklistDialog-Question-${question.nodeKey}`}
      />
    );
  };

  const renderQuestion = (question: ChecklistQuestionType): JSX.Element => {
    const groupQuestions =
      checklistState?.questions.filter(
        (q) => q.parent === question.nodeKey && q.nodeKey !== question.nodeKey
      ) ?? [];

    if (question.dataType === QuestionDataType.ConditionalGroup) {
      return (
        <ConditionalGroupType
          key={question.nodeKey}
          question={question}
          onChange={(value) => {
            handleOnChange(question.nodeKey, value, "answer");
          }}
          readonly={readonly}
          data-testid={`ChecklistDialog-GroupType-${question.nodeKey}`}
        >
          {groupQuestions.map((q) => renderQuestion(q))}
        </ConditionalGroupType>
      );
    }

    if (question.dataType === QuestionDataType.Group) {
      return (
        <GroupType key={question.nodeKey} question={question}>
          {groupQuestions.map((q) => renderQuestion(q))}
        </GroupType>
      );
    }

    return (
      <Grid
        key={question.nodeKey}
        item
        container
        direction="column"
        width="100%"
        marginTop="5px"
        marginBottom="5px"
      >
        <Grid item>
          <Typography>{question.question}</Typography>
        </Grid>
        <Grid item width="100%">
          {getQuestionAnswerType(question)}
        </Grid>
        {question.showComment && (
          <Grid item mt={1} mb={1}>
            <StyledTextField
              value={question.comment}
              label={intl.formatMessage({ id: "general.comment" })}
              onChange={(event) => {
                handleOnChange(question.nodeKey, event.target.value, "comment");
              }}
              size="small"
              data-testid={`ChecklistDialog-Comment-${question.nodeKey}`}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const handleOnChange = (
    nodeKey: string | null | undefined,
    value: any,
    property: "comment" | "answer"
  ) => {
    const questionsTemp = [...(checklistState?.questions || [])];
    const question = questionsTemp.find((q) => q.nodeKey === nodeKey);
    if (question) {
      question[property] = value;
      setChecklistState(
        checklistState
          ? {
              ...checklistState,
              isTemplate: false,
              questions: questionsTemp,
            }
          : null
      );
    }
  };
  const handleExpressionChange = (question: ChecklistQuestionType) => {
    const questionsTemp = [...(checklistState?.questions || [])];
    const questionIndex = questionsTemp.findIndex((q) => q.nodeKey === question.nodeKey);
    if (questionIndex >= 0) {
      questionsTemp[questionIndex] = question;
      setChecklistState(
        checklistState
          ? {
              ...checklistState,
              isTemplate: false,
              questions: questionsTemp,
            }
          : null
      );
    }
  };

  const save = () => {
    if (checklistState) dispatch(updateChecklist({ checklist: checklistState, index }));
    handleClose();
  };

  const rootQuestions =
    checklistState?.questions.filter((q) => !q.parent || q.parent === q.nodeKey) ?? [];

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-testid="ChecklistDialog"
    >
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="ChecklistDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            {externalId} / {checklistState?.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        <Grid container direction="column" spacing={1}>
          {rootQuestions.map((q) => renderQuestion(q))}
          {!readonly && (
            <Grid item mb={1}>
              <PrimaryButton fullWidth onClick={save} data-testid="ChecklistDialogSaveButton">
                <FormattedMessage id="general.save" />
              </PrimaryButton>
            </Grid>
          )}
        </Grid>
      </StyledContainer>
    </Dialog>
  );
};
