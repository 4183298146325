import { CircularProgress } from "@mui/material";
import { FC, useEffect } from "react";

import { getAuth0, getIsAuth0, initializeAuth0 } from "context/auth0";

import { LoginContainer } from "components/LoginContainer";
import { getStore } from "store";
import { selectAuth0 } from "store/slices/user.store";

export const Auth0Login: FC = () => {
  useEffect(() => {
    initializeAuth0();
  }, []);

  useEffect(() => {
    const handle = async () => {
      const { email } = selectAuth0(getStore().getState());
      await getAuth0()?.loginWithRedirect({ login_hint: email });
    };
    if (getIsAuth0()) {
      handle();
    }
  }, []);

  return (
    <LoginContainer>
      <CircularProgress size={24} />
    </LoginContainer>
  );
};
