import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";

export interface State {
  locationSettings: boolean;
  userTrackingSettings: boolean;
  queue: boolean;
  signout: boolean;
}

interface Actions extends SliceCaseReducers<State> {
  setOpen: (
    state: State,
    action: PayloadAction<{
      dialogName: Extract<keyof State, string>;
      open: boolean;
    }>
  ) => State;
}

export const initialState: State = {
  locationSettings: false,
  userTrackingSettings: false,
  queue: false,
  signout: false,
};
const actions: Actions = {
  setOpen: (state, { payload: { dialogName, open } }) => {
    state = { ...initialState };
    state[dialogName] = open;
    return state;
  },
};

const dialogStore = createSlice<State, Actions>({
  name: "dialog",
  initialState,
  reducers: actions,
});

export default dialogStore.reducer;
export const { setOpen } = dialogStore.actions;
