import {
  ArrowBack as ArrowBackIcon,
  Construction as ConstructionIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  CardContent,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import PrimaryButton from "components/PrimaryButton";
import StyledCard from "components/StyledCard";
import { StyledContainer } from "components/StyledContainer";
import StyledDisabledSecondaryGreyMini from "components/StyledDisabledSecondaryGreyMini";
import { Maybe, PartStatus, PartType, StockStore } from "operations/schema/schema";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import {
  addPartsMany,
  selectJobsWithPreOrderedParts,
  selectUnusedPreOrderedPartsCount,
  setViewUnusedPreOrderedPartsOpen,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

interface PreOrderedPart extends PartType {
  checked: boolean;
}

interface JobWithUnusedParts {
  id: string;
  externalId: string;
  customerName: Maybe<string>;
  equipmentName: Maybe<string>;
  symptomDescription: Maybe<string>;
  parts: PreOrderedPart[];
}

export const ViewPreOrderedPartsDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { userVar: userData } = useAppSelector((state) => state.user);
  const jobs = useAppSelector(selectJobsWithPreOrderedParts);
  const open = useAppSelector((s) => s.jobs.unusedPreOrderedParts.open);
  const [allPartsChecked, setAllPartsChecked] = useState<boolean>(true);
  const unusedPreOrderedPartsCount = useAppSelector(selectUnusedPreOrderedPartsCount);
  const [jobsWithParts, setJobsWithParts] = useState<JobWithUnusedParts[]>([]);

  useEffect(() => {
    if (open) {
      setAllPartsChecked(true);
      setJobsWithParts(
        jobs.map((job) => {
          let usedParts = job.usedParts.filter((p) => p && p?.stockStore !== StockStore.Other);
          var unusedPreordered = job.preOrderedParts.filter(
            (pop) =>
              !usedParts.find((up) => up?.id === pop?.id && up?.partNumber === pop?.partNumber)
          );
          let j: JobWithUnusedParts = {
            id: job.id,
            externalId: job.externalId,
            customerName: job.customer?.name,
            equipmentName: job.equipment?.name,
            symptomDescription: job.symptomDescription,
            parts: unusedPreordered.map((p) => ({ ...p, checked: true })),
          };
          return j;
        })
      );
    }
  }, [jobs, open]);

  const handleClose = () => {
    dispatch(setViewUnusedPreOrderedPartsOpen({ open: false }));
  };

  const toggleAllPartsChecked = () => {
    if (allPartsChecked) {
      setJobsWithParts(
        jobsWithParts.map((j) => ({ ...j, parts: j.parts.map((p) => ({ ...p, checked: false })) }))
      );
      setAllPartsChecked(false);
    } else {
      setJobsWithParts(
        jobsWithParts.map((j) => ({ ...j, parts: j.parts.map((p) => ({ ...p, checked: true })) }))
      );
      setAllPartsChecked(true);
    }
  };

  const getCheckedAsJobUsedParts = (job: JobWithUnusedParts) => {
    return job.parts
      .filter((p: PreOrderedPart) => p.checked === true)
      .map((selectedPart: PreOrderedPart) => {
        const part: PartType = {
          id: selectedPart.id || "",
          partNumber: selectedPart.partNumber || "",
          barcode: selectedPart.barcode,
          quantity: selectedPart.quantity,
          stockStore: StockStore.Engineer,
          stockId: userData?.suppliedStoreId?.toString(),
        };

        return {
          maxQuantity: part.quantity || 0,
          salesPriceChanged: false,
          part: {
            ...part,
            description: selectedPart.description || "",
            status: PartStatus.RequestedUsed,
          },
        };
      });
  };

  const addCheckedParts = () => {
    const jobsWithAddedParts = jobsWithParts.map((j) => ({
      id: j.id,
      parts: getCheckedAsJobUsedParts(j),
    }));
    dispatch(addPartsMany({ jobsWithAddedParts }));
    dispatch(addSnackbarMessage({ key: "AddParts-success" }));
    handleClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} data-testid="ViewPreOrderedPartsDialog">
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="ViewPreOrderedPartsDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            <FormattedMessage id="part.requestedParts" />
          </Typography>
          <StyledDisabledSecondaryGreyMini disabled endIcon={<ConstructionIcon />}>
            {unusedPreOrderedPartsCount}
          </StyledDisabledSecondaryGreyMini>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer data-testid="ViewPreOrderedPartsDialog" sx={{ mt: 1 }}>
        <Grid container minWidth="100%" alignItems="center">
          <Grid item xs={11} style={{ padding: "8px 16px 16px" }}>
            <Typography>
              <FormattedMessage id="part.allParts" />
            </Typography>
          </Grid>
          <Grid>
            <Checkbox color="primary" checked={allPartsChecked} onChange={toggleAllPartsChecked} />
          </Grid>
        </Grid>
        <Grid container alignItems="center" minWidth="100%" mt={2}>
          {jobsWithParts.map((job, jobIndex) => (
            <Accordion
              defaultExpanded
              disableGutters
              style={{ width: "100%", boxShadow: "none" }}
              key={job.id}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid container minWidth="100%">
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="body1" component="h4" color="primary">
                        <span>{job.externalId}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                  {job.customerName && (
                    <Grid container>
                      <Typography variant="body1">
                        <span>{job.customerName}</span>
                      </Typography>
                    </Grid>
                  )}
                  {job.equipmentName && (
                    <Grid container>
                      <Typography variant="body1">{job.equipmentName}</Typography>
                    </Grid>
                  )}
                  {job.symptomDescription && (
                    <Grid container>
                      <Typography variant="body2" color="textSecondary">
                        {job.symptomDescription}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {job.parts.map((part, partIndex) => (
                  <Grid container minWidth="100%" alignItems="center" key={part.id}>
                    <Grid item xs={11}>
                      <StyledCard>
                        <CardContent>
                          <Typography variant="body1" component="h3">
                            {part.description}
                          </Typography>
                          <Typography color="textSecondary">{part.partNumber}</Typography>
                          <Typography variant="body2" component="p">
                            <FormattedMessage id="general.quantity" />: {part.quantity}
                          </Typography>
                        </CardContent>
                      </StyledCard>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "center" }}>
                      <Checkbox
                        color="primary"
                        checked={part.checked}
                        onChange={() => {
                          let job = jobsWithParts[jobIndex];
                          if (!job) return;
                          let partChecked = job.parts[partIndex].checked;
                          job.parts[partIndex].checked = !partChecked;
                          if (partChecked) setAllPartsChecked(false);
                          else {
                            setAllPartsChecked(
                              jobsWithParts.every((j) => j.parts.every((p) => p.checked))
                            );
                          }

                          setJobsWithParts([
                            ...jobsWithParts.slice(0, jobIndex),
                            job,
                            ...jobsWithParts.slice(jobIndex + 1),
                          ]);
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid item>
          <PrimaryButton
            fullWidth
            variant="contained"
            onClick={addCheckedParts}
            disabled={jobsWithParts.every((j) => j.parts.every((p) => !p.checked))}
            data-testid="ViewPreOrderedParts-AddPart"
          >
            {intl.formatMessage({
              id: "visit.addPart",
            })}
          </PrimaryButton>
        </Grid>
      </StyledContainer>
    </Dialog>
  );
};
