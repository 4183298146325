import {
  Close as CloseIcon,
  NotificationImportant as NotificationImportantIcon,
} from "@mui/icons-material";
import { Alert, Link } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { resetRefreshNotifications, setJobTab } from "store/slices/jobs.store";

export const RefreshJobAlert: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { refreshJobNotifications } = useAppSelector((s) => s.jobs);

  const onDimiss = (key: string) => {
    dispatch(resetRefreshNotifications({ key }));
  };

  return (
    <>
      {refreshJobNotifications.symptomText && (
        <Link
          component="button"
          onClick={() => {
            dispatch(setJobTab({ tab: "details" }));
            onDimiss("symptomText");
          }}
          sx={{
            width: 1,
          }}
          data-testid="RefreshJobAlert-SymptomText"
        >
          <Alert
            sx={{
              width: 1,
              borderRadius: 0,
            }}
            variant="filled"
            severity="info"
            icon={<NotificationImportantIcon fontSize="inherit" />}
            action={
              <div
                style={{ margin: "auto", display: "flex", alignItems: "center" }}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  onDimiss("symptomText");
                }}
                data-testid="RefreshJobAlert-SymptomTextDismiss"
              >
                {intl.formatMessage({ id: "refreshJob.alert.dismiss" })}
                <CloseIcon />
              </div>
            }
          >
            <FormattedMessage id="refreshJob.alert.symptomText" />
          </Alert>
        </Link>
      )}
      {refreshJobNotifications.internalNotes && (
        <Link
          component="button"
          onClick={() => {
            dispatch(setJobTab({ tab: "notes" }));
            onDimiss("notes");
          }}
          sx={{
            width: 1,
          }}
          data-testid="RefreshJobAlert-Notes"
        >
          <Alert
            sx={{
              width: 1,
              borderRadius: 0,
            }}
            variant="filled"
            severity="info"
            icon={<NotificationImportantIcon fontSize="inherit" />}
            action={
              <div
                style={{ margin: "auto", display: "flex", alignItems: "center" }}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  onDimiss("notes");
                }}
                data-testid="RefreshJobAlert-NotesDismiss"
              >
                {intl.formatMessage({ id: "refreshJob.alert.dismiss" })}
                <CloseIcon />
              </div>
            }
          >
            <FormattedMessage id="refreshJob.alert.notes" />
          </Alert>
        </Link>
      )}
      {refreshJobNotifications.files && (
        <Link
          component="button"
          onClick={() => {
            dispatch(setJobTab({ tab: "files" }));
            onDimiss("files");
          }}
          sx={{
            width: 1,
          }}
          data-testid="RefreshJobAlert-Files"
        >
          <Alert
            sx={{
              width: 1,
              borderRadius: 0,
            }}
            variant="filled"
            severity="info"
            icon={<NotificationImportantIcon fontSize="inherit" />}
            action={
              <div
                style={{ margin: "auto", display: "flex", alignItems: "center" }}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  onDimiss("files");
                }}
                data-testid="RefreshJobAlert-FilesDismiss"
              >
                {intl.formatMessage({ id: "refreshJob.alert.dismiss" })}
                <CloseIcon />
              </div>
            }
          >
            <FormattedMessage id="refreshJob.alert.files" />
          </Alert>
        </Link>
      )}
      {refreshJobNotifications.preOrderedParts && (
        <Link
          component="button"
          onClick={() => {
            dispatch(setJobTab({ tab: "parts" }));
            onDimiss("parts");
          }}
          sx={{
            width: 1,
          }}
          data-testid="RefreshJobAlert-Parts"
        >
          <Alert
            sx={{
              width: 1,
              borderRadius: 0,
            }}
            variant="filled"
            severity="info"
            icon={<NotificationImportantIcon fontSize="inherit" />}
            action={
              <div
                style={{ margin: "auto", display: "flex", alignItems: "center" }}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  onDimiss("parts");
                }}
                data-testid="RefreshJobAlert-PartsDismiss"
              >
                {intl.formatMessage({ id: "refreshJob.alert.dismiss" })}
                <CloseIcon />
              </div>
            }
          >
            <FormattedMessage id="refreshJob.alert.parts" />
          </Alert>
        </Link>
      )}
    </>
  );
};
