import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";

import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import { isAbortError } from "helpers";
import { ContactType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import {
  editContact,
  getContacts,
  selectContactsLoading,
  selectSelectedJob,
  setEditContactOpen,
  setVisitValue,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

type EditContactDialogProps = {
  onSave?: () => void;
};

export const EditContactDialog: FC<EditContactDialogProps> = (props) => {
  const { onSave } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { open, loading } = useAppSelector((s) => s.jobs.editContact);
  const contactsLoading = useAppSelector(selectContactsLoading);
  const [contacts, setContacts] = useState<ContactType[]>([]);
  const addNewContact: ContactType = useMemo(() => {
    return {
      email: intl.formatMessage({ id: "contact.addContact" }),
      name: "",
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      mobile: "",
    };
  }, [intl]);
  const [selectedContact, setSelectedContact] = useState<ContactType>(addNewContact);

  const [name, setName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const reset = useCallback(() => {
    setSelectedContact(addNewContact);
    setName("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setMobile("");
    setEmail("");
  }, [addNewContact]);

  const close = useCallback(() => {
    reset();
    dispatch(setEditContactOpen({ open: false }));
  }, [dispatch, reset]);

  const editContactCb = useCallback(() => {
    dispatch(
      editContact({
        jobId: job.id,
        customerId: job.customer?.id ?? "",
        equipmentId: "",
        contact: {
          id: selectedContact?.id ?? "",
          contactNo: selectedContact?.contactNo ?? "",
          name,
          firstName,
          lastName,
          phone,
          mobile,
          email,
        },
      })
    )
      .then(unwrapResult)
      .then(({ queued }) => {
        if (queued) {
          dispatch(addSnackbarMessage({ key: "EditContact-stored" }));
        } else {
          dispatch(addSnackbarMessage({ key: "EditContact-success" }));
        }
        dispatch(setVisitValue({ key: "customerSignerName", value: name }));
        if (onSave) onSave();
        close();
      })
      .catch((error: any) => {
        if (isAbortError(error)) return;
        if (Array.isArray(error) && error.some((e) => e.message.includes("403 Forbidden"))) {
          dispatch(addSnackbarMessage({ key: "EditContact-forbidden" }));
        } else {
          dispatch(addSnackbarMessage({ key: "EditContact-fail" }));
        }
      });
  }, [
    close,
    dispatch,
    email,
    firstName,
    job.customer?.id,
    job.id,
    lastName,
    mobile,
    name,
    onSave,
    phone,
    selectedContact?.contactNo,
    selectedContact?.id,
  ]);

  useEffect(() => {
    if (open) {
      dispatch(getContacts())
        .then(unwrapResult)
        .then((fetchedContacts) => {
          setContacts([addNewContact, ...fetchedContacts]);
        });
    }
  }, [open, dispatch, addNewContact]);

  return (
    <Dialog fullScreen open={open} onClose={close} data-testid="EditContactDialog">
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="EditContactDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            {`${job?.externalId} `} / <FormattedMessage id="contact.editContact" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Card>
        <CardContent sx={{ paddingTop: 3 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Autocomplete
                data-testid="EditContact-SelectContact"
                fullWidth
                disableClearable
                loading={contactsLoading}
                options={contacts}
                defaultValue={contacts[0]}
                getOptionLabel={(option: ContactType) => {
                  return option.name === ""
                    ? `${option.email}`
                    : `${option.email} - ${option.name}`;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => {
                  if (value == null || value.id === "") {
                    reset();
                    setSelectedContact(addNewContact);
                  } else {
                    const contact = value;
                    setSelectedContact(contact);
                    setFirstName(contact.firstName ?? "");
                    setLastName(contact.lastName ?? "");
                    setName(contact.name ?? "");
                    setPhone(contact.phone ?? "");
                    setMobile(contact.mobile ?? "");
                    setEmail(contact.email ?? "");
                  }
                }}
                value={selectedContact}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label={intl.formatMessage({
                      id: "contact",
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <StyledTextField
                value={firstName}
                label={intl.formatMessage({ id: "contact.firstName" })}
                data-testid="EditContact-FirstName"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setName((e.target.value + " " + lastName).trim());
                }}
              />
            </Grid>
            <Grid item>
              <StyledTextField
                value={lastName}
                label={intl.formatMessage({ id: "contact.lastName" })}
                data-testid="EditContact-LastName"
                onChange={(e) => {
                  setLastName(e.target.value);
                  setName((firstName + " " + e.target.value).trim());
                }}
              />
            </Grid>
            <Grid item>
              <StyledTextField
                value={name}
                label={intl.formatMessage({ id: "contact.fullName" })}
                data-testid="EditContact-FullName"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item>
              <StyledTextField
                value={phone}
                label={intl.formatMessage({ id: "contact.phone" })}
                data-testid="EditContact-Phone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item>
              <StyledTextField
                value={mobile}
                label={intl.formatMessage({ id: "contact.mobile" })}
                data-testid="EditContact-Mobile"
                onChange={(e) => setMobile(e.target.value)}
              />
            </Grid>
            <Grid item>
              <StyledTextField
                value={email}
                label={intl.formatMessage({ id: "general.email" })}
                data-testid="EditContact-Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item>
              <PrimaryButton
                key={`edit-contact-submit-button`}
                fullWidth
                variant="contained"
                disabled={!name || loading}
                onClick={editContactCb}
                data-testid="EditContact-SaveButton"
              >
                <FormattedMessage id="general.save" />
              </PrimaryButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};
