import { FC } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  AppBar,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import { useAppDispatch, useAppSelector } from "store";
import {
  handleCloseAddChecklist,
  selectSelectedJob,
  setAddChecklistSelectOpen,
} from "store/slices/jobs.store";
import { AddChecklistSelectDialog } from "./AddChecklistSelectDialog";

export const AddChecklistDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const currentEquipment = job?.equipment;
  const machineInstallDate = currentEquipment?.installDate;
  const { open, loading } = useAppSelector((state) => state.jobs.addChecklist);

  const handleClose = () => {
    dispatch(handleCloseAddChecklist());
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} data-testid="AddChecklistDialog">
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="AddChecklistDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            {`${job?.externalId} `} / <FormattedMessage id="visit.addChecklist" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <List>
        <ListItemButton
          divider
          data-testid="AddChecklist-WithoutMachine"
          onClick={() => dispatch(setAddChecklistSelectOpen({ open: true, useEquipment: false }))}
        >
          <ListItemText>
            <FormattedMessage id="visit.addChecklistWithoutMachine" />
          </ListItemText>
        </ListItemButton>

        {currentEquipment && (
          <ListItemButton
            divider
            component={Grid}
            onClick={() => dispatch(setAddChecklistSelectOpen({ open: true, useEquipment: true }))}
          >
            <Grid item>
              <Typography fontWeight={700}>{currentEquipment.name}</Typography>
              <Typography>{currentEquipment.serialNumber}</Typography>
              {currentEquipment.assetNumber && (
                <Typography>{currentEquipment.assetNumber}</Typography>
              )}
              {currentEquipment.location && <Typography>{currentEquipment.location}</Typography>}
              {machineInstallDate && (
                <Typography>
                  {`${intl.formatMessage({ id: "general.installed" })} `}
                  <FormattedDate
                    value={machineInstallDate}
                    day="numeric"
                    month="long"
                    year="numeric"
                  />
                </Typography>
              )}
            </Grid>
          </ListItemButton>
        )}
      </List>
      <AddChecklistSelectDialog />
    </Dialog>
  );
};
