import { FC } from "react";

import { AlertNotesDialog } from "components/AlertNotesDialog";
import { JobDetails } from "pages/jobs/jobDetails/JobDetails";

export const JobDetailsPage: FC = () => {
  return (
    <>
      <JobDetails />
      <AlertNotesDialog />
    </>
  );
};
