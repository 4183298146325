import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import FullScreenDialog from "components/FullScreenDialog";
import { TabPanel } from "components/TabPanel";
import { isPartRequestedUsed, isPartReturnRequested, isPartUsed } from "helpers";
import { StockStore } from "operations/schema/schema";

import { useAppSelector } from "store";
import { selectSelectedJob, selectSelectedJobVisit } from "store/slices/jobs.store";

import { UsedPart } from "models/usedPart";
import { TabProps } from "./TabProps";
import { AddPartEnum } from "./part/AddPartEnum";
import { AddPreOrderedParts } from "./part/AddPreOrderedParts";
import { PartTab } from "./part/PartTab";

export const PartsTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const intl = useIntl();
  const job = useAppSelector(selectSelectedJob);
  const { usedParts } = useAppSelector(selectSelectedJobVisit);
  const [tabIndex, setTabIndex] = useState("usedParts");
  const [openPreOrderedPartsDialog, setOpenPreOrderedPartsDialog] = useState(false);

  const currentParts = usedParts.filter(
    (p) =>
      p.part.stockStore !== StockStore.Other && (isPartUsed(p.part) || isPartRequestedUsed(p.part))
  );

  const usedPartsLabelCount: number = currentParts?.length;

  const usedPartLabel =
    intl.formatMessage({ id: "part.used" }) +
    (usedPartsLabelCount ? ` (${usedPartsLabelCount})` : "");

  const returnRequestedParts = usedParts.filter((p) => isPartReturnRequested(p.part));

  const returnRequestedPartsLabelCount: number = returnRequestedParts?.length;

  const returnRequestedPartsLabel =
    intl.formatMessage({ id: "part.returned" }) +
    (returnRequestedPartsLabelCount ? ` (${returnRequestedPartsLabelCount})` : "");

  const requestedParts = usedParts.filter((p) => p.part.stockStore === StockStore.Other);

  const requestedPartsLabelCount: number = requestedParts?.length;

  const requestedPartLabel =
    intl.formatMessage({ id: "part.requested" }) +
    (requestedPartsLabelCount ? ` (${requestedPartsLabelCount})` : "");

  const used: UsedPart[] = [...currentParts, ...returnRequestedParts]?.filter(
    (p) => !isPartUsed(p.part)
  );

  const preOrdered = job.preOrderedParts.filter((a) =>
    used.every((b) => !(a.id === b.part?.id && a.partNumber === b.part?.partNumber))
  );

  useEffect(() => {
    if (!!preOrdered.length) {
      setOpenPreOrderedPartsDialog(true);
    } else {
      setOpenPreOrderedPartsDialog(false);
    }
  }, [preOrdered.length]);

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={(_, newValue: string) => {
          setTabIndex(newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab value="usedParts" sx={{ whiteSpace: "nowrap" }} label={usedPartLabel} />
        {returnRequestedPartsLabelCount > 0 && (
          <Tab
            value="returnRequested"
            sx={{ whiteSpace: "nowrap" }}
            label={returnRequestedPartsLabel}
          />
        )}
        <Tab value="requested" sx={{ whiteSpace: "nowrap" }} label={requestedPartLabel} />
      </Tabs>
      <TabPanel value={tabIndex} index="usedParts">
        <PartTab
          setTabIndex={setTabIndex}
          parts={currentParts}
          dialogType={AddPartEnum.AddPart}
          hasPreOrderedParts={!!preOrdered.length}
          setOpenPreOrderedPartsDialog={setOpenPreOrderedPartsDialog}
          setFab={setFab}
        />
      </TabPanel>
      {returnRequestedPartsLabelCount > 0 && (
        <TabPanel value={tabIndex} index="returnRequested">
          <PartTab
            hideAddButton
            setTabIndex={setTabIndex}
            parts={returnRequestedParts}
            dialogType={AddPartEnum.AddPart}
            setFab={setFab}
          />
        </TabPanel>
      )}
      <TabPanel value={tabIndex} index="requested">
        <PartTab
          setTabIndex={setTabIndex}
          parts={requestedParts}
          dialogType={AddPartEnum.RequestPart}
          setFab={setFab}
        />
      </TabPanel>
      <FullScreenDialog
        child={<AddPreOrderedParts parts={preOrdered} />}
        open={openPreOrderedPartsDialog}
        setOpen={setOpenPreOrderedPartsDialog}
      />
    </>
  );
};
