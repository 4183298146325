import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConstructionIcon from "@mui/icons-material/Construction";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import ConnectionStatusIcon from "components/ConnectionStatusIcon";
import StyledButtonSecondaryGreyMini from "components/StyledButtonSecondaryGreyMini";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";
import { setOpen as setDialogOpen } from "store/slices/dialog.store";
import { getEngineerHistory } from "store/slices/history.store";
import {
  clearNewJobIds,
  downloadJobs,
  getJobs,
  selectIncompleteJobs,
  selectUnusedPreOrderedPartsCount,
  setViewUnusedPreOrderedPartsOpen,
} from "store/slices/jobs.store";
import { getPlannerJobs } from "store/slices/planner.store";
import { SignoutDialog } from "../dialogs/SignoutDialog";
import { MenuDrawer } from "./MenuDrawer";
import { RightMenuDrawer } from "./RightMenuDrawer";
import { JobStatus } from "operations/schema/schema";

const PREFIX = "MenuAppBar";

const classes = {
  menuButton: `${PREFIX}-menuButton`,
  hidden: `${PREFIX}-hidden`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.hidden}`]: {
    visibility: "hidden",
  },
}));

interface MenuAppBarProps {}

export const MenuAppBar: FC<MenuAppBarProps> = (props) => {
  const intl = useIntl();
  const isOnline = useAppSelector(selectConnectionStatus);
  const dispatch = useAppDispatch();
  const { page } = useAppSelector((s) => s.root);
  const {
    appBar: { title, goingBack, sortingType, refetchEnabled, backAction, unusedPartsButton },
  } = useAppSelector((s) => s.menu);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openHistoryMenu, setOpenHistoryMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const unusedPreOrderedPartsCount = useAppSelector(selectUnusedPreOrderedPartsCount);
  const showUnusedParts = page === "jobs" && unusedPartsButton && unusedPreOrderedPartsCount > 0;
  const jobs = useAppSelector(selectIncompleteJobs, shallowEqual);

  const openOfflineQueue = (open: boolean) => {
    dispatch(setDialogOpen({ dialogName: "queue", open }));
  };

  const onRefetch = () => {
    switch (page) {
      case "jobs":
        dispatch(getJobs({ force: true }))
          .then(unwrapResult)
          .then(() => {
            if (jobs.length > 0) {
              var jobIds: string[] = [];
              jobs.forEach((job) => {
                if (job.status === JobStatus.Assigned) {
                  jobIds.push(job.id);
                }
              });
              if (jobIds.length > 0) {
                dispatch(
                  downloadJobs({
                    jobIds,
                  })
                );
              }
            }
          });
        break;
      case "planner":
        dispatch(getPlannerJobs());
        break;
      case "history":
        dispatch(getEngineerHistory());
        break;
    }
  };

  const onBackAction = () => {
    if (backAction === "ClearNewJobIds") {
      dispatch(clearNewJobIds());
    }
  };

  const topLeftButton = goingBack ? (
    <IconButton
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label={intl.formatMessage({ id: "general.back" })}
      onClick={() => {
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
        } else {
          navigate("/", { replace: true });
        }
        onBackAction();
      }}
      size="large"
    >
      <ArrowBackIcon />
    </IconButton>
  ) : (
    <IconButton
      edge="start"
      className={`${classes.menuButton}`}
      color="inherit"
      aria-label={intl.formatMessage({ id: "menu" })}
      onClick={() => setOpenMenu(true)}
      size="large"
    >
      <MenuIcon />
    </IconButton>
  );

  return (
    <Root sx={{ flexGrow: 1 }}>
      <SignoutDialog />
      <AppBar
        position="fixed"
        data-testid="MenuAppBar"
        sx={{
          backgroundColor: "primary.dark",
        }}
      >
        <Toolbar>
          {topLeftButton}
          {/*fake dom element to keep title centered*/}
          {!isOnline && (
            <div
              style={{
                visibility: "hidden",
                minWidth: 64,
              }}
            />
          )}
          <Typography
            onClick={() => (refetchEnabled ? onRefetch() : {})}
            textAlign="center"
            className="truncated"
            ml="auto"
            mr="auto"
            data-testid="menuTitle"
          >
            {title}
          </Typography>
          {!isOnline && <ConnectionStatusIcon onClick={() => openOfflineQueue(true)} />}
          {showUnusedParts && (
            <StyledButtonSecondaryGreyMini
              onClick={() => dispatch(setViewUnusedPreOrderedPartsOpen({ open: true }))}
              endIcon={<ConstructionIcon />}
            >
              {unusedPreOrderedPartsCount}
            </StyledButtonSecondaryGreyMini>
          )}
          {!!sortingType && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label={intl.formatMessage({ id: "menu" })}
              onClick={() => setOpenHistoryMenu(true)}
              data-testid="MenuAppBar-SortingMenu-ButtonOpen"
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />

      <MenuDrawer openMenu={openMenu} setOpenMenu={setOpenMenu} />

      <RightMenuDrawer openMenu={openHistoryMenu} setOpenMenu={setOpenHistoryMenu} />
    </Root>
  );
};
