import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      dark: "#2e4535",
      main: "#597d6a",
      light: "#a3b2a4",
    },
    secondary: {
      dark: "#4a4a49",
      main: "#878787",
      light: "#c6c6c6",
    },
    info: {
      dark: "#0d3c61",
      main: "#0a73ba",
      light: "#d9e0f3",
    },
    success: {
      dark: "#1e4620",
      main: "#2e8532",
      light: "#dce4d4",
    },
    warning: {
      dark: "#663c00",
      main: "#f29808",
      light: "#fceace",
    },
    error: {
      dark: "#611a15",
      main: "#da1b1e",
      light: "#f6d7ca",
    },
  },
});

export default theme;
