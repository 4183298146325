import { ServiceJob } from "operations/schema/schema";
import { IntlShape } from "react-intl";
import { formatDate } from "./date";
import { groupByProperty } from "./groupBy";
import { sortByProperty } from "./sortBy";
import { SortingOrder } from "./sorters";

// Defines which properties/keys are allowed to group by
export const jobGroupingKeys = ["plannedDate", "customer", "postalCode", "priority"] as const;
export const historyGroupingKeys = ["completedDate", "customer", "postalCode"] as const;
// // // //
export type JobGroupingKey = (typeof jobGroupingKeys)[number];
export type HistoryGroupingKey = (typeof historyGroupingKeys)[number];
export type GroupingKey = JobGroupingKey | HistoryGroupingKey;

export interface Group<T extends JobGroupingKey | HistoryGroupingKey> {
  key: T;
  order: SortingOrder;
}
type GroupByTuple = [string, ServiceJob[]];
type GroupBy = {
  [x in GroupingKey]: (
    arr: ServiceJob[],
    intl: IntlShape,
    order?: SortingOrder
  ) => {
    grouped: GroupByTuple[];
    defKey: string;
  };
};
const groupBy: GroupBy = {
  plannedDate: (arr, intl, order) => {
    const defKey = intl.formatMessage({ id: "general.unplanned" });
    let grouped = groupByProperty(
      arr,
      (s) => formatDate(s.plannedDate?.startTime || s.responseDate),
      defKey
    );
    return { defKey, grouped: sortGroup(grouped, sortByDate, defKey, order) };
  },
  completedDate: (arr, intl, order) => {
    const defKey = intl.formatMessage({ id: "general.unknown" });
    let grouped = groupByProperty(
      arr,
      (s) => formatDate(s.completedDate || s.workTimes[s.workTimes!.length - 1]?.stopTime),
      defKey
    );
    return { defKey, grouped: sortGroup(grouped, sortByDate, defKey, order) };
  },
  customer: (arr, intl, order) => {
    const defKey = intl.formatMessage({ id: "sorting.noCustomer" });
    let grouped = groupByProperty(arr, (s) => s.customer?.name, defKey);
    return { defKey, grouped: sortGroup(grouped, sortByString, defKey, order) };
  },
  postalCode: (arr, intl, order) => {
    const defKey = intl.formatMessage({ id: "sorting.noPostalCode" });
    let grouped = groupByProperty(arr, (s) => s.customer?.address?.postalCode, defKey);
    return { defKey, grouped: sortGroup(grouped, sortByString, defKey, order) };
  },
  priority: (arr, intl, order) => {
    const defKey = intl.formatMessage({ id: "sorting.noPriority" });
    let grouped = groupByProperty(arr, (s) => s.priority, defKey);
    return { defKey, grouped: sortPriorityGroup(grouped, sortByString, defKey, order) };
  },
};
export const getGroupBy = (key: GroupingKey) => groupBy[key];

export const groupOptionText = (intl: IntlShape, key: GroupingKey): string => {
  if (key === "plannedDate") return intl.formatMessage({ id: "sorting.plannedDate" });
  if (key === "completedDate") return intl.formatMessage({ id: "sorting.completedDate" });
  if (key === "customer") return intl.formatMessage({ id: "general.customer" });
  if (key === "postalCode") return intl.formatMessage({ id: "general.postalCode" });
  if (key === "priority") return intl.formatMessage({ id: "sorting.priority" });
  throw Error("invalid key");
};

const sortGroup = (
  grouped: Record<string, ServiceJob[]>,
  sortByFn: (
    a: string | number,
    b: string | number,
    defKey: string,
    order?: SortingOrder
  ) => number,
  defKey: string,
  order?: SortingOrder
): GroupByTuple[] => {
  return Object.keys(grouped)
    .sort((a, b) => sortByFn(a, b, defKey, order))
    .map((key) => [key, grouped[key]]);
};
const sortByString = (
  a: string | number,
  b: string | number,
  defKey: string,
  order?: SortingOrder
): number => {
  if (a === defKey) return 1;
  if (b === defKey) return -1;
  return sortByProperty(a, b, order);
};
const sortByDate = (
  a: string | number,
  b: string | number,
  defKey: string,
  order?: SortingOrder
): number => {
  if (a === defKey) return 1;
  if (b === defKey) return -1;
  return sortByProperty(new Date(a).getTime(), new Date(b).getTime(), order);
};

const sortPriorityGroup = (
  grouped: Record<string, ServiceJob[]>,
  sortByFn: (
    a: string | number,
    b: string | number,
    defKey: string,
    order?: SortingOrder
  ) => number,
  defKey: string,
  order?: SortingOrder
): GroupByTuple[] => {
  return Object.keys(grouped)
    .sort((a, b) => {
      const aCode = grouped[a][0]?.priorityCode || defKey;
      const bCode = grouped[b][0]?.priorityCode || defKey;
      return sortByFn(aCode, bCode, defKey, order);
    })
    .map((key) => [key, grouped[key]]);
};
