import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { intlFormatDateWithWeekday, toDate, toDateString } from "helpers";
import { Maybe, MeterReadingInputType, MeterType } from "operations/schema/schema";

import StyledTextField from "components/StyledTextField";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJob, setMetersNotSaved } from "store/slices/jobs.store";

export interface MeterValidation {
  typeId: string;
  valid: boolean;
}

const PREFIX = "MeterReading";
const classes = {
  warning: `${PREFIX}-warning`,
  helperText: `${PREFIX}-helperText`,
};
const StyledDiv = styled("div")(({ theme }) => ({
  [`& .${classes.helperText}`]: {
    color: theme.palette.warning.main,
  },
  [`& .${classes.warning}`]: {
    "& label": {
      color: theme.palette.warning.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.warning.main,
      },
    },
  },
}));

type MeterReadingProps = {
  meter: Maybe<MeterType>;
  setError: (value: MeterValidation) => void;
  index: number;
  readings: MeterReadingInputType[];
  setReadings: (value: SetStateAction<MeterReadingInputType[]>) => void;
  useCurrent: boolean;
  required: boolean;
};

export const MeterReadingComponent: FC<MeterReadingProps> = (props: MeterReadingProps) => {
  const { meter, setError, index, readings, setReadings, useCurrent, required } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const typeId = readings[index].typeId;
  const currentReading = meter?.previousReading;
  const [value, setValue] = useState(
    readings[index].currentReading === 0 || readings[index].currentReading === undefined
      ? ""
      : (readings[index].currentReading || 0).toString()
  );
  const job = useAppSelector(selectSelectedJob);

  const [errorText, setErrorText] = useState<string>("");
  const [warningText, setWarningText] = useState<string>("");

  const validateReading = useCallback(() => {
    if (!useCurrent) {
      const intValue = parseInt(value.toString());

      if (currentReading && !isNaN(intValue)) {
        if (intValue < currentReading?.reading!) {
          setWarningText("");
          setErrorText(
            intl.formatMessage({
              id: "visit.meters.shouldBeGreaterThanPrevious",
            })
          );
          setError({ typeId: typeId || "", valid: false });
        } else if (
          meter?.unitsPerDay &&
          meter?.unitsPerDay > 0 &&
          job.equipment?.increaseLimit &&
          job.equipment?.increaseLimit > 0 &&
          meter.previousReading?.reading &&
          meter.previousReading?.readingDate &&
          currentReading?.readingDate
        ) {
          const numOfDays = Math.floor(
            (toDate(meter.previousReading?.readingDate).setHours(0, 0, 0, 0) -
              toDate(currentReading?.readingDate).setHours(0, 0, 0, 0)) /
              1000 /
              60 /
              60 /
              24
          );
          if (
            numOfDays > 0 &&
            (intValue - meter.previousReading?.reading) / numOfDays >
              meter?.unitsPerDay * (1 + job.equipment?.increaseLimit)
          ) {
            setWarningText("");
            setErrorText(
              intl.formatMessage({
                id: "visit.meters.valueTooHighPrevious",
              })
            );
            setError({ typeId: typeId || "", valid: false });
          }
          setWarningText("");
          setErrorText("");
          setError({ typeId: typeId || "", valid: true });
        } else if (intValue >= currentReading?.reading!) {
          setWarningText("");
          setErrorText("");
          setError({ typeId: typeId || "", valid: true });
        }
      } else if (value === "" && !required) {
        setWarningText("");
        setErrorText("");
        setError({ typeId: typeId || "", valid: true });
      } else if (isNaN(intValue)) {
        setWarningText("");
        setErrorText(
          intl.formatMessage({
            id: "general.enterValidNumber",
          })
        );
        setError({ typeId: typeId || "", valid: false });
      } else {
        setWarningText("");
        setErrorText("");
        setError({ typeId: typeId || "", valid: true });
      }

      setReadings((readings) => {
        return [
          ...readings.slice(0, index),
          {
            typeId: typeId,
            currentReading: intValue,
            currentReadingDate: toDateString(new Date()),
          },
          ...readings.slice(index + 1),
        ];
      });
    }
  }, [
    useCurrent,
    value,
    currentReading,
    required,
    setReadings,
    meter?.unitsPerDay,
    meter?.previousReading?.reading,
    meter?.previousReading?.readingDate,
    job.equipment?.increaseLimit,
    intl,
    setError,
    typeId,
    index,
  ]);

  useEffect(() => {
    if (!useCurrent) {
      validateReading();
    } else {
      setErrorText("");
    }
  }, [useCurrent, validateReading]);

  useEffect(() => {
    if (useCurrent) {
      setValue((readings[index].currentReading || 0).toString());
    }
  }, [useCurrent, readings, index]);

  const changeValue = (newValue: string) => {
    if (newValue === value) return;
    setValue(newValue);
    dispatch(setMetersNotSaved({ unSavedChanges: true }));
  };

  return (
    <StyledDiv style={{ marginTop: "16px" }}>
      <Typography variant="body1" paragraph>
        {meter?.name}
      </Typography>
      {currentReading ? (
        <Typography variant="subtitle2" color="textPrimary" paragraph>
          {intl.formatMessage({ id: "visit.meters.previous" })} : {currentReading.reading} |
          {` ${intlFormatDateWithWeekday(intl, currentReading.readingDate)}`}
        </Typography>
      ) : null}
      <StyledTextField
        name="currentReading"
        type="number"
        inputProps={{ min: 0 }}
        label={intl.formatMessage({ id: "visit.meters.newReading" })}
        disabled={useCurrent}
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        onChange={(event) => changeValue(event.target.value)}
        helperText={errorText || warningText}
        error={!!errorText}
        size="small"
        required={required}
        className={!!warningText ? classes.warning : ""}
        FormHelperTextProps={{
          className: !!warningText ? classes.helperText : "",
        }}
      />
    </StyledDiv>
  );
};
