import { Grid, LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TaskMarkerIcon, TechnicianMarkerIcon } from "components/icons/MapMarkers";
import PrimaryButton from "components/PrimaryButton";
import { isEmpty } from "helpers";
import engineerSettings from "helpers/engineerSettings";
import "mapbox-gl/dist/mapbox-gl.css";
import { ServiceJob } from "operations/schema/schema";
import { FC, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Map, { Marker } from "react-map-gl";
import { useAppDispatch, useAppSelector } from "store";
import { setSelectedJob } from "store/root.store";
import { selectUser } from "store/slices/user.store";
import { JobMapPopup } from "./JobMapPopup";

const PREFIX = "JobMapView";

const classes = {
  root: `${PREFIX}-root`,
};
const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {
    width: "100vw",
    flex: 1,
    position: "relative",
    padding: 0,
  },
}));

interface JobMapViewProps {
  jobs: ServiceJob[];
  toLink?: string;
  loading?: boolean;
  disabled?: boolean;
  refetchJobs?: any;
  plannerMode?: boolean;
}

type UserLocation = {
  coords: {
    longitude: number;
    latitude: number;
  };
};
export const JobMapView: FC<JobMapViewProps> = (props: JobMapViewProps) => {
  const { jobs, loading = false, refetchJobs, plannerMode } = props;
  const dispatch = useAppDispatch();
  const userVar = useAppSelector(selectUser);
  const [popupJob, setPopupJob] = useState<ServiceJob | null>(null);

  const userLocation: UserLocation = JSON.parse(localStorage.getItem("userLocation") || "{}");

  const techMarker = useMemo(
    () => (
      <Marker
        key={userVar?.technicianNo}
        longitude={userLocation.coords.longitude}
        latitude={userLocation.coords.latitude}
        anchor="bottom"
      >
        <div>{userVar?.fullName}</div>
        <TechnicianMarkerIcon style={{ fontSize: 40, cursor: "pointer" }} />
      </Marker>
    ),
    [userLocation, userVar?.fullName, userVar?.technicianNo]
  );

  const jobMarkers = useMemo(
    () =>
      jobs
        .filter((j) => !!j.coordinates)
        .map((job: ServiceJob) => (
          <Marker
            key={job.id}
            longitude={job.coordinates!.longitude!}
            latitude={job.coordinates!.latitude!}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setPopupJob(job);
              dispatch(setSelectedJob({ jobId: job.id }));
            }}
          >
            <TaskMarkerIcon style={{ fontSize: 40, cursor: "pointer" }} />
          </Marker>
        )),
    [dispatch, jobs]
  );

  return (
    <>
      {isEmpty(jobs) && !loading ? (
        <>
          <Grid container direction="column" alignItems="center" mt={2.5}>
            <Grid item>
              <Typography>
                <FormattedMessage id="general.noJobsFound" />.
              </Typography>
            </Grid>
            {refetchJobs && (
              <Grid item>
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  data-testid="refetchJobs"
                  onClick={() => {
                    refetchJobs(true);
                  }}
                >
                  <FormattedMessage id="general.refreshJobList" />
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Root>
          {loading && <LinearProgress />}
          <Map
            mapboxAccessToken={engineerSettings.mapboxToken}
            initialViewState={{
              longitude: userLocation.coords.longitude,
              latitude: userLocation.coords.latitude,
              zoom: 11,
            }}
            style={{ height: "calc(100vh - 56px - 40px - 16px)", width: "100%" }}
            mapStyle="mapbox://styles/mapbox/streets-v12"
            boxZoom
          >
            {techMarker}
            {jobMarkers}
          </Map>
          {popupJob && (
            <JobMapPopup
              job={popupJob}
              plannerMode={plannerMode}
              onClose={() => {
                setPopupJob(null);
              }}
            />
          )}
        </Root>
      )}
    </>
  );
};
