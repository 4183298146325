import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { mergeDateTimeString, toDateString } from "helpers";
import { createUpdateVisitVars } from "operations/mutations/UpdateVisit";
import {
  ContactType,
  EquipmentInputType,
  JobStatus,
  MeterReadingInputType,
  PropertyValueInputType,
  TaskStatus,
  TimesType,
  UpdateVisitInputType,
} from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State, storeVisitsToLocal } from "./jobs.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncMutations = {
  rejectJob: createAppAsyncThunk(
    "jobs/rejectJob",
    async (props: { jobId: string; rejectReason: string }, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.rejectJobAssignment, {
        thunkName: "jobs/rejectJob",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.rejectJobAssignment) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  acceptJob: createAppAsyncThunk(
    "jobs/acceptJob",
    async (props: { jobId: string }, { rejectWithValue, extra: { sdk } }) => {
      const { jobId } = props;
      const { errors, queued } = await graphqlRequest(sdk.updateJobStatus, {
        thunkName: "jobs/acceptJob",
        thunkProps: props,
        variables: {
          jobId,
          status: JobStatus.Accepted,
        },
      });
      if (errors) return rejectWithValue(errors);
      return { queued };
    }
  ),
  downloadJobs: createAppAsyncThunk(
    "jobs/downloadJobs",
    async (props: { jobIds: string[] }, { rejectWithValue, extra: { sdk } }) => {
      const { jobIds } = props;
      const { errors, queued } = await graphqlRequest(sdk.updateJobsStatus, {
        thunkName: "jobs/downloadJobs",
        thunkProps: props,
        variables: {
          jobIds,
          status: JobStatus.Downloaded,
        },
      });
      if (errors) return rejectWithValue(errors);
      return { queued };
    }
  ),
  //EditEquipmentDialog
  updateEquipment: createAppAsyncThunk(
    "jobs/updateEquipment",
    async (
      props: { jobId: string; equipment: EquipmentInputType },
      { rejectWithValue, extra: { sdk } }
    ) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.updateEquipment, {
        thunkName: "jobs/updateEquipment",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateEquipment) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  //ChangeJobEquipmentDialog
  changeJobEquipment: createAppAsyncThunk(
    "jobs/changeJobEquipment",
    async (props: { jobId: string; equipmentId: string }, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.changeJobEquipment, {
        thunkName: "jobs/changeJobEquipment",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.changeJobEquipment) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  // EditContactDialog
  editContact: createAppAsyncThunk(
    "jobs/editContact",
    async (
      props: { jobId: string; customerId: string; equipmentId: string; contact: ContactType },
      { rejectWithValue, extra: { sdk } }
    ) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.editContact, {
        thunkName: "jobs/editContact",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateContact) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  //EditPlannedDateDialog
  updatePlannedDate: createAppAsyncThunk(
    "jobs/updatePlannedDate",
    async (props: { jobId: string; times: TimesType }, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.updatePlannedDate, {
        thunkName: "jobs/updatePlannedDate",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updatePlannedDate) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  // Visit Specific
  completeVisit: createAppAsyncThunk(
    "visit/completeVisit",
    async (props: { jobId: string }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { jobId } = props;
      const {
        jobs: { jobVisits },
        user: { userVar },
      } = getState();
      if (!userVar) return rejectWithValue("No user");
      const visit = jobVisits[jobId];
      const completeVisitVars = createUpdateVisitVars(
        jobId,
        userVar.suppliedStoreId,
        visit,
        TaskStatus.Done
      );

      const { data, errors, queued } = await graphqlRequest(sdk.updateVisitWithParts, {
        thunkName: "visit/completeVisit",
        thunkProps: props,
        variables: completeVisitVars,
        onError: [{ type: "jobs/resetJobStatus", payload: { jobId } }],
      });

      if (errors) return rejectWithValue(errors);
      if (data && !data.updateVisitWithParts) return rejectWithValue("something went wrong");
      return { queued, followUp: visit.followUp.followUpChecked };
    }
  ),
  updateVisit: createAppAsyncThunk(
    "visit/updateVisit",
    async (props: { jobId: string }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { jobId } = props;
      const {
        jobs: { jobVisits },
        user: { userVar },
      } = getState();
      if (!userVar) return rejectWithValue("No user");
      const visit = jobVisits[jobId];
      const updateVisitVars = createUpdateVisitVars(
        jobId,
        userVar.suppliedStoreId,
        visit,
        TaskStatus.Accepted
      );

      const { data, errors, queued } = await graphqlRequest(sdk.updateVisitWithParts, {
        thunkName: "visit/updateVisit",
        thunkProps: props,
        variables: updateVisitVars,
      });

      if (errors) return rejectWithValue(errors);
      if (data && !data.updateVisitWithParts) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  updateVisits: createAppAsyncThunk(
    "visit/updateVisits",
    async (props: { jobIds: string[] }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { jobIds } = props;
      const {
        jobs: { jobVisits },
        user: { userVar },
      } = getState();
      if (!userVar) return rejectWithValue("No user");
      let updateVisits: UpdateVisitInputType[] = [];
      jobIds.forEach((id) => {
        const visit = jobVisits[id];
        if (!visit) return;
        const updateVisitVars = createUpdateVisitVars(
          id,
          userVar.suppliedStoreId,
          visit,
          TaskStatus.Accepted
        );
        updateVisits.push(updateVisitVars.updateVisit);
      });

      const { data, errors, queued } = await graphqlRequest(sdk.updateVisitsWithParts, {
        thunkName: "visit/updateVisit",
        thunkProps: props,
        variables: { updateVisits: updateVisits },
      });

      if (errors) return rejectWithValue(errors);
      if (data && !data.updateVisitsWithParts) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  startVisitTravel: createAppAsyncThunk(
    "visit/startVisitTravel",
    async (props: { jobId: string }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { jobId } = props;
      const { jobVisits } = getState().jobs;
      let firstTravelTime = jobVisits[jobId].travelTimes[0];
      if (!firstTravelTime) return rejectWithValue("No times");
      let startTime = mergeDateTimeString(firstTravelTime.startDate, firstTravelTime.startTime);

      const { data, errors, queued } = await graphqlRequest(sdk.startVisitTravel, {
        thunkName: "visit/startVisitTravel",
        thunkProps: props,
        variables: { jobId, startTime },
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.startVisitTravel) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
  startVisitWork: createAppAsyncThunk(
    "visit/startVisitWork",
    async (props: { jobId: string }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { jobId } = props;
      const { jobVisits } = getState().jobs;
      const { travelTimes } = jobVisits[jobId];
      const lastTravelTime = [...travelTimes].pop();
      const now = toDateString(Date.now());

      const { data, errors, queued } = await graphqlRequest(sdk.startVisitWork, {
        thunkName: "visit/startVisitWork",
        thunkProps: props,
        variables: {
          jobId,
          startTime: now,
          travelStopTime: lastTravelTime?.stopTime || now,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.startVisitWork) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
  updateVisitTravel: createAppAsyncThunk(
    "visit/updateVisitTravel",
    async (props: { jobId: string }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { jobId } = props;
      const { jobVisits } = getState().jobs;
      const { travelTimes, errors: vErrors } = jobVisits[jobId];
      let errorKeys = Object.keys(vErrors);
      if (errorKeys.includes("travelTimes")) {
        return rejectWithValue("Invalid travelTimes");
      }
      const { data, errors, queued } = await graphqlRequest(sdk.updateVisitTravel, {
        thunkName: "visit/updateVisitTravel",
        thunkProps: props,
        variables: {
          jobId,
          travelTimes: travelTimes.map((tt) => ({
            startTime: mergeDateTimeString(tt.startDate, tt.startTime),
            stopTime:
              tt.stopDate && tt.stopTime ? mergeDateTimeString(tt.stopDate, tt.stopTime) : null,
          })),
        },
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateVisitTravel) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
  updateMachineCustomProps: createAppAsyncThunk(
    "visit/updateMachineCustomProps",
    async (
      props: { jobId: string; properties: PropertyValueInputType[] },
      { rejectWithValue, extra: { sdk } }
    ) => {
      const { jobId, properties } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.updateMachineCustomProperties, {
        thunkName: "visit/updateMachineCustomProps",
        thunkProps: props,
        variables: {
          jobId,
          properties,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateMachineCustomProperties)
        return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
  //AddChecklistDialog
  addChecklist: createAppAsyncThunk(
    "visit/addChecklist",
    async (
      props: { jobId: string; checklistCode: string },
      { rejectWithValue, extra: { sdk } }
    ) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.addChecklist, {
        thunkName: "visit/addChecklist",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.addChecklist) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
  updateMeters: createAppAsyncThunk(
    "visit/updateMeters",
    async (
      props: { jobId: string; meters: MeterReadingInputType | MeterReadingInputType[] },
      { rejectWithValue, extra: { sdk } }
    ) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.updateMeters, {
        thunkName: "visit/updateMeters",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateMeters) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
};

export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.rejectJob.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.rejectJob.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.rejectJob.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.rejectJob.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.rejectJob.fulfilled, (state, { meta }) => {
    state.jobs[meta.arg.jobId].status = JobStatus.Rejected;
    state.rejectJob.loading = false;
    state.rejectJob.text = "";
    state.rejectJob.open = false;
    return state;
  });
  builder.addCase(asyncMutations.acceptJob.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.jobs[meta.arg.jobId].status = JobStatus.Downloaded;
    return state;
  });
  builder.addCase(asyncMutations.acceptJob.fulfilled, (state, { meta }) => {
    state.jobs[meta.arg.jobId].status = JobStatus.Accepted;
    return state;
  });
  builder.addCase(asyncMutations.downloadJobs.fulfilled, (state, { meta }) => {
    meta.arg.jobIds.forEach((jobId) => {
      state.jobs[jobId].status = JobStatus.Downloaded;
    });
    return state;
  });
  // EditEquipmentDialog
  builder.addCase(asyncMutations.updateEquipment.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.updateEquipment.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.updateEquipment.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.updateEquipment.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.updateEquipment.fulfilled, (state, { meta }) => {
    state.updateEquipment.loading = false;
    state.updateEquipment.open = false;
    const { jobId, equipment } = meta.arg;
    if (state.jobs[jobId].equipment) {
      state.jobs[jobId].equipment!.location = equipment.location;
    }
    return state;
  });
  // ChangeJobEquipmentDialog
  builder.addCase(asyncMutations.changeJobEquipment.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.changeJobEquipment.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.changeJobEquipment.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.changeJobEquipment.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.changeJobEquipment.fulfilled, (state) => {
    state.changeJobEquipment.loading = false;
    return state;
  });
  // EditContactDialog
  builder.addCase(asyncMutations.editContact.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.editContact.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.editContact.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.editContact.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.editContact.fulfilled, (state, { meta }) => {
    state.editContact.loading = false;
    const { jobId, contact } = meta.arg;
    state.jobs[jobId].preferredContact = {
      email: contact.email,
      firstName: contact.firstName,
      id: contact.id,
      lastName: contact.lastName,
      mobileNumber: contact.mobile,
      name: contact.name,
      phoneNumber: contact.phone,
    };

    return state;
  });
  // Visit Specific
  builder.addCase(asyncMutations.completeVisit.rejected, (state, { payload: errors, meta }) => {
    if (!errors) throw new Error("Something went horribly wrong; error undefined");
    if (typeof errors === "string") throw new Error(errors);

    const id = meta.arg.jobId;
    state.jobVisits[id].submitLoading = false;

    const { infoString } = ((messages: string[]): { shortMessage: string; infoString?: string } => {
      for (let message of messages) {
        if (!message.includes("|")) continue;
        const [s0] = message.split(" at ");
        const [shortMessage, infoString] = s0.split("|");
        return { shortMessage, infoString };
      }
      return {
        shortMessage: messages[0],
      };
    })(errors.map((e) => e.message));

    if (infoString) {
      const infos = infoString.split(",");
      for (let info of infos) {
        if (info.includes("UploadFiles")) {
          state.jobVisits[id].files = [];
        }
      }
    }
    storeVisitsToLocal(state);
    return state;
  });
  builder.addCase(asyncMutations.completeVisit.pending, (state, { meta }) => {
    if (meta.queued) return state;
    let id = state.selectedJobId;
    if (!id) throw Error("Missing selectedId");
    state.jobVisits[id].submitLoading = true;
    return state;
  });
  builder.addCase(asyncMutations.completeVisit.fulfilled, (state, { meta }) => {
    let id = meta.arg.jobId;
    state.jobVisits[id].submitLoading = false;
    return state;
  });
  builder.addCase(asyncMutations.updateVisit.rejected, (state, { payload: errors, meta }) => {
    if (!errors) throw new Error("Something went horribly wrong; error undefined");
    if (typeof errors === "string") throw new Error(errors);

    const id = meta.arg.jobId;
    state.jobVisits[id].submitLoading = false;

    const { infoString } = ((messages: string[]): { shortMessage: string; infoString?: string } => {
      for (let message of messages) {
        if (!message.includes("|")) continue;
        const [s0] = message.split(" at ");
        const [shortMessage, infoString] = s0.split("|");
        return { shortMessage, infoString };
      }
      return {
        shortMessage: messages[0],
      };
    })(errors.map((e) => e.message));

    if (infoString) {
      const infos = infoString.split(",");
      for (let info of infos) {
        if (info.includes("UploadFiles")) {
          state.jobVisits[id].files = [];
        }
      }
    }
    storeVisitsToLocal(state);
    return state;
  });
  builder.addCase(asyncMutations.updateVisit.pending, (state, { meta }) => {
    if (meta.queued) return state;
    let id = state.selectedJobId;
    if (!id) throw Error("Missing selectedId");
    state.jobVisits[id].submitLoading = true;
    return state;
  });
  builder.addCase(asyncMutations.updateVisit.fulfilled, (state, { meta }) => {
    let id = meta.arg.jobId;
    state.jobVisits[id].submitLoading = false;
    state.jobVisits[id].files = [];
    storeVisitsToLocal(state);
    return state;
  });
  builder.addCase(asyncMutations.updateVisits.rejected, (state, { payload: errors, meta }) => {
    if (!errors) throw new Error("Something went horribly wrong; error undefined");
    if (typeof errors === "string") throw new Error(errors);

    for (let id of meta.arg.jobIds) {
      if (!state.jobVisits[id]) continue;
      state.jobVisits[id].submitLoading = false;

      const { infoString } = ((
        messages: string[]
      ): { shortMessage: string; infoString?: string } => {
        for (let message of messages) {
          if (!message.includes("|")) continue;
          const [s0] = message.split(" at ");
          const [shortMessage, infoString] = s0.split("|");
          return { shortMessage, infoString };
        }
        return {
          shortMessage: messages[0],
        };
      })(errors.map((e) => e.message));

      if (infoString) {
        const infos = infoString.split(",");
        for (let info of infos) {
          if (info.includes("UploadFiles")) {
            state.jobVisits[id].files = [];
          }
        }
      }
    }
    storeVisitsToLocal(state);
    return state;
  });
  builder.addCase(asyncMutations.updateVisits.pending, (state, { meta }) => {
    if (meta.queued) return state;
    for (let id of meta.arg.jobIds) {
      if (!state.jobVisits[id]) continue;
      state.jobVisits[id].submitLoading = true;
    }
    return state;
  });
  builder.addCase(asyncMutations.updateVisits.fulfilled, (state, { meta }) => {
    for (let id of meta.arg.jobIds) {
      if (!state.jobVisits[id]) continue;
      state.jobVisits[id].submitLoading = false;
      state.jobVisits[id].files = [];
    }
    storeVisitsToLocal(state);

    return state;
  });
  builder.addCase(asyncMutations.startVisitTravel.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    return state;
  });
  builder.addCase(asyncMutations.startVisitTravel.fulfilled, (state) => {
    return state;
  });
  builder.addCase(asyncMutations.startVisitWork.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    return state;
  });
  builder.addCase(
    asyncMutations.startVisitWork.fulfilled,
    (state, { payload: { data, queued }, meta: { arg } }) => {
      let dateTime: string | null;

      if (!queued) dateTime = toDateString(data?.startVisitWork?.workTimes[0].startTime);
      else dateTime = toDateString(Date.now());

      if (!dateTime) return state;
      state.jobVisits[arg.jobId].workTimes = [
        {
          startDate: dateTime,
          startTime: dateTime,
        },
      ];
      storeVisitsToLocal(state);
      return state;
    }
  );
  builder.addCase(asyncMutations.updateVisitTravel.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    return state;
  });
  builder.addCase(asyncMutations.updateVisitTravel.fulfilled, (state) => {
    return state;
  });
  builder.addCase(asyncMutations.updateMachineCustomProps.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.machineProperties.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.updateMachineCustomProps.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.machineProperties.loading = false;
  });
  builder.addCase(asyncMutations.updateMachineCustomProps.fulfilled, (state) => {
    state.machineProperties.loading = false;
    return state;
  });
  // AddChecklistDialog
  builder.addCase(asyncMutations.addChecklist.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.addChecklist.addLoading = true;
    return state;
  });
  builder.addCase(asyncMutations.addChecklist.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.addChecklist.addLoading = false;
  });
  builder.addCase(
    asyncMutations.addChecklist.fulfilled,
    (state, { payload: { data }, meta: { arg } }) => {
      const newChecklist = data?.addChecklist;
      if (newChecklist) {
        const currentChecklists = state.jobVisits[arg.jobId].checklists;
        state.jobVisits[arg.jobId].checklists = [
          ...currentChecklists,
          {
            checklist: newChecklist,
            hasPreStart: newChecklist.questions.some((q) => q.isRequiredPreStart),
          },
        ];
      }
      storeVisitsToLocal(state);

      state.addChecklist.addLoading = false;
      return state;
    }
  );
};
