import { CustomerType, JobSymptomType } from "operations/schema/schema";
import { CustomerSimple } from "./CustomerSimple";

export const JobFilterStorageKey = "jobFilter";

export type JobFilterDateTypes = "week" | "1month" | "3month" | "specificDate";
export type JobFilter = {
  typeDate: JobFilterDateTypes;
  jobId: string | null;
  selectedSymptoms: JobSymptomType[];
  specificDate: Date | null;
  areaCode: string | null;
  customer: CustomerSimple | CustomerType | null;
  city: string | null;
  postalCode: string | null;
};

export const defaultJobFilter: JobFilter = {
  typeDate: "1month",
  jobId: null,
  selectedSymptoms: [],
  specificDate: null,
  areaCode: null,
  customer: null,
  city: null,
  postalCode: null,
};

const keysToExclude = ["specificDate", "typeDate"] as const;
type FilterKeys = (keyof Omit<typeof defaultJobFilter, (typeof keysToExclude)[number]>)[];
export const JobFilterKeys: FilterKeys = Object.keys(defaultJobFilter).filter(
  (x) => !keysToExclude.includes(x as any)
) as FilterKeys;
