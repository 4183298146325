import { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { List } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { SortingList } from "pages/appBar/rightMenuDrawer/SortingList";
import { useAppSelector } from "store";
import { CalendarViewList } from "./rightMenuDrawer/CalendarViewList";
import { PlannerDisplayAsList } from "./rightMenuDrawer/PlannerDisplayAsList";

const PREFIX = "RightMenuDrawer";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeaderRight: `${PREFIX}-drawerHeaderRight`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    width: 240,
    flexShrink: 0,
  },

  [`& .${classes.drawerPaper}`]: {
    width: 240,
  },

  [`& .${classes.drawerHeaderRight}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

interface RightMenuDrawerProps {
  openMenu: boolean;
  setOpenMenu: (open: boolean) => void;
}

export const RightMenuDrawer: FC<RightMenuDrawerProps> = ({ openMenu, setOpenMenu }) => {
  const { sortingType, calendarView } = useAppSelector((s) => s.menu.appBar);

  return (
    <StyledDrawer
      className={classes.drawer}
      variant="temporary"
      onClose={() => setOpenMenu(false)}
      anchor="right"
      open={openMenu}
      classes={{
        paper: classes.drawerPaper,
      }}
      data-testid="rightMenuDrawer"
    >
      <div className={classes.drawerHeaderRight}>
        <IconButton onClick={() => setOpenMenu(false)} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <List key="RightMenuDrawerList">
        {calendarView ? (
          <CalendarViewList />
        ) : (
          !!sortingType && <SortingList sortingType={sortingType} />
        )}

        {sortingType === "planner" && <PlannerDisplayAsList />}
      </List>
    </StyledDrawer>
  );
};
