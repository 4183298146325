import { Note as NoteIcon } from "@mui/icons-material";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { isEmpty } from "helpers";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store";
import { selectPageWorkNotes } from "store/root.store";

export const NotesTab: FC = () => {
  const workNotes = useAppSelector(selectPageWorkNotes, shallowEqual);

  return (
    <Grid container direction="column" data-testid="NotesTab">
      {!isEmpty(workNotes) ? (
        workNotes.map((wn, index) => {
          let replacedText = "";
          let wasReplaced = false;
          if (wn?.text && wn?.loggedBy !== "") {
            replacedText = wn.text.replaceAll(wn.loggedBy, "**" + wn.loggedBy + "** \n");
            wasReplaced = true;
          }

          return (
            wn && (
              <Card key={`${index}-${wn.loggedBy}-${wn.loggedDate}`} sx={{ width: "100%" }}>
                <CardContent sx={{ pl: 3, pr: 3, pt: 1, pb: "1 !important" }}>
                  <Typography align="justify" component="div" whiteSpace="pre-line">
                    <ReactMarkdown
                      children={wasReplaced ? replacedText : wn.text}
                      className="markdown-text"
                    />
                  </Typography>
                </CardContent>
              </Card>
            )
          );
        })
      ) : (
        <Grid container item direction="column" alignItems="center" mt={2.5}>
          <Grid item>
            <NoteIcon color="secondary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography color="secondary">
              <FormattedMessage id="note.noNotesFound" />
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
